import { FC } from "react";
import {
  Box,
  Checkbox,
  Chip,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import { useGetEmissionFactorsQuery } from "../../emission-factors.queries";
import {
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import { CornerRightUpDoubleIcon } from "../../../common/constants/tabler-icon.constants";
import { CountryCodeAlpha2Enum, IGhgProtocolScope } from "@netcero/netcero-common";
import { EmissionFactorDialogFilter } from "../../../filters/filter.constants";
import { PaginationUtilities } from "../../../pagination/pagination.utilities";
import { TablePaginationForState } from "../../../pagination/components/table-pagination-for-state.component";
import { ACTIVE_BACKGROUND_HIGHLIGHT_COLOR, HOVER_BACKGROUND_COLOR } from "../../../../theme/theme";
import { usePaginationState } from "../../../pagination/hooks/pagination-state.hook";
import {
  EmissionFactorTableDatabaseCell,
  EmissionFactorTableGhgCategoriesCell,
  EmissionFactorTableTitleCell,
  EmissionFactorTableUnitCell,
  EmissionFactorTableVersionCell,
  StyledTableCell,
} from "../emission-factor-table-common-cell.components";
import { MapFilterState } from "../../../filters/map-state-filters.component";

interface IEmissionFactorsDialogTableProps {
  organizationId: string;
  selectedEmissionFactorId: string | null;
  disabledEmissionFactorIds?: string[];
  onChange: (emissionFactorId: string) => void;
  filtersMap: MapFilterState;
}

export const EmissionFactorsDialogTable: FC<IEmissionFactorsDialogTableProps> = ({
  organizationId,
  disabledEmissionFactorIds,
  selectedEmissionFactorId,
  onChange,
  filtersMap,
}) => {
  const { t } = useTranslation("emission_factors_table");

  const paginationState = usePaginationState(0, 5);

  const emissionFactorsQuery = useGetEmissionFactorsQuery({
    organizationId,
    ...PaginationUtilities.getPaginationQueryParams(paginationState.state),
    title: filtersMap.get(EmissionFactorDialogFilter.nameDialog)?.[0] ?? undefined,
    emissionFactorDatabases: filtersMap.get(
      EmissionFactorDialogFilter.databasesDialog,
    ) as IEmissionFactorDatabase[],
    emissionFactorTypes: filtersMap.get(
      EmissionFactorDialogFilter.emissionFactorTypesDialog,
    ) as IEmissionFactorType[],
    ghgCategories: filtersMap.get(
      EmissionFactorDialogFilter.ghgCategoriesDialog,
    ) as IGhgCategoriesIdentifier[],
    countries: filtersMap.get(
      EmissionFactorDialogFilter.countriesDialog,
    ) as CountryCodeAlpha2Enum[],
    units: filtersMap.get(EmissionFactorDialogFilter.unitsDialog) as IEmissionFactorUnit[],
  });

  return (
    <QueryWrapper
      query={emissionFactorsQuery}
      build={({ items, total }) =>
        items.length === 0 ? (
          <Box>{t("no_emission_factors_found")}</Box>
        ) : (
          <>
            <Box sx={{ overflowX: "auto" }}>
              <Table size="small" sx={{ mt: -2 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                    <StyledTableCell>{t("name")}</StyledTableCell>
                    <StyledTableCell>{t("database")}</StyledTableCell>
                    <StyledTableCell>{t("version")}</StyledTableCell>
                    <StyledTableCell width="150px">{t("ghg_category")}</StyledTableCell>
                    <StyledTableCell>{t("factor")}</StyledTableCell>
                    <StyledTableCell>{t("unit")}</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items.map(
                    ({
                      id,
                      title,
                      database,
                      version,
                      ghgCategories,
                      factor,
                      scopeFactors,
                      unit,
                    }) => {
                      const isEmissionFactorDisabled =
                        disabledEmissionFactorIds?.includes(id) ?? false;

                      return (
                        <TableRow
                          key={id}
                          sx={{
                            cursor: isEmissionFactorDisabled ? undefined : "pointer",
                            backgroundColor:
                              selectedEmissionFactorId === id
                                ? ACTIVE_BACKGROUND_HIGHLIGHT_COLOR
                                : undefined,
                            ":hover": isEmissionFactorDisabled
                              ? {}
                              : {
                                  backgroundColor: HOVER_BACKGROUND_COLOR,
                                },
                          }}
                          onClick={!isEmissionFactorDisabled ? () => onChange(id) : undefined}
                        >
                          <StyledTableCell>
                            <Checkbox
                              checked={selectedEmissionFactorId === id}
                              disabled={isEmissionFactorDisabled}
                            />
                          </StyledTableCell>
                          <EmissionFactorTableTitleCell title={title} />
                          <EmissionFactorTableDatabaseCell database={database} />
                          <EmissionFactorTableVersionCell version={version} />
                          <EmissionFactorTableGhgCategoriesCell ghgCategories={ghgCategories} />
                          <StyledTableCell align="right">
                            <Stack direction="row" alignItems="center" gap={0.5}>
                              <Box minWidth="40px">{factor}</Box>
                              <Chip size="small" label="kgCO₂eq" sx={{ ml: 0.5 }} />
                              {scopeFactors[IGhgProtocolScope.Scope3] !== undefined ? (
                                <Tooltip
                                  title={`${scopeFactors[IGhgProtocolScope.Scope3]} kgCO₂eq`}
                                >
                                  <CornerRightUpDoubleIcon />
                                </Tooltip>
                              ) : (
                                <Box width={5} />
                              )}
                            </Stack>
                          </StyledTableCell>
                          <EmissionFactorTableUnitCell unit={unit} />
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </Box>
            <TablePaginationForState
              component="div"
              sx={{ mt: 1 }}
              count={total}
              paginationStateResult={paginationState}
              rowsPerPageOptions={[5]}
            />
          </>
        )
      }
    />
  );
};
