import { FC } from "react";
import { Box, IconButton, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useGetEmissionFactorsQuery } from "../emission-factors.queries";
import {
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import { DeleteIcon, EditIcon } from "../../common/constants/tabler-icon.constants";
import { CountryCodeAlpha2Enum } from "@netcero/netcero-common";
import { EmissionFactorsFilter } from "../../filters/filter.constants";
import { SetURLSearchParams } from "react-router-dom";
import { PaginationUtilities } from "../../pagination/pagination.utilities";
import { TablePaginationForState } from "../../pagination/components/table-pagination-for-state.component";
import { useSearchParamsPaginationState } from "../../pagination/hooks/query-params-pagination-state.hook";
import {
  EmissionFactorTableCountryCell,
  EmissionFactorTableDatabaseCell,
  EmissionFactorTableFactorCell,
  EmissionFactorTableGhgCategoriesCell,
  EmissionFactorTableTitleCell,
  EmissionFactorTableTypeCell,
  EmissionFactorTableUnitCell,
  EmissionFactorTableVersionCell,
  StyledTableCell,
} from "./emission-factor-table-common-cell.components";

interface IEmissionFactorsTableProps {
  organizationId: string;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  onEdit: (emissionFactorId: string) => void;
  onDelete: (emissionFactorId: string) => void;
  canEditDeleteEmissionFactor?: boolean;
}

export const EmissionFactorsTable: FC<IEmissionFactorsTableProps> = ({
  organizationId,
  searchParams,
  setSearchParams,
  onEdit,
  onDelete,
  canEditDeleteEmissionFactor,
}) => {
  const { t } = useTranslation("emission_factors_table");

  const paginationState = useSearchParamsPaginationState(searchParams, setSearchParams);

  const emissionFactorsQuery = useGetEmissionFactorsQuery({
    organizationId,
    ...PaginationUtilities.getPaginationQueryParams(paginationState.state),
    title: searchParams.get(EmissionFactorsFilter.name) ?? undefined,
    emissionFactorDatabases: searchParams.getAll(
      EmissionFactorsFilter.databases,
    ) as IEmissionFactorDatabase[],
    emissionFactorTypes: searchParams.getAll(
      EmissionFactorsFilter.emissionFactorTypes,
    ) as IEmissionFactorType[],
    ghgCategories: searchParams.getAll(
      EmissionFactorsFilter.ghgCategories,
    ) as IGhgCategoriesIdentifier[],
    countries: searchParams.getAll(EmissionFactorsFilter.countries) as CountryCodeAlpha2Enum[],
    units: searchParams.getAll(EmissionFactorsFilter.units) as IEmissionFactorUnit[],
  });

  return (
    <QueryWrapper
      query={emissionFactorsQuery}
      build={({ items, total }) =>
        items.length === 0 ? (
          <Box>{t("no_emission_factors_found")}</Box>
        ) : (
          <>
            <Box sx={{ overflowX: "auto" }}>
              <Table size="small" sx={{ mt: -2 }}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="33%">{t("name")}</StyledTableCell>
                    <StyledTableCell>{t("database")}</StyledTableCell>
                    <StyledTableCell>{t("type")}</StyledTableCell>
                    <StyledTableCell>{t("version")}</StyledTableCell>
                    <StyledTableCell>{t("ghg_category")}</StyledTableCell>
                    <StyledTableCell>{t("country")}</StyledTableCell>
                    <StyledTableCell>{t("factor")}</StyledTableCell>
                    <StyledTableCell>{t("unit")}</StyledTableCell>
                    <StyledTableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {items?.map(
                    ({
                      id,
                      title,
                      database,
                      emissionFactorType,
                      version,
                      ghgCategories,
                      factor,
                      scopeFactors,
                      country,
                      unit,
                    }) => (
                      <TableRow key={id}>
                        <EmissionFactorTableTitleCell title={title} />
                        <EmissionFactorTableDatabaseCell database={database} />
                        <EmissionFactorTableTypeCell emissionFactorType={emissionFactorType} />
                        <EmissionFactorTableVersionCell version={version} />
                        <EmissionFactorTableGhgCategoriesCell ghgCategories={ghgCategories} />
                        <EmissionFactorTableCountryCell country={country} />
                        <EmissionFactorTableFactorCell
                          factor={factor}
                          scopeFactors={scopeFactors}
                        />
                        <EmissionFactorTableUnitCell unit={unit} />
                        <StyledTableCell align="right">
                          {database === IEmissionFactorDatabase.OrganizationSpecific &&
                            canEditDeleteEmissionFactor && (
                              <Box display="flex" flexDirection="row" flexWrap="nowrap">
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    onEdit(id);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton size="small" onClick={() => onDelete(id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            )}
                        </StyledTableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </Box>
            <TablePaginationForState
              component="div"
              sx={{ mt: 1 }}
              count={total}
              paginationStateResult={paginationState}
            />
          </>
        )
      }
    />
  );
};
