import { Chip, Skeleton } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useGetEmissionFactorQuery } from "../emission-factors.queries";
import { ObjectUtilities, UndefinedOrNull } from "@netcero/netcero-common";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { ChipProps } from "@mui/material/Chip/Chip";

interface IEmissionFactorChipProps {
  organizationId: string;
  emissionFactorId: UndefinedOrNull<string>;
  onClick?: () => void;
  error?: boolean;
  disabled?: boolean;
}

export const EmissionFactorChip: FC<IEmissionFactorChipProps> = ({
  organizationId,
  emissionFactorId,
  onClick,
  error,
  disabled,
}) => {
  const { t } = useTranslation("emission_factor_dialog");
  const translateContent = useTranslateContent();

  const emissionFactorQuery = useGetEmissionFactorQuery(
    {
      organizationId,
      // Fine since the query will only be called when the emissionFactorId is present
      emissionFactorId: emissionFactorId!,
    },
    ObjectUtilities.isValuePresent(emissionFactorId),
  );

  const commonChipProps: ChipProps = useMemo(
    () => ({
      size: "small",
      onClick,
      sx: {
        height: "auto",
        border: error && !emissionFactorId ? "1px solid" : undefined,
        borderColor: "error.main",
      },
      disabled,
    }),
    [disabled, emissionFactorId, error, onClick],
  );

  return emissionFactorId ? (
    <QueryWrapper
      query={emissionFactorQuery}
      loadingOverride={() => <EmissionFactorChipSkeleton />}
      build={(emissionFactor) => (
        <Chip
          label={`${translateContent(emissionFactor.title)}, ${t(emissionFactor.database, {
            ns: "emission_factor_databases",
          })}`}
          {...commonChipProps}
        />
      )}
    />
  ) : (
    <Chip label={t("no_related_location_based_emission_factor")} {...commonChipProps} />
  );
};

export const EmissionFactorChipSkeleton = () => (
  <Skeleton variant="rounded" width={140} sx={{ borderRadius: 4 }} />
);
