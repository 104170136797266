import { useMemo } from "react";
import { IOption } from "../../filters/filter.type";
import {
  IEmissionFactorDatabase,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
} from "@netcero/netcero-core-api-client";
import { CountryCodeAlpha2Enum } from "@netcero/netcero-common";
import { ORDERED_EMISSION_FACTOR_TYPE } from "../emission-factors.constants";
import { useTranslation } from "react-i18next";
import { useRenderGhgCategoryName } from "../emission-factors.hooks";

export const useGetEmissionFactorFilters = () => {
  const { t } = useTranslation("emission_factors_filters");
  const renderGhgCategoryName = useRenderGhgCategoryName();

  return useMemo(() => {
    const databaseOptions: IOption[] = Object.values(IEmissionFactorDatabase).map((database) => ({
      name: t(database, { ns: "emission_factor_databases" }),
      value: database,
    }));

    const emissionFactorTypeOptions: IOption[] = ORDERED_EMISSION_FACTOR_TYPE.map((database) => ({
      name: t(database, { ns: "emission_factor_types" }),
      value: database,
    }));

    const ghgCategoryOptions: IOption[] = Object.values(IGhgCategoriesIdentifier).map(
      (ghgCategory) => ({
        name: renderGhgCategoryName(ghgCategory),
        value: ghgCategory,
      }),
    );

    const countryOptions: IOption[] = Object.values(CountryCodeAlpha2Enum).map((countryCode) => ({
      name: t(`countries.${countryCode}`, { ns: "common" }),
      value: countryCode,
    }));

    const unitOptions: IOption[] = Object.values(IEmissionFactorUnit).map((unit) => ({
      name: t(unit, { ns: "emission_factor_units" }),
      value: unit,
    }));

    return {
      databaseOptions,
      emissionFactorTypeOptions,
      ghgCategoryOptions,
      countryOptions,
      unitOptions,
    };
  }, [renderGhgCategoryName, t]);
};
