import {
  CountryCodeAlpha2Enum,
  generateDMACategoryTranslations,
  generateESRSTopicTranslations,
  MdrUnitGroup,
  ScopeKeys,
} from "@netcero/netcero-common";
import {
  IAuditLogEvent,
  IContentLanguage,
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  IDataEntryObjectInputParameterGapAnalysisDataCollection,
  IDataEntryObjectInputParameterStatus,
  IDEOIPValueDataQuality,
  IEmissionFactorDatabase,
  IEmissionFactorType,
  IEmissionFactorUnit,
  IGhgCategoriesIdentifier,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum,
  IMarketBasedEmissionFactorType,
  ITargetPathGoalTemplateType,
  ITargetPathState,
} from "@netcero/netcero-core-api-client";
import countryTranslations from "i18n-iso-countries";
import englishCountryTranslation from "i18n-iso-countries/langs/en.json";
import { TRANSLATIONS_DE } from "./de.translations";
import { CalculationErrorCodes } from "@netcero/netcero-calculation";
import {
  BasicSnackbarApiActionType,
  SnackbarSuccessMassageType,
} from "../modules/app-snackbar/app-snackbar.interfaces";
import { IROType } from "../modules/policies/policies.types";
import { EligiblePhaseInDrsEfragIds } from "../modules/input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { ESRSInputParameterType } from "../modules/data-entry-object-values/esrs/esrs-input-parameter-type-indicator.component";
import { LexicalBlockType } from "../modules/lexical/lexical.types";
import { IRenderEmissionFactorUnitType } from "../modules/emission-factors/emission-factors.hooks";

countryTranslations.registerLocale(englishCountryTranslation);
const CountryNamesByCode = Object.values(CountryCodeAlpha2Enum).reduce((res, code) => {
  const name = countryTranslations.getName(code, "en");
  if (name) {
    res[code] = name;
  }
  return res;
}, {} as Record<CountryCodeAlpha2Enum, string>);

const actionsTranslations: Record<SnackbarSuccessMassageType, string> = {
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_VALUE]:
    "Update disclosure requirement value",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS]:
    "Update contributors",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER]:
    "Update responsible person",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE]:
    "Update value inside disclosure requirement",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS]:
    "Update contributors",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER]:
    "Update responsible person",
  [BasicSnackbarApiActionType.UPDATE_PHASE_IN_DECISION]: "Update Phase-In",
  [BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT]:
    "Reset status of disclosure requirement",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT]: "Submit disclosure requirement",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT]: "Approve disclosure requirement",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT]: "Reject disclosure requirement",
  [BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Reset status of value inside disclosure requirement",
  [BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Submit value inside disclosure requirement",
  [BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Approve value inside disclosure requirement",
  [BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Reject value inside disclosure requirement",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT]:
    "Disclosure requirement excluded from report",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT]:
    "Disclosure requirement restored to report",
  [BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Value in disclosure requirement excluded from report",
  [BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER]:
    "Value in disclosure requirement restored to report",
  [BasicSnackbarApiActionType.EXPORT_DMA_IROS]: "Exported impacts, risks and opportunities",
  [BasicSnackbarApiActionType.EXPORT_DMA_STAKEHOLDER_FEEDBACK]: "Exported stakeholder feedback",
  [BasicSnackbarApiActionType.EXPORT_USERS]: "Exported users",
  // DR Assessment
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_ASSESSMENT]:
    "Updated disclosure requirement assessment",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_DATA_COLLECTION]:
    "Updated data collection",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_AVAILABILITY]:
    "Updated availability",
  // Sources
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_SOURCES]:
    "Updated sources for disclosure requirement",
  [BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_SOURCES]:
    "Updated sources for value inside disclosure requirement",
  // IRO Move
  [BasicSnackbarApiActionType.MOVE_FINANCIAL_EFFECT_TO_OTHER_TOPIC]:
    "Moved risk / opportunity to another topic",
  [BasicSnackbarApiActionType.MOVE_MATERIAL_IMPACT_TO_OTHER_TOPIC]: "Moved impact to another topic",
  // IRO Duplicate
  [BasicSnackbarApiActionType.DUPLICATE_MATERIAL_IMPACT]: "Duplicated impact",
  [BasicSnackbarApiActionType.DUPLICATE_FINANCIAL_EFFECT]: "Duplicated opportunity / risk",
  // Init all ESRS Topics
  [BasicSnackbarApiActionType.INIT_ALL_ESRS_TOPICS]: "Added all ESRS topics",
  // Org Roles
  [BasicSnackbarApiActionType.UPDATE_ORG_ROLE]: "Updated role",
  [BasicSnackbarApiActionType.UPDATE_USER_DATA_ENTRY_OBJECTS_ACCESS]:
    "Updated data entry objects access",
  // Verification of DMA Categories
  [BasicSnackbarApiActionType.VERIFY_DMA_CATEGORY]: "Verified category",
  [BasicSnackbarApiActionType.UNVERIFY_DMA_CATEGORY]: "Removed verification of category",
};

const DRs_PHASE_IN_EXPLANATIONS: Record<EligiblePhaseInDrsEfragIds, string> = {
  [EligiblePhaseInDrsEfragIds.SBM_1]:
    "The company must report ESRS 2 SBM-1, starting from the date in the Commission Delegated Act under Directive 2013/34/EU, Article 29b(1), third subparagraph, point (ii).",
  [EligiblePhaseInDrsEfragIds.SBM_3]:
    "The company may omit ESRS 2 SBM-3, paragraph 48(e), for the first year and provide only qualitative disclosures for the first three years if quantitative disclosures are impracticable.",
  [EligiblePhaseInDrsEfragIds.E1_6]:
    "Companies with fewer than 750 employees may omit scope 3 and total GHG emissions data in the first year of their sustainability statement.",
  [EligiblePhaseInDrsEfragIds.E1_9]:
    "The company may omit ESRS E1-9 for the first year and provide only qualitative disclosures for the first three years if quantitative disclosures are impracticable",
  [EligiblePhaseInDrsEfragIds.E2_6]:
    "The company may omit ESRS E2-6 for the first year. Except for paragraph 40(b), it may provide only qualitative disclosures for the first three years if quantitative disclosures are impracticable.",
  [EligiblePhaseInDrsEfragIds.E3_5]:
    "The company may omit ESRS E3-5 for the first year and provide only qualitative disclosures for the first three years.",
  [EligiblePhaseInDrsEfragIds.E4_6]:
    "The company may omit ESRS E4-6 for the first year and provide only qualitative disclosures for the first three years.",
  [EligiblePhaseInDrsEfragIds.E5_6]:
    "The company may omit ESRS E5-6 for the first year and provide only qualitative disclosures for the first three years.",
  [EligiblePhaseInDrsEfragIds.S1_7]:
    "The company may omit all data points in this Disclosure Requirement for the first year.",
  [EligiblePhaseInDrsEfragIds.S1_8]:
    "The company may omit this Disclosure Requirement for its employees in non-EEA countries for the first year",
  [EligiblePhaseInDrsEfragIds.S1_11]:
    "The undertaking may omit the information prescribed by ESRS S1-11 for the first year of preparation of its sustainability statement.",
  [EligiblePhaseInDrsEfragIds.S1_12]:
    "The undertaking may omit the information prescribed by ESRS S1-12 for the first year of preparation of its sustainability statement.",
  [EligiblePhaseInDrsEfragIds.S1_13]:
    "The undertaking may omit the information prescribed by ESRS S1-13 for the first year of preparation of its sustainability statement.",
  [EligiblePhaseInDrsEfragIds.S1_14]:
    "The company may omit data on work-related ill-health, injuries, accidents, fatalities, and days lost for the first year.",
  [EligiblePhaseInDrsEfragIds.S1_15]:
    "The undertaking may omit the information prescribed by ESRS S1-15 for the first year of preparation of its sustainability statement.",
};

const inputParameterTypesTranslations: Record<ESRSInputParameterType, string> = {
  text: "Text",
  "text-area": "Textfield",
  currency: "Currency",
  number: "Number",
  boolean: "Yes/No",
  date: "Date",
  "date-range": "Date Range",
  options: "Select",
  "nested-options": "Select",
  policy: "Policies",
  table: "Table",
  complex: "Multiple Values",
  "phase-in": "Phase-In",
  year: "Year",
  "value-with-unit": "Number with Unit",
  action: "Actions",
  target: "Targets",
  "referenced-target": "Referenced Targets",
};

const ADDRESSED_MATTERS_ENUM = {
  e1: {
    "esrs:ClimateChangeAdaptationMember": "Climate change adaptation",
    "esrs:ClimateChangeMitigationMember": "Climate change mitigation",
    "esrs:EnergyEfficiencyMember": "Energy efficiency",
    "esrs:RenewableEnergyDeploymentMember": "Renewable energy deployment",
    "esrs:OtherClimateChangeSubtopicMember": "Other climate change subtopic",
  },
  e2: {
    "esrs:PollutionOfAirMember": "Pollution of air",
    "esrs:PollutionOfWaterMember": "Pollution of water",
    "esrs:PollutionOfSoilMember": "Pollution of soil",
    "esrs:PollutionOfLivingOrganismsAndFoodResourcesMember":
      "Pollution of living organisms and food resources",
    "esrs:SubstancesOfConcernMember": "Substances of concern",
    "esrs:SubstancesOfVeryHighConcernMember": "Substances of very high concern",
    "esrs:MicroplasticsMember": "Microplastics",
    "esrs:OtherPollutionSubtopicMember": "Other pollution subtopic",
  },
  e3: {
    "esrs:WaterAndMarineResourcesMember": "Water and marine resources",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterConsumptionMember": "Water consumption",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterWithdrawalsMember": "Water withdrawals",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesMember": "Water discharges",
    "esrs:WaterAndMarineResourcesMember.esrs:WaterDischargesInOceansMember":
      "Water discharges in oceans",
    "esrs:WaterAndMarineResourcesMember.esrs:ExtractionAndUseOfMarineResourcesMember":
      "Extraction and use of marine resources",
    "esrs:OtherWaterAndMarineResourcesSubtopicMember": "Other water and marine resources subtopic",
  },
  e4: {
    "esrs:DirectImpactDriversOfBiodiversityLossMember":
      "Direct impact drivers of biodiversity loss",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:ClimateChangeMember": "Climate change",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:LandUseChangeMember": "Land use change",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:FreshWateruseChangeMember":
      "Freshwater use change",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:SeauseChangeMember": "Seause change",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:DirectExploitationMember":
      "Direct exploitation",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:InvasiveAlienSpeciesMember":
      "Invasive alien species",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:PollutionMember": "Pollution",
    "esrs:DirectImpactDriversOfBiodiversityLossMember.esrs:OtherDirectImpactDriversOfBiodiversityLossMember":
      "Other direct impact drivers of biodiversity loss",
    "esrs:ImpactsOnStateOfSpeciesMember": "Impacts on state of species",
    "esrs:ImpactsOnExtentAndConditionOfEcosystemsMember":
      "Impacts on extent and condition of ecosystems",
    "esrs:ImpactsAndDependenciesOnEcosystemServicesMember":
      "Impacts and dependencies on ecosystem services",
    "esrs:OtherBiodiversityAndEcosystemsSubtopicMember":
      "Other biodiversity and ecosystems subtopic",
  },
  e5: {
    "esrs:ResourceInflowsMember": "Resource inflows",
    "esrs:ResourceOutflowsRelatedToProductsAndServicesMember":
      "Resource outflows related to products and services",
    "esrs:WasteMember": "Waste",
    "esrs:OtherResourceUseAndCircularEconomySubtopicMember":
      "Other resource use and circular economy subtopic",
  },
  s1: {
    "esrs:WorkingConditionsForOwnWorkforceMember": "Working conditions for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SecureEmploymentForOwnWorkforceMember":
      "Secure employment for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorkingTimeForOwnWorkforceMember":
      "Working time for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:AdequateWagesForOwnWorkforceMember":
      "Adequate wages for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:SocialDialogueForOwnWorkforceMember":
      "Social dialogue for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:FreedomOfAssociationForOwnWorkforceMember":
      "Freedom of association for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:ExistenceOfWorksCouncilsForOwnWorkforceMember":
      "Existence of works councils for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:InformationConsultationAndParticipationRightsOfOwnWorkforceMember":
      "Information, consultation, and participation rights of own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:CollectiveBargainingForOwnWorkforceMember":
      "Collective bargaining for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:WorklifeBalanceForOwnWorkforceMember":
      "Work-life balance for own workforce",
    "esrs:WorkingConditionsForOwnWorkforceMember.esrs:HealthAndSafetyForOwnWorkforceMember":
      "Health and safety for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember":
      "Equal treatment and opportunities for all for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForOwnWorkforceMember":
      "Gender equality and equal pay for work of equal value for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:TrainingAndSkillsDevelopmentForOwnWorkforceMember":
      "Training and skills development for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForOwnWorkforceMember":
      "Employment and inclusion of persons with disabilities for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForOwnWorkforceMember":
      "Measures against violence and harassment in the workplace for own workforce",
    "esrs:EqualTreatmentAndOpportunitiesForAllForOwnWorkforceMember.esrs:DiversityForOwnWorkforceMember":
      "Diversity for own workforce",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember":
      "Other work-related rights for own workforce",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ChildLabourForOwnWorkforceMember":
      "Child labour for own workforce",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:ForcedLabourForOwnWorkforceMember":
      "Forced labour for own workforce",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:AdequateHousingForOwnWorkforceMember":
      "Adequate housing for own workforce",
    "esrs:OtherWorkrelatedRightsForOwnWorkforceMember.esrs:PrivacyForOwnWorkforceMember":
      "Privacy for own workforce",
    "esrs:OtherOwnWorkforceSubtopicMember": "Other own workforce subtopic",
  },
  s2: {
    "esrs:WorkingConditionsForValueChainWorkersMember":
      "Working conditions for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SecureEmploymentForValueChainWorkersMember":
      "Secure employment for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorkingTimeForValueChainWorkersMember":
      "Working time for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:AdequateWagesForValueChainWorkersMember":
      "Adequate wages for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SocialDialogueForValueChainWorkersMember":
      "Social dialogue for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:FreedomOfAssociationForValueChainWorkersMember":
      "Freedom of association for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:CollectiveBargainingForValueChainWorkersMember":
      "Collective bargaining for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorklifeBalanceForValueChainWorkersMember":
      "Work-life balance for value chain workers",
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:HealthAndSafetyForValueChainWorkersMember":
      "Health and safety for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember":
      "Equal treatment and opportunities for all for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember":
      "Gender equality and equal pay for work of equal value for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:TrainingAndSkillsDevelopmentForValueChainWorkersMember":
      "Training and skills development for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember":
      "Employment and inclusion of persons with disabilities for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember":
      "Measures against violence and harassment in the workplace for value chain workers",
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:DiversityForValueChainWorkersMember":
      "Diversity for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember":
      "Other work-related rights for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ChildLabourForValueChainWorkersMember":
      "Child labour for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ForcedLabourForValueChainWorkersMember":
      "Forced labour for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:AdequateHousingForValueChainWorkersMember":
      "Adequate housing for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:WaterAndSanitationForValueChainWorkersMember":
      "Water and sanitation for value chain workers",
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:PrivacyForValueChainWorkersMember":
      "Privacy for value chain workers",
    "esrs:OtherWorkersInValueChainSubtopicMember": "Other workers in value chain subtopic",
  },
  s3: {
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember":
      "Communities' economic, social, and cultural rights",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateHousingForAffectedCommunitiesMember":
      "Adequate housing for affected communities",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:AdequateFoodMember":
      "Adequate food",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:WaterAndSanitationForAffectedCommunitiesMember":
      "Water and sanitation for affected communities",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:LandrelatedImpactsMember":
      "Land-related impacts",
    "esrs:CommunitiesEconomicSocialAndCulturalRightsMember.esrs:SecurityrelatedImpactsMember":
      "Security-related impacts",
    "esrs:CommunitiesCivilAndPoliticalRightsMember": "Communities' civil and political rights",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfExpressionForAffectedCommunitiesMember":
      "Freedom of expression for affected communities",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:FreedomOfAssemblyMember":
      "Freedom of assembly",
    "esrs:CommunitiesCivilAndPoliticalRightsMember.esrs:ImpactsOnHumanRightsDefendersMember":
      "Impacts on human rights defenders",
    "esrs:RightsOfIndigenousPeoplesMember": "Rights of indigenous peoples",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:FreePriorAndInformedConsentMember":
      "Free, prior, and informed consent",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:SelfdeterminationMember": "Self-determination",
    "esrs:RightsOfIndigenousPeoplesMember.esrs:CulturalRightsMember": "Cultural rights",
    "esrs:OtherAffectedCommunitiesSubtopicMember": "Other affected communities subtopic",
  },
  s4: {
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember":
      "Information-related impacts for consumers and/or end users",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:PrivacyForConsumersAndOrEndUsersMember":
      "Privacy for consumers and/or end users",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:FreedomOfExpressionForConsumersAndOrEndusersMember":
      "Freedom of expression for consumers and/or end users",
    "esrs:InformationrelatedImpactsForConsumersAndOrEndUsersMember.esrs:AccessToQualityInformationMember":
      "Access to quality information",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember":
      "Personal safety of consumers and/or end users",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:HealthAndSafetyForConsumersAndOrEndUsersMember":
      "Health and safety for consumers and/or end users",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:SecurityOfPersonMember":
      "Security of person",
    "esrs:PersonalSafetyOfConsumersAndOrEndUsersMember.esrs:ProtectionOfChildrenMember":
      "Protection of children",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember":
      "Social inclusion of consumers and/or end users",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:NondiscriminationMember":
      "Non-discrimination",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:AccessToProductsAndServicesMember":
      "Access to products and services",
    "esrs:SocialInclusionOfConsumersAndOrEndUsersMember.esrs:ResponsibleMarketingPracticesMember":
      "Responsible marketing practices",
    "esrs:OtherConsumersAndOrEndusersSubtopicMember": "Other consumers and/or end users subtopic",
  },
};

const staticUnitTranslations: Record<
  IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum,
  string
> = {
  [IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum.KgPerYear]: "kg/year",
  [IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum.Hours]: "hours",
};

const EMISSION_FACTOR_UNITS: Record<
  IRenderEmissionFactorUnitType,
  Record<IEmissionFactorUnit, string>
> = {
  short: {
    [IEmissionFactorUnit.FZkm]: "vkm",
    [IEmissionFactorUnit.Pkm]: "pkm",
    [IEmissionFactorUnit.Tkm]: "tkm",
    [IEmissionFactorUnit.T]: "t",
    [IEmissionFactorUnit.L]: "l",
    [IEmissionFactorUnit.M2]: "m²",
    [IEmissionFactorUnit.KWh]: "kWh",
    [IEmissionFactorUnit.MioEuros]: "€ mio",
  },
  long: {
    [IEmissionFactorUnit.FZkm]: "Vehicle kilometer",
    [IEmissionFactorUnit.Pkm]: "Passenger kilometer",
    [IEmissionFactorUnit.Tkm]: "Tonne kilometer",
    [IEmissionFactorUnit.T]: "Tonne",
    [IEmissionFactorUnit.L]: "Liter",
    [IEmissionFactorUnit.M2]: "Square meter",
    [IEmissionFactorUnit.KWh]: "Kilowatt hour",
    [IEmissionFactorUnit.MioEuros]: "Million euros",
  },
};

const GHG_CATEGORIES: Record<IGhgCategoriesIdentifier, string> = {
  [IGhgCategoriesIdentifier._11StationaryCombustion]: "Stationary Combustion",
  [IGhgCategoriesIdentifier._12MobileCombustion]: "Mobile Combustion",
  [IGhgCategoriesIdentifier._13FugitiveEmissions]: "Fugitive Emissions",
  [IGhgCategoriesIdentifier._14OtherEmissions]: "Other Emissions",
  [IGhgCategoriesIdentifier._21PurchasedElectricity]: "Purchased Electricity",
  [IGhgCategoriesIdentifier._22PurchasedHeat]: "Purchased Heat",
  [IGhgCategoriesIdentifier._23PurchasedSteam]: "Purchased Steam",
  [IGhgCategoriesIdentifier._24PurchasedCooling]: "Purchased Cooling",
  [IGhgCategoriesIdentifier._31PurchasedGoodsAndServices]: "Purchased Goods and Services",
  [IGhgCategoriesIdentifier._32CapitalGoods]: "Capital Goods",
  [IGhgCategoriesIdentifier._33EnergyRelatedActivitiesNotInScope1And2]:
    "Fuel- and Energy-Related Activities (not included in Scope 1 or 2)",
  [IGhgCategoriesIdentifier._34UpstreamTransportationAndDistribution]:
    "Upstream Transportation and Distribution",
  [IGhgCategoriesIdentifier._35Waste]: "Waste Generated in Operations",
  [IGhgCategoriesIdentifier._36BusinessTravel]: "Business Travel",
  [IGhgCategoriesIdentifier._37EmployeeCommuting]: "Employee Commuting",
  [IGhgCategoriesIdentifier._38LeasedOrRentedAssets]: "Upstream Leased Assets",
  [IGhgCategoriesIdentifier._39DownstreamTransportationAndDistribution]:
    "Downstream Transportation and Distribution",
  [IGhgCategoriesIdentifier._310ProcessingOfSoldProducts]: "Processing of Sold Products",
  [IGhgCategoriesIdentifier._311UseOfSoldProducts]: "Use of Sold Products",
  [IGhgCategoriesIdentifier._312EndOfLifeTreatmentOfSoldProducts]:
    "End-of-Life Treatment of Sold Products",
  [IGhgCategoriesIdentifier._313LeasedOrRentedAssetsDownstream]: "Downstream Leased Assets",
  [IGhgCategoriesIdentifier._314Franchise]: "Franchises",
  [IGhgCategoriesIdentifier._315Investments]: "Investments",
};

const EMISSION_FACTOR_DATABASES: Record<IEmissionFactorDatabase, string> = {
  [IEmissionFactorDatabase.UbaAt]: "UBA AT",
  [IEmissionFactorDatabase.Defra]: "DEFRA",
  [IEmissionFactorDatabase.OrganizationSpecific]: "Custom Entry",
};

const EMISSION_FACTOR_TYPES: Record<IEmissionFactorType, string> = {
  [IEmissionFactorType.ActivityBased]: "Activity based",
  [IEmissionFactorType.LocationBasedEnergy]: "Location based (energy)",
  [IEmissionFactorType.MarketBasedEnergy]: "Market based (energy)",
  [IEmissionFactorType.SpendingBased]: "Spend based",
  [IEmissionFactorType.Other]: "Other",
};

const MARKET_BASED_EMISSION_FACTOR_TYPES: Record<IMarketBasedEmissionFactorType, string> = {
  [IMarketBasedEmissionFactorType.ProductMix]: "Product mix (contract-related)",
  [IMarketBasedEmissionFactorType.ResidualMix]: "Residual mix",
  [IMarketBasedEmissionFactorType.SupplierMix]: "Supplier mix",
};

const auditLoggingEventsTranslations: Record<IAuditLogEvent, string> = {
  recording_period_create: "A new recording period has been created.",
  recording_period_update: "A recording period has been edited.",
  recorded_esrs_topic_opt_in: "Recording has started for the topic.",
  recorded_esrs_topic_opt_out: "Recording for this topic has been skipped.",
  dma_configuration_update: "DMA configuration has been edited.",
  dma_category_create: "A DMA category has been created.",
  dma_category_update: "A DMA category has been edited.",
  dma_category_update_information: "The information of a DMA category has been updated.",
  dma_category_delete: "A DMA category has been deleted.",
  dma_category_opt_in: "Recording has started for the category.",
  dma_category_opt_out: 'This category has been marked as "not essential".',
  stakeholder_feedback_request_for_category: "Feedback has been requested for this category.",
  stakeholder_feedback_revoke_request_for_category:
    "Feedback request has been revoked for this category.",
  dma_category_verify_material: "Impacts have been verified for this category.",
  dma_category_verify_financial: "Opportunities/risks have been verified for this category.",
  dma_category_verify: "The category has been verified.",
  dma_category_unverify_material: "Impact verification has been removed for this category.",
  dma_category_unverify_financial:
    "Opportunity/risk verification has been removed for this category.",
  dma_category_unverify: "Verification has been removed for this category.",
  dma_material_impact_create: "A new impact has been created.",
  dma_material_impact_update: "An impact has been edited.",
  dma_material_impact_update_dma_category: "An impact has been moved to another category.",
  dma_material_impact_delete: "An impact has been deleted.",
  dma_material_impact_state_update: "The status of the impact has been changed.",
  dma_financial_effect_create: "A new opportunity/risk has been created.",
  dma_financial_effect_update: "An opportunity/risk has been edited.",
  dma_financial_effect_update_dma_category:
    "An opportunity/risk has been moved to another category.",
  dma_financial_effect_delete: "An opportunity/risk has been deleted.",
  dma_financial_effect_state_update: "The status of the opportunity/risk has been updated.",
  manual_stakeholder_feedback_create: "Manual feedback has been created for the category.",
  manual_stakeholder_feedback_update: "Manual feedback has been edited.",
  manual_stakeholder_feedback_delete: "Manual stakeholder feedback has been deleted.",
  dma_stakeholder_submit_feedback: "A stakeholder has submitted their feedback.",
  dma_stakeholder_update_feedback: "A stakeholder has edited their feedback.",
  dma_stakeholder_create: "A new stakeholder has been created.",
  dma_stakeholder_update: "A stakeholder has been edited.",
  dma_stakeholder_delete: "A stakeholder has been deleted.",
  organization_structure_create: "A new organizational structure has been created.",
  organization_structure_update: "An organizational structure has been edited.",
  data_entry_object_create: "A new data entry object has been created.",
  data_entry_object_update: "A data entry object has been edited.",
  root_data_entry_object_create: "A new root data entry object has been created.",
  data_entry_object_input_parameter_create:
    "A new data entry parameter has been created for a data entry object.",
  data_entry_object_input_parameter_update:
    "A data entry parameter of a data entry object has been updated",
  data_entry_object_input_parameter_update_sources:
    "The sources of a data entry parameter of a data entry object have been updated.",
  data_entry_object_input_parameter_status_update:
    "The status of a data entry parameter of a data entry object has been changed.",
  data_entry_object_input_parameter_exclude:
    "A data entry parameter of a data entry object has been excluded from the report.",
  data_entry_object_input_parameter_include:
    "A data entry parameter of a data entry object has been included in the report.",
  data_entry_object_input_parameter_update_gap_analysis:
    "Values of the gap analysis of a data entry parameter of a data entry object have been updated.",
  data_entry_object_input_parameter_value_create:
    "A new value has been added for a data entry object.",
  data_entry_object_input_parameter_value_update:
    "A value of a data entry object has been changed.",
  data_entry_object_input_parameter_value_delete:
    "A value of a data entry object has been deleted.",
  esrs_topic_phase_in_decision_makes_use_of_phase_in:
    "An ESRS topic is now making use of the phase in option.",
  esrs_topic_phase_in_decision_no_longer_makes_use_of_phase_in:
    "An ESRS topic is no longer making use of the phase in option.",
  policy_create: "A new policy has been created.",
  policy_update: "A policy has been edited.",
  policy_delete: "A policy has been deleted.",
  action_create: "A new action has been created.",
  action_update: "An action has been edited.",
  action_delete: "An action has been deleted.",
  target_create: "A new target has been created.",
  target_update: "A target has been edited.",
  target_delete: "A target has been deleted.",
  source_create: "A new source has been created.",
  source_update: "A source has been edited.",
  source_delete: "A source has been deleted.",
  source_file_create: "A new source file has been created.",
  source_file_state_update: "The status of a source file has been updated.",
  source_file_delete: "A source file has been deleted.",
  data_entry_object_dma_configuration_execute_dma:
    "The DMA has been carried out for a data entry object.",
  user_update_organization_role: "The role of a user has been edited.",
  user_update_data_entry_objects_access: "The user's access to data entry objects has been edited.",
  // Emission Factors
  emission_factor_create: "A new emission factor has been created.",
  emission_factor_update: "An emission factor has been edited.",
  emission_factor_delete: "An emission factor has been deleted.",
  // Activities
  activity_create: "A new activity has been created.",
  activity_update: "An activity has been edited.",
  activity_delete: "An activity has been deleted.",
  activity_update_sources: "The sources of an activity have been edited.",
  recording_period_ghg_category_update_status: "The status of a GHG category has been updated.",
  // Activity values
  activity_value_create: "A new value has been added to an activity.",
  activity_value_update: "A value of an activity has been edited.",
  activity_value_delete: "A value of an activity has been deleted.",
};

const LEXICAL_BLOCK_TRANSLATIONS: Record<LexicalBlockType, string> = {
  paragraph: "Paragraph",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  bullet: "List",
  number: "Numbered List",
  check: "Checklist",
  quote: "Quote",
  code: "Code",
};

// NOTE: Make sure to keep the keys in sync with the German translations
export const TRANSLATIONS_EN: typeof TRANSLATIONS_DE = {
  module_access: {
    error_module_not_accessible_to_organization: "Module not accessible to organization!",
  },
  input_parameter_types: inputParameterTypesTranslations,
  static_unit_name: staticUnitTranslations,
  dr_eligible_for_phase_in_explanations: DRs_PHASE_IN_EXPLANATIONS,
  common: {
    logout_button_text: "Logout",
    no_changes: "No changes",
    title_warning: "Warning",
    countries: CountryNamesByCode,
    explanation_dialog_title: "Explanation",
    title_audit_logging: "Activity Protocol",
    placeholders: {
      search: "Search...",
    },
    error_field_required: "This field is required",
  },
  snack_bar_messages: {
    error_message: "Error: {{translatedAction}} - {{translatedError}}",
    actions: actionsTranslations,
  },
  user_picker_components: {
    selected_user_not_found: "Unknown",
    no_user_selected: "No user selected",
  },
  buttons: {
    create: "Create",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
    delete: "Delete",
    remove: "Remove",
    edit: "Edit",
    confirm: "Confirm",
    accept: "Accept",
    publish: "Publish",
    accept_understood: "Accept",
    move: "Move",
    show_explanation_button_tooltip: "Click to show explanation",
    add_comment: "Add Comment",
    remove_comment: "Remove Comment",
    tooltip_copy_link: "Copy link",
    tooltip_copy_link_success: "Copied!",
    clear_selection: "Clear selection",
  },
  popup_menu_filter_users_section_component: {
    users_search_no_results: "No results found for search",
  },
  side_bar_nav_items: {
    dashboard: "Dashboard",
    reporting: "Reporting",
    dma: "DMA",
    esrs: "ESRS",
    thg: "GHG",
    transformation: "Transformation",
    carbon_management: "Carbon Management",
    value_input: "Data Entry",
    target_paths: "Target Paths",
    target_paths_reference_budgets: "Reference Budgets",
    dr_assessments: "Disclosure Requirements",
    configuration: "Configuration",
    configuration_organization: "Organization",
    configuration_recording_periods: "Recording Periods",
    configuration_stakeholder: "Stakeholder",
    configuration_distribution_criteria: "Distribution Criteria",
    configuration_sources: "Sources",
    configuration_users: "Users",
    configuration_emission_factors: "Emission Factors",
  },
  top_bar: {
    recording_period: "Recording Period",
    change_recording_period_text:
      "Are you sure you want to change the recording period? You will be redirected to the dashboard.",
    data_entry_object: "Data Entry Object",
  },
  organizations_redirect_page: {
    no_associated_organizations: "Your user is not associated with any organizations.",
  },
  recording_periods_redirect_page: {
    no_recording_periods: "No recording periods have been set up for your organization.",
    button_create_recording_period: "Create Recording Period",
  },
  lexical: {
    block_types: LEXICAL_BLOCK_TRANSLATIONS,
  },
  lexical_ai: {
    ai_button_menu: {
      title: "AI-assisted options",
      expand_text: "Expand text",
      summarize_text: "Summarize text",
    },
    ai_suggestion_modal: {
      title: "AI-assisted suggestion",
    },
    button_accept: "Accept",
    button_discard: "Discard",
    ai_button_disabled_tooltip: "Select text to use AI capabilities",
    ai_button_enabled_tooltip: "AI assistant",
    error: {
      TEXT_TOO_LONG: "The entered text is too long. Please shorten the text.",
      BAD_REQUEST: "There was a problem with your request. Please check your input.",
      SERVER_ERROR: "A server error occurred. Please try again later.",
      UNKNOWN: "An unexpected error occurred. Please try again.",
    },
  },
  comments: {
    button: {
      label: "Comments",
    },
    notice_no_comments: {
      first: "No comments yet",
      second: "Be the first to leave a comment!",
    },
    text_editor: {
      placeholder: "Write a comment...",
    },
    tooltip_delete_button: "Delete comment",
    delete_dialog: {
      title: "Delete comment",
      content: "Are you sure you want to delete this comment?",
    },
  },
  audit_logs: {
    button: {
      label: "Activity",
    },
  },
  users: {
    anonymous_name: "Anonymous",
  },
  recording_period_actions_dashboard_section: {
    title_double_materiality_assessment: "$t(dma_common:title)",
    double_materiality_assessment_abbreviation: "DMA",
    text_double_materiality_assessment:
      "Conduct a double materiality assessment to determine the impacts, risks and opportunities of your organization.",
    button_double_materiality_assessment: "Conduct",
  },
  recording_period_recording_structures_cards_list: {
    structures_without_recording_model_header: "Without Recording Model",
    add_category_button: "Manually add topic",
    add_all_esrs_topics_button: "Add all ESRS topics",
  },
  init_esrs_confirm_dialog: {
    title: "Add all ESRS topics",
    content: "Are you sure you want to add all ESRS topics?",
  },
  evaluation_list_item: {
    evaluation_name: {
      emissions: "Evaluate Emissions",
    },
  },
  add_recording_structure_to_recording_period_dialog: {
    dialog_title: 'Add New Topic for "{{organizationName}}":',
    recording_models_empty: "No recording models available.",
    choose_recording_model_label: "Choose Recording Model",
    choose_recording_model_item_text: "Choose Recording Model",
    template_groups_empty:
      'No (further) topics available for recording model "{{recordingModelName}}".',
    choose_templates_group_label: "Choose Recording Structure",
    choose_templates_group_item_text: "Choose Recording Structure",
    choose_template_label: "Choose Template",
    templates_group_empty:
      'No templates for the "{{templatesGroupName}}" entry structure are available for your organization\'s licence. Please contact <a>{{email}}</a>.',
    choose_template_item_text: "Choose Template",
    accept_template_button_text: "Accept Template",
    cancel_button_text: "Cancel",
  },
  organization_structure_is_draft_notice: {
    organization_structure_is_draft:
      "You need to define and publish the organizational structure for the current recording period before you can enter values.",
    to_configuration_text: "Go to Configuration",
  },
  filter_bar: {
    choose_data_entry_object: "Choose Data Entry Object",
    clear_filter: "Clear Filter",
    clear: "Clear",
    no_entries_available: "No entries available",
    no_entries_found: "No entries found",
    search: "Search...",
    no_matching_results: "No matching results found.",
  },
  data_entry_object_filter: {
    responsiblePerson: "Responsible Person",
    contributingPeople: "Contributors",
    status: "Status",
    phaseIn: "Phase-In",
    optional: "Optional",
  },
  data_entry_object_values_overview_thg_component: {
    page_title: "Data Entry",
    edit_data_entry_object_input_parameters: "Adjust Values to be Captured",
    no_results_found: "No values to be captured found",
    tooltip_button_filter: "Filter",
    no_filterable_users_available: "No users assigned yet.",
    redirect_button: "Adjust values to be captured",
  },
  phase_in: {
    chip_label: "Phase-In",
  },
  data_entry_object_values_overview_esrs_component: {
    eu_law_label: "ESRS Standard",
    application_requirements_label: "Application Requirements",
    confirm_submit_incomplete_dr_title: 'Associated IPs not "Done"',
    confirm_submit_incomplete_dr_body:
      'Some associated IPs are not marked as "Done". Are you sure you want to set the DR status to "In Review" / "Done"?',
    button_add_table_row: "Add Row",
    update_valid_confirm_state_change_dialog: {
      title: "Already marked as Done",
      content:
        'The data point has already been marked as "Done". Are you sure you want to update the value and downgrade it to "In progress" or "Open"?',
    },
    phase_in_title: "Phase-In",
    phase_in_info_box: {
      text: "Minimum disclosures after use of phase-in provisions in accordance with Appendix C of ESRS 1",
    },
    materiality_not_material_chip_tooltip:
      "Connected to sub-topics marked as ‘not material’ in your materiality analysis.",
  },
  data_entry_object_values_overview_common: {
    no_contributing_users: "No contributors assigned",
    add_contributing_user_tooltip: "Assign contributors",
    no_more_contributing_users_to_assign: "No more contributors to assign",
    no_users_available_for_responsible_user: "No users available",
    add_responsible_user_tooltip: "Assign responsible person",
    no_responsible_user_value_text: "No responsible person",
    comment_label: "Comment",
    dependency_mismatch_info:
      "The recording of this data point is dependent on the entry for “{{parameterName}}”. This value does not currently have to be entered, but a value is already stored.",
    buttons: {
      previous_section: "Previous Section",
      next_section: "Next Section",
    },
    indicator_optional_text: "Optional",
    temporary_disabled_text:
      "This data point is currently being technically revised in consultation with our experts. Please contact <a>{{email}}</a> if you urgently need this data point.",
    // Materiality Info Box
    materiality_info_box_not_material: {
      title: "Topic Not Material",
      text: "Based on the results of your Double Materiality Assessment, this ESRS topic has been classified as “not material”. Data collection for this topic is therefore not required.",
    },
  },
  data_entry_object_values_input_table: {
    notice_empty: "No values recorded yet",
    duplicate_error:
      "Multiple rows with the same values for the following fields exist: {{fields}}",
  },
  options_input_component: {
    name_empty_option: "No selection",
  },
  data_entry_object_input_parameter_common: {
    status: {
      [IDataEntryObjectInputParameterStatus.Open]: {
        name: "Open",
        description: "No data has been provided.",
        action_needed: "User needs to input data.",
      },
      [IDataEntryObjectInputParameterStatus.InProgress]: {
        name: "In Progress",
        description: "Data has been entered, but not yet submitted.",
        action_needed: "User needs to review and submit data.",
      },
      [IDataEntryObjectInputParameterStatus.InReview]: {
        name: "In Review",
        description: "Data has been submitted and is awaiting validation.",
        action_needed: "Reviewer needs to review and approve/reject the data.",
      },
      [IDataEntryObjectInputParameterStatus.Done]: {
        name: "Done",
        description: "Data has been reviewed and approved by the reviewer.",
        action_needed: "None, data is finalized.",
      },
    },
    excluded_status: "Excluded",
    exclude_transition_name: "Exclude from Report",
    transition_actions: {
      submit_with_reviewer: "Submit for review",
      submit_without_reviewer: "Finalize without review",
      reset: "Reset",
      approve: "Approve",
      reject: "Reject",
      notice_only_responsible_person_can_approve_or_reject:
        "Only the responsible person can approve or reject.",
    },
    exclusion_reasons: {
      [IDataEntryObjectInputParameterExclusionReason.NonMaterial]: "Mark as non-material",
      [IDataEntryObjectInputParameterExclusionReason.OtherReason]: "Other reason",
      [IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn]: "Apply phase-in provision",
    },
    notice_exclusion_only_possible_as_responsible:
      "Only the responsible person can exclude values from the report.",
    notice_inclusion_only_possible_as_responsible:
      "Only the responsible person can restore values to the report.",
  },
  data_entry_object_input_parameter_exclusion_dialog: {
    dialog_title_exclude: "Why do you want to exclude the data?",
    dialog_title_already_excluded: "Data is excluded from the report",
    label_exclusion_reason: "Reason",
    label_explanation: "Short Explanation",
    label_explanation_optional: "Short Explanation (optional)",
    error_explanation_required: "Explanation is required for the chosen reason.",
    button_confirm_exclude: "Exclude",
    button_include: "Restore to Report",
    // Include Confirm Dialog
    include_confirm_dialog_title: "Restore to Report",
    include_confirm_dialog_body: "Are you certain that you want restore this value to the report?",
  },
  data_entry_object_input_parameters_dialog: {
    dialog_title: "Values to be Captured:",
    close_dialog: "Close",
  },
  data_entry_object_select_group_component: {
    button_settings: "Actions",
    button_category_select_all: "Select all directly subordinate data entry objects",
    button_category_deselect_all: "Deselect all directly subordinate data entry objects",
    button_select_all: "Select all subordinate data entry objects",
    button_deselect_all: "Deselect all subordinate data entry objects",
  },
  data_entry_object_input_parameters_select_group_component: {
    button_settings: "Actions",
    button_category_select_all: "Select All Values of this Topic and all its Subtopics",
    button_category_deselect_all: "Deselect All Values of this Topic and all its Subtopics",
    button_select_all: "Select All Values of this Topic",
    button_deselect_all: "Deselect All Values of this Topic",
  },
  data_entry_object_values_group: {
    category_empty: "At the moment no values or companies are captured.",
  },
  data_entry_object_values_group_input_parameter_component: {
    no_contributing_users: "No contributors assigned",
    add_contributing_user_tooltip: "Assign contributors",
    no_more_contributing_users_to_assign: "No more contributors to assign",
  },
  associated_iros_tab_component: {
    no_entries: {
      materialImpacts: "You have to create impacts in order to be able to select them here.",
      financialEffects: "You have to risks/opportunities in order to be able to select them here.",
    },
    no_results: {
      materialImpacts: "No impacts were found for the specified search.",
      financialEffects: "No opportunities or risks were found for the specified search.",
    },
  },
  associated_iros_dialog: {
    title: "Link Impacts, Risks and Opportunities",
    search_placeholder: "Search...",
    tab_titles: {
      materialImpacts: "Impacts",
      financialEffects: "Risks and opportunities",
    } as { [key in IROType]: string },
  },
  mdr_select_dialog: {
    table_header_name: "Title",
    table_header_topics: "Topics",
  },
  mdr_common: {
    name: "Title",
    iro_dropdown_text: "Choose associated Impacts, Risks & Opportunities",
    label_associated_policies: "Choose associated Policies",
    label_associated_actions: "Choose associated Actions",
    mdr_unit_groups: {
      [MdrUnitGroup.DIMENSIONLESS]: "No Unit",
      [MdrUnitGroup.MASS]: "Mass",
      [MdrUnitGroup.ENERGY]: "Energy",
      [MdrUnitGroup.ENERGY_PER_MONETARY]: "Energy / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.GHG_EMISSIONS]: "Emissions",
      [MdrUnitGroup.GHG_EMISSIONS_PER_MONETARY]:
        "Emissions / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.VOLUME]: "Volume",
      [MdrUnitGroup.VOLUME_PER_MONETARY]: "Volume / $t(mdr_common:mdr_unit_per_monetary_suffix)",
      [MdrUnitGroup.AREA]: "Area",
      [MdrUnitGroup.MONETARY]: "Monetary",
      other: "Other",
    },
    mdr_unit_per_monetary_suffix: "MM. €",
  },
  data_entry_object_policy_component: {
    present_policies: "Yes, Policies exist",
    no_present_policies: "No policies adopted or planned",
    button_choose_no_policies_for_topic: "No policies adopted",
    notice_chosen_to_not_adopt_policies: "No policies are being adopted for this topic.",
    button_undo_no_policies_adopted: "Policies adopted",
    tooltip_add_policy: "Create new policy",
    no_policies_defined: "No policies defined.",
    edit_policy: 'Edit policy <i>"{{title}}"</i>',
    create_policy: "Create new policy",
    delete_policy_tooltip: "Delete policy",
    confirm_dialog: {
      title: "Delete policy",
      body: "Are you sure you want to remove the policy?",
      button_confirm: "Remove",
    },
    section_titles: {
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Specific disclosures for climate change",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Specific disclosures for pollution",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Specific disclosures for water and marine resources",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Specific disclosures for biodiversity and ecosystems",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Specific disclosures for circular economy",
      esrs_s: "General Disclosures for social topics",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Specific disclosures for own workforce",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Specific disclosures for workforce in the value chain",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Specific disclosures for affected communities",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Specific disclosures for consumers and end-users",
    },
    labels: {
      general: {
        name: "Title",
        descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory:
          "Disclosure of consideration given to interests of key stakeholders in setting policy",
        descriptionOfKeyContentsOfPolicyExplanatory: "Disclosure of key contents of policy",
        descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory:
          "Disclosure of scope of policy or of its exclusions",
        descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory:
          "Disclosure of most senior level in organisation that is accountable for implementation of policy",
        referenceToThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicy:
          "Disclosure of third-party standards or initiatives that are respected through implementation of policy",
        explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementItExplanatory:
          "Explanation of how policy is made available to potentially affected stakeholders and stakeholders who need to help implement it",
        policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementIt:
          "Policy is made available to potentially affected stakeholders and stakeholders who need to help implement it",
      },
      e1: {
        addressedMatters: "Sustainability matters addressed by policy for climate change",
      },
      e2: {
        addressedMatters: "Sustainability matters addressed by policy for pollution",
        policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil:
          "Policy addresses mitigating negative impacts related to pollution of air, water and soil",
        policyEitherAddressesSubstitutingOrMinimisingUseOfSubstancesOfConcernAndOrPhasingOutSubstancesOfVeryHighConcern:
          "Policy addresses substituting and minimising use of substances of concern and phasing out substances of very high concern",
        policyAddressesAvoidingIncidentsAndOrEmergencySituationsAndIfWhenTheyOccurItEitherControlsOrLimitsTheirImpactOnPeopleAndEnvironment:
          "Policy addresses avoiding incidents and emergency situations, and if and when they occur, controlling and limiting their impact on people and environment",
      },
      e3: {
        addressedMatters:
          "Sustainability matters addressed by policy for water and marine resources",
        policyAddressesWaterManagement: "Policy addresses water management",
        policyAddressesEitherUseOrSourcingOfWaterAndOrMarineResourcesInOwnOperations:
          "Policy addresses use and sourcing of water and marine resources in own operations",
        policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater:
          "Policy addresses water treatment as a step towards more sustainable sourcing of water",
        policyAddressesPreventionAndOrAbatementOfWaterPollutionResultingFromActivities:
          "Policy addresses prevention and abatement of water pollution resulting from activities",
        policyAddressesEitherProductOrServiceDesignInViewOfAddressingWaterrelatedIssuesAndOrPreservingMarineResources:
          "Policy addresses product and service design in view of addressing water-related issues and preservation of marine resources",
        policyAddressesCommitmentToReducingMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndOrAlongUpstreamAndDownstreamValueChain:
          "Policy addresses commitment to reduce material water consumption in areas at water risk in own operations and along upstream and downstream value chain",
        policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems:
          "Policy prevents further deterioration and protects and enhances the status of water bodies and aquatic ecosystems",
        policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources:
          "Policy promotes sustainable water use based on long-term protection of available water resources",
        policyAimsAtEnhancingProtectionAndOrImprovingAquaticEnvironment:
          "Policy aims at enhanced protection and improvement of the aquatic environment",
        policyPromotesGoodEnvironmentalStatusOfMarineWater:
          "Policy promotes good environmental status of marine water",
        policyPromotesReductionOfWaterWithdrawalsAndOrWaterDischarges:
          "Policy promotes reduction of water withdrawals and water discharges",
        policyContributesEitherToGoodEcologicalAndOrChemicalQualityOfSurfaceWaterBodiesOrToGoodChemicalQualityAndOrQuantityOfGroundwaterBodies:
          "Policy contributes to good ecological and chemical quality of surface water bodies and good chemical quality and quantity of groundwater bodies",
        policyEitherMinimisesMaterialImpactsAndRisksOrImplementsMitigationMeasuresThatAimAtMaintainingValueAndFunctionalityOfPriorityServicesAndOrToIncreaseResourceEfficiencyInOwnOperations:
          "Policy minimises material impacts and risks and implements mitigation measures that aim to maintain value and functionality of priority services and to increase resource efficiency on own operations",
        policyAvoidsImpactsOnAffectedCommunities: "Policy avoids impacts on affected communities",
      },
      e4: {
        addressedMatters:
          "Sustainability matters addressed by policy for biodiversity and ecosystems",
        disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
          "Disclosure of how policy limits procurement from suppliers that cannot demonstrate that they are not contributing to significant damage to protected areas or key biodiversity areas",
        disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
          "Disclosure of how policy refers to recognised standards or third-party certifications overseen by regulators",
        disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
          "Disclosure of how policy addresses raw materials originating from ecosystems that have been managed to maintain or enhance conditions for biodiversity",
        sustainableDevelopmentGoalsToWhichPolicyIsConnected:
          "Sustainable development goals to which policy is connected (biodiversity and ecosystems)",
        disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
          "Disclosure of how policy enables to avoid negative impacts on biodiversity and ecosystems in own operations and related upstream and downstream value chain",
        disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
          "Disclosure of how policy enables to reduce and minimise negative impacts on biodiversity and ecosystems in own operations and throughout upstream and downstream value chain that cannot be avoided",
        disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
          "Disclosure of how policy enables to restore and rehabilitate degraded ecosystems or restore cleared ecosystems following exposure to impacts that cannot be completely avoided and/or minimised",
        thirdpartyStandardOfConductUsedInPolicyIsEitherObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesOrRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
          "Third-party standard of conduct used in policy is objective and achievable based on scientific approach to identifying issues and realistic in assessing how these issues can be addressed under a variety of practical circumstances",
        thirdpartyStandardOfConductUsedInPolicyIsEitherDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsAndNoGroupHoldingUndueAuthorityAndOrVetoPowerOverContent:
          "Third-party standard of conduct used in policy is developed or maintained through process of ongoing consultation with relevant stakeholders with balanced input from all relevant stakeholder groups with no group holding undue authority or veto power over content",
        thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
          "Third-party standard of conduct used in policy encourages stepwise approach and continuous improvement in standard and its application of better management practices and requires establishment of meaningful targets and specific milestones to indicate progress against principles and criteria over time",
        thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
          "Third-party standard of conduct used in policy is verifiable through independent certifying or verifying bodies which have defined and rigorous assessment procedures that avoid conflicts of interest and are compliant with ISO guidance on accreditation and verification procedures or Article 52 of Regulation (EC) No 765/2008",
        thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice:
          "Third-party standard of conduct used in policy conforms to ISEAL code of good practice",
        biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndOrEcosystemsrelatedImpacts:
          "Biodiversity and ecosystems-related policy relates to material biodiversity and ecosystems-related impacts",
        biodiversityAndEcosystemsrelatedPolicyRelatesToEitherMaterialDependenciesOrMaterialPhysicalAndOrTransitionRisksAndOpportunities:
          "Biodiversity and ecosystems-related policy relates to material dependencies and material physical and transition risks and opportunities",
        biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
          "Biodiversity and ecosystems-related policy supports traceability of products, components, and raw materials with material actual or potential impacts on biodiversity and ecosystems along value chain",
        biodiversityAndEcosystemsrelatedPolicyAddressesEitherProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainAndOrEnhanceConditionsForBiodiversity:
          "Biodiversity and ecosystems-related policy addresses production, sourcing, or consumption from ecosystems that are managed to maintain or enhance conditions for biodiversity",
        biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndOrEcosystemsrelatedImpacts:
          "Biodiversity and ecosystems-related policy addresses social consequences of biodiversity and ecosystems-related impacts",
      },
      e5: {
        addressedMatters:
          "Sustainability matters addressed by policy for resource use and circular economy",
        layersInWasteHierarchyThatPolicyAddresses: "Layer in waste hierarchy that policy addresses",
        policyAddressesTransitioningAwayFromUseOfVirginResources:
          "Policy addresses transitioning away from use of virgin resources",
        policyAddressesSustainableSourcingAndOrUseOfRenewableResources:
          "Policy addresses sustainable sourcing and use of renewable resources",
        policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteReuseRepairRefurbishRemanufactureAndRepurposeOverWasteTreatmentRecycling:
          "Policy addresses prioritisation of avoidance or minimisation of waste over waste treatment",
      },
      s: {
        groundsForDiscriminationSpecificallyCoveredByPolicy:
          "Grounds for discrimination that are specifically covered by policy",
        referenceForIdentificationOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusers:
          "Disclosure of aspects of policy that satisfy requirements of disclosure of policies related to own workforce, value chain workers, affected communities, consumers, and end-users",
        specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
          "Specific policy commitments related to inclusion or positive action for people from groups at particular risk of vulnerability in own workforce are in place",
        disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsAsWellAsExplanationOfHowPotentialBarriersForDisseminationAreIdentifiedAndRemovedExplanatory:
          "Disclosure of communication tools and channels aimed at ensuring that policy is accessible and that different audiences understand its implications",
        disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
          "Disclosure of specific policy commitments related to inclusion or positive action for people from groups at particular risk of vulnerability in own workforce",
        s1: {
          addressedMatters: "Sustainability matters addressed by policy for own workforce",
        },
        s2: {
          addressedMatters: "Sustainability matters addressed by policy for value chain workers",
        },
        s3: {
          addressedMatters: "Sustainability matters addressed by policy for affected communities",
        },
        s4: {
          addressedMatters:
            "Sustainability matters addressed by policy for consumers and end-users",
        },
      },
    },
    enums: {
      e1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
      },
      e2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
      },
      e3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
      },
      e4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
        sustainableDevelopmentGoalsToWhichPolicyIsConnected: {
          "esrs:EndPovertyInAllItsFormsEverywhereMember":
            "1 - End poverty in all its forms everywhere",
          "esrs:EndHungerAchieveFoodSecurityAndImprovedNutritionAndPromoteSustainableAgricultureMember":
            "2 - End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
          "esrs:EnsureHealthyLivesAndPromoteWellbeingForAllAtAllAgesMember":
            "3 - Ensure healthy lives and promote wellbeing for all at all ages",
          "esrs:EnsureInclusiveAndEquitableQualityEducationAndPromoteLifelongLearningOpportunitiesForAllMember":
            "4 - Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
          "esrs:AchieveGenderEqualityAndEmpowerAllWomenAndGirlsMember":
            "5 - Achieve gender equality and empower all women and girls",
          "esrs:EnsureAvailabilityAndSustainableManagementOfWaterAndSanitationForAllMember":
            "6 - Ensure availability and sustainable management of water and sanitation for all",
          "esrs:EnsureAccessToAffordableReliableSustainableAndModernEnergyForAllMember":
            "7 - Ensure access to affordable, reliable, sustainable, and modern energy for all",
          "esrs:PromoteSustainedInclusiveAndSustainableEconomicGrowthFullAndProductiveEmploymentAndDecentWorkForAllMember":
            "8 - Promote sustained, inclusive, and sustainable economic growth, full and productive employment, and decent work for all",
          "esrs:BuildResilientInfrastructurePromoteInclusiveAndSustainableIndustrializationAndFosterInnovationMember":
            "9 - Build resilient infrastructure, promote inclusive and sustainable industrialization, and foster innovation",
          "esrs:ReduceInequalityWithinAndAmongCountriesMember":
            "10 - Reduce inequality within and among countries",
          "esrs:MakeCitiesAndHumanSettlementsInclusiveSafeResilientAndSustainableMember":
            "11 - Make cities and human settlements inclusive, safe, resilient, and sustainable",
          "esrs:EnsureSustainableConsumptionAndProductionPatternsMember":
            "12 - Ensure sustainable consumption and production patterns",
          "esrs:TakeUrgentActionToCombatClimateChangeAndItsImpactsMember":
            "13 - Take urgent action to combat climate change and its impacts",
          "esrs:ConserveAndSustainablyUseOceansSeasAndMarineResourcesForSustainableDevelopmentMember":
            "14 - Conserve and sustainably use the oceans, seas, and marine resources for sustainable development",
          "esrs:ProtectRestoreAndPromoteSustainableUseOfTerrestrialEcosystemsSustainablyManageForestsCombatDesertificationAndHaltAndReverseLandDegradationAndHaltBiodiversityLossMember":
            "15 - Protect, restore, and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation, and halt biodiversity loss",
          "esrs:PromotePeacefulAndInclusiveSocietiesForSustainableDevelopmentProvideAccessToJusticeForAllAndBuildEffectiveAccountableAndInclusiveInstitutionsAtAllLevelsMember":
            "16 - Promote peaceful and inclusive societies for sustainable development, provide access to justice for all, and build effective, accountable, and inclusive institutions at all levels",
          "esrs:StrengthenMeansOfImplementationAndRevitalizeGlobalPartnershipForSustainableDevelopmentMember":
            "17 - Strengthen the means of implementation and revitalize the global partnership for sustainable development",
        },
      },
      e5: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
        layersInWasteHierarchyThatPolicyAddresses: {
          "esrs:PreventionMember": "Prevention",
          "esrs:PreparationForReuseMember": "Preparation for reuse",
          "esrs:RecyclingMember": "Recycling",
          "esrs:OtherRecoveryMember": "Other recovery",
          "esrs:DisposalMember": "Disposal",
        },
      },
      s: {
        groundsForDiscriminationSpecificallyCoveredByPolicy: {
          "esrs:RacialAndEthnicOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Racial and ethnic origin grounds for discrimination and diversity are specifically covered in policy",
          "esrs:ColourGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Colour grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SexGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sex grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SexualOrientationGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Sexual orientation grounds for discrimination and diversity are specifically covered in policy",
          "esrs:GenderIdentityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Gender identity grounds for discrimination and diversity are specifically covered in policy",
          "esrs:DisabilityGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Disability grounds for discrimination and diversity are specifically covered in policy",
          "esrs:AgeGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Age grounds for discrimination and diversity are specifically covered in policy",
          "esrs:ReligionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Religion grounds for discrimination and diversity are specifically covered in policy",
          "esrs:PoliticalOpinionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Political opinion grounds for discrimination and diversity are specifically covered in policy",
          "esrs:NationalExtractionGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "National extraction grounds for discrimination and diversity are specifically covered in policy",
          "esrs:SocialOriginGroundsForDiscriminationAndDiversityAreSpecificallyCoveredInPolicyMember":
            "Social origin grounds for discrimination and diversity are specifically covered in policy",
          "esrs:OtherFormsOfDiscriminationCoveredByEuRegulationAndNationalLawAreSpecificallyCoveredInPolicyMember":
            "Other forms of discrimination covered by EU regulation and national law are specifically covered in policy",
        },
        s1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
        },
        s2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
        },
        s3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
        },
        s4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
        },
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Please provide a name!",
        },
      },
    },
  },
  data_entry_object_action_component: {
    name: "Title",
    present_actions: "There are actions for this topic",
    no_present_actions: "There are no actions for this topic",
    button_choose_no_actions_for_topic: "No actions adopted",
    notice_chosen_to_not_define_actions: "No actions are being adopted for this topic.",
    tooltip_add_action: "Create new action",
    no_actions_defined: "No actions defined.",
    actions_adopted: "Actions adopted",
    edit_action: 'Edit action <i>"{{title}}"</i>',
    create_action: "Create new action",
    delete_action_tooltip: "Delete action",
    confirm_dialog: {
      title: "Delete action",
      body: "Are you sure you want to remove the action?",
      button_confirm: "Remove",
      button_cancel: "Cancel",
    },
    section_titles: {
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Specific disclosures for climate change",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Specific disclosures for pollution",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Specific disclosures for water and marine resources",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Specific disclosures for biodiversity and ecosystems",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Specific disclosures for circular economy",
      esrs_s: "General Disclosures for social topics",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Specific disclosures for own workforce",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Specific disclosures for workforce in the value chain",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Specific disclosures for affected communities",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Specific disclosures for consumers and end-users",
      esrs_e1_and_e4: "Common disclosures for climate change, biodiversity and ecosystems",
      capEx_opEx: "Financial resources",
    },
    labels: {
      general: {
        name: "Title",
        disclosureOfKeyActionExplanatory: "Disclosure of key action",
        statusOfKeyAction: "Status of key action",
        timeHorizonsUnderWhichKeyActionIsToBeCompleted:
          "Time horizon under which key action is to be completed",
        disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory:
          "Disclosure of expected outcomes of key action and how its implementation contributes to achievement of policy objectives and targets",
        descriptionOfScopeOfKeyActionCoverageInTermsOfActivitiesUpstreamAndOrDownstreamValueChainGeographiesAndAffectedStakeholderGroupsExplanatory:
          "Description of scope of key action",
        keyActionCoverage: "Key action coverage",
        keyActionIsTakenToEitherProvideForCooperateInOrInSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts:
          "Key action is taken to provide for and cooperate in or support provision of remedy for those harmed by actual material impacts",
        disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory:
          "Disclosure of quantitative and qualitative information regarding progress of actions or action plans disclosed in prior periods",
        descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory:
          "Description of type of current and future financial and other resources allocated to action plan",
        abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions:
          "Ability to implement action or action plan depends on specific preconditions",
        explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory:
          "Explanation of how current financial resources relate to most relevant amounts presented in financial statements",
        financialAllocationTable: {
          allocatedResources: "Allocated Resources",
          shortTerm: "Short-Term (1 year)",
          midTerm: "Medium-Term (1-3 years)",
          longTerm: "Long-Term (3+ years)",
          financialResourcesAllocatedToActionPlanOpEx: "OpEx",
          financialResourcesAllocatedToActionPlanCapEx: "CapEx",
        },
      },
      e1: {
        addressedMatters: "Sustainability matters addressed by action for climate change",
        decarbonisationLeverTypes: "Type of decarbonisation lever used",
        adaptationSolutionTypes: "Type of adaptation solution implemented",
        achievedGHGEmissionReductions: "Achieved GHG emission reductions",
        expectedGHGEmissionReductions: "Expected GHG emission reductions",
        explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory:
          "Explanation of the extent to which ability to implement action depends on availability and allocation of resources",
        explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory:
          "Explanation of any potential differences between significant OpEx and CapEx disclosed under ESRSE1 and key performance indicators disclosed under Commission Delegated Regulation EU 2021/NO 2178",
      },
      e2: {
        addressedMatters: "Sustainability matters addressed by action for pollution",
        actionPlanHasBeenImplementedAtSiteLevel: "This action is implemented at the site level",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution:
          "Layer in mitigation hierarchy to which action can be allocated to pollution",
      },
      e3: {
        addressedMatters:
          "Sustainability matters addressed by action for water and marine resources",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources:
          "Layer in mitigation hierarchy to which action can be allocated to water and marine resources",
        actionRelatesToAreasAtWaterRisk: "Action relates to areas at water risk",
        actionRelatesToAreasOfHighwaterStress: "Action relates to areas of high water stress",
        informationAboutSpecificCollectiveActionExplanatory:
          "Information about specific collective action for water and marine resources",
      },
      e4: {
        addressedMatters:
          "Sustainability matters addressed by action for biodiversity and ecosystems",
        layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems:
          "Layer in mitigation hierarchy to which action can be allocated to biodiversity and ecosystems",
        disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsExplanatory:
          "Disclosure of how mitigation hierarchy has been applied with regard to actions on biodiversity and ecosystems",
        biodiversityOffsetsWereUsedInActionPlan: "Biodiversity offsets were used in action plan",
        disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory:
          "Disclosure of aim of biodiversity offset and key performance indicators used",
        financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets:
          "Financing effects direct and indirect costs of biodiversity offsets",
        descriptionOfBiodiversityOffsetsExplanatory: "Description of biodiversity offsets",
        localAndIndigenousKnowledgeAndOrNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction:
          "Local and indigenous knowledge and nature-based solutions have been incorporated into biodiversity and ecosystems-related action",
        disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory:
          "Disclosure of list of key stakeholders involved in key action and how they are involved, key stakeholders negatively or positively impacted by key action and how they are impacted",
        explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory:
          "Explanation of need for appropriate consultations and need to respect decisions of affected communities",
        descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsExplanatory:
          "Description of whether key action may induce significant negative sustainability impacts on biodiversity and ecosystems",
        keyActionOccurrenceBiodiversityAndEcosystems:
          "Key action occurrence biodiversity and ecosystems",
        keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResources:
          "Key action plan is carried out only by undertaking using its resources, biodiversity and ecosystems",
        keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributes:
          "Key action plan is part of wider initiative to which undertaking significantly contributes, biodiversity and ecosystems",
        disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsExplanatory:
          "Disclosure of additional information about project, its sponsors and other participants, biodiversity and ecosystems",
        descriptionOfHowKeyActionContributesToSystemwideChangeExplanatory:
          "Description of how key action contributes to system-wide change, biodiversity and ecosystems",
      },
      e5: {
        addressedMatters:
          "Sustainability matters addressed by action for resource use and circular economy",
        actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndOrWater:
          "Action covers higher levels of resource efficiency in use of technical and biological materials and water",
        actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates:
          "Action covers higher rates of use of secondary raw materials recyclates",
        actionCoversApplicationOfCircularDesignAndOrHigherRatesOfEitherReuseRepairRefurbishingRemanufactureRepurposingOrRecycling:
          "Action covers application of circular design and higher rates of reuse, repair, refurbishing, remanufacture, repurposing, and recycling",
        actionCoversApplicationOfCircularBusinessPractices:
          "Action covers application of circular business practices",
        actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain:
          "Action covers actions taken to prevent waste generation in upstream and downstream value chain",
        actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy:
          "Action covers optimisation of waste management in line with waste hierarchy",
        actionContributesToCollectiveAction:
          "This action is part of a collective action with value chain partners or local networks to improve circularity of products and materials",
        descriptionOfContributionToCircularEconomy:
          "Description of contribution to circular economy, , including for instance smart waste collection systems",
        descriptionOfOtherStakeholdersInvolved:
          "Description of other stakeholders involved in the collective action (e.g. competitors, suppliers , retailers, customers,..)",
        descriptionOfOrganisationOfProject:
          "Description of organisation of project and the roles of the different stakeholders",
      },
      s1: {
        addressedMatters: "Sustainability matters addressed by action for own workforce",
      },
      s2: {
        addressedMatters: "Sustainability matters addressed by action for value chain workers",
      },
      s3: {
        addressedMatters: "Sustainability matters addressed by action for affected communities",
      },
      s4: {
        addressedMatters: "Sustainability matters addressed by action for consumers and end-users",
      },
      e1And4: {
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory:
          "Explanation of the relationship of significant capital expenditures (CapEx) and operational expenditures (OpEx) required to implement actions taken or planned to relevant line items or notes in financial statements",
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory:
          "Explanation of the relationship of significant CapEx and OpEx required to implement actions taken or planned to key performance indicators required under Commission Delegated Regulation EU 2021/NO 2178",
        explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory:
          "Explanation of the relationship of significant CapEx and OpEx required to implement actions taken or planned to the CapEx plan required by Commission Delegated Regulation EU 2021/NO 2178",
      },
    },
    enums: {
      general: {
        statusOfKeyAction: {
          "esrs:TakenMember": "Taken",
          "esrs:PlannedMember": "Planned",
        },
        timeHorizonsUnderWhichKeyActionIsToBeCompleted: {
          "esrs:ShorttermMember": "Short term (1 year)",
          "esrs:MediumtermMember": "Medium term (2-5 years)",
          "esrs:LongtermMember": "Long term (>5 years)",
        },
        keyActionCoverage: {
          "esrs:UpstreamValueChainMember": "Upstream value chain",
          "esrs:DownstreamValueChainMember": "Downstream value chain ",
          "esrs:UndertakingsActivitiesMember": "Undertaking's own activities",
          "esrs:GeographiesMember": "Geographies",
          "esrs:AffectedStakeholderGroupsMember": "Affected stakeholder groups",
        },
      },
      e1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
        decarbonisationLeverTypes: {
          "esrs:EnergyEfficiencyAndConsumptionReductionMember":
            "Energy efficiency and consumption reduction",
          "esrs:MaterialEfficiencyAndConsumptionReductionMember":
            "Material efficiency and consumption reduction",
          "esrs:FuelSwitchingMember": "Fuel switching",
          "esrs:ElectrificationMember": "Electrification",
          "esrs:UseOfRenewableEnergyMember": "Use of renewable energy",
          "esrs:PhaseOutSubstitutionOrModificationOfProductMember":
            "Phase-out, substitution, or modification of product",
          "esrs:PhaseOutSubstitutionOrModificationOfProcessMember":
            "Phase-out, substitution, or modification of process",
          "esrs:SupplyChainDecarbonisationMember": "Supply chain decarbonisation",
          "esrs:OtherDecarbonisationLeversMember": "Other decarbonisation levers",
        },
        adaptationSolutionTypes: {
          "esrs:NaturebasedMember": "Nature-based",
          "esrs:EngineeringMember": "Engineering",
          "esrs:TechnologicalMember": "Technological",
          "esrs:OtherThanNaturebasedEngineeringOrTechnologicalMember":
            "Other than nature-based, engineering, or technological",
        },
      },
      e2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution: {
          "esrs:PollutionAvoidancePreventionOfPollutionAtSourceMember":
            "Pollution avoidance, prevention of pollution at source",
          "esrs:PollutionReductionMinimisationOfPollutionMember":
            "Pollution reduction, minimisation of pollution",
          "esrs:RestorationRegenerationAndTransformationOfEcosystemsWherePollutionHasOccurredControlOfImpactsFromRegularActivitiesAndIncidentsMember":
            "Restoration, regeneration, and transformation of ecosystems where pollution has occurred; control of impacts from regular activities and incidents",
        },
      },
      e3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources: {
          "esrs:AvoidanceOfUseOfWaterAndMarineResourcesMember":
            "Avoidance of use of water and marine resources",
          "esrs:ReductionOfUseOfWaterAndMarineResourcesMember":
            "Reduction of use of water and marine resources",
          "esrs:ReclaimingAndReuseOfWaterMember": "Reclaiming and reuse of water",
          "esrs:RestorationAndRegenerationOfAquaticEcosystemsAndWaterBodiesMember":
            "Restoration and regeneration of aquatic ecosystems and water bodies",
        },
      },
      e4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
        layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems: {
          "esrs:AvoidanceMember": "Avoidance",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember":
            "Avoidance through site selection",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember":
            "Avoidance through project design",
          "esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember":
            "Avoidance through scheduling",
          "esrs:MinimisationMember": "Minimisation",
          "esrs:RestorationAndRehabilitationMember": "Restoration and rehabilitation",
          "esrs:CompensationOrOffsetsMember": "Compensation or offsets",
        },
      },
      e5: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
      },
      s1: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
      },
      s2: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
      },
      s3: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
      },
      s4: {
        addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
      },
    },
    input_errors: {
      general: {
        name: {
          required: "Please provide a name!",
        },
      },
    },
  },
  data_entry_object_target_component: {
    name: "Title",
    present_targets: "There are targets for this topic",
    no_present_targets: "There are no targets for this topic",
    button_choose_no_targets_for_topic: "No targets adopted",
    notice_chosen_to_not_define_targets: "No targets are being adopted for this topic.",
    tooltip_add_target: "Create new target",
    no_targets_defined: "No targets defined.",
    targets_adopted: "Targets adopted",
    edit_target: 'Edit target <i>"{{title}}"</i>',
    create_target: "Create new target",
    delete_target_tooltip: "Delete target",
    confirm_dialog: {
      title: "Delete target",
      body: "Are you sure you want to remove the target?",
      button_confirm: "Remove",
      button_cancel: "Cancel",
    },
    section_titles: {
      esrs_e: "General disclosures for environmental topics",
      esrs_e2_to_e5:
        "Common information on climate change, environmental pollution, water and marine resources, biodiversity and ecosystems, circular economy",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E1]:
        "Specific disclosures for climate change",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E2]:
        "Specific disclosures for pollution",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E3]:
        "Specific disclosures for water and marine resources",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E4]:
        "Specific disclosures for biodiversity and ecosystems",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.E5]:
        "Specific disclosures for circular economy",
      esrs_s: "General Disclosures for social topics",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S1]:
        "Specific disclosures for own workforce",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S2]:
        "Specific disclosures for workforce in the value chain",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S3]:
        "Specific disclosures for affected communities",
      [IInputParameterValueMetaDataEsrsTopicIdentifierEnum.S4]:
        "Specific disclosures for consumers and end-users",
    },
    labels: {
      general: {
        name: "Name or identifier of target",
        disclosureOfMeasurableOutcomeorientedAndTimeboundTargetExplanatory:
          "Disclosure of measurable outcome-oriented and time-bound target",
        identifiersOfRelatedImpactsRisksAndOpportunities:
          "Select related impacts, risks and opportunities",
        identifiersOfRelatedPolicies: "Select related policies",
        measurableTargetAbsoluteValue: "Measurable target (absolute value)",
        measurableTargetPercentage: "Measurable target (percentage)",
        absoluteOrRelativeTarget: "Absolute or relative target",
        esrsMetricsAndMonetaryAmountsUsedForTarget: "ESRS metric(s) used for target",
        descriptionOfScopeOfTargetExplanatory: "Description of scope of target",
        baselineValueOfMeasurableTargetAbsoluteValue:
          "Baseline value of measurable target (absolute value)",
        baseYearFromWhichProgressIsMeasured: "Base year from which progress is measured",
        periodToWhichTargetAppliesStartYear: "Period to which target applies (start year)",
        periodToWhichTargetAppliesEndYear: "Period to which target applies (end year)",
        descriptionOfMethodologiesAndSignificantAssumptionsUsedToDefineTargetExplanatory:
          "Description of methodologies and significant assumptions used to define target",
        stakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatter:
          "Have stakeholders been involved in target setting?",
        disclosureOfHowStakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatterExplanatory:
          "Disclosure of how stakeholders have been involved in target setting",
        descriptionOfAnyChangesInTargetAndCorrespondingMetricsOrUnderlyingMeasurementMethodologiesSignificantAssumptionsLimitationsSourcesAndAdoptedProcessesToCollectDataExplanatory:
          "Description of any changes in target and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and adopted processes to collect data",
        descriptionOfPerformanceAgainstDisclosedTargetExplanatory:
          "Description of performance against disclosed target",
        measurableTargetCurrentProgressAbsoluteValue:
          "Measurable target current progress (absolute value)",
        measurableTargetCurrentProgressPercentage:
          "Measurable target current progress (percentage)",
        progressIsInLineWithWhatHadBeenInitiallyPlanned:
          "Is progress in line with what was initially planned?",
      },
      e: {
        targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence:
          "Is the target related to environmental matters based on conclusive scientific evidence?",
        e2ToE5: {
          ecologicalThresholdsAndOrEntityspecificAllocationsWereTakenIntoConsiderationWhenSettingTarget:
            "Were ecological thresholds and entity-specific allocations taken into consideration when setting the target?",
          disclosureOfEcologicalThresholdsIdentifiedAndMethodologyUsedToIdentifyEcologicalThresholdsExplanatory:
            "Disclosure of ecological thresholds identified and methodology used to identify ecological thresholds",
          ecologicalThresholdsAreEntityspecific: "Are ecological thresholds entity-specific?",
          disclosureOfHowEcologicalEntityspecificThresholdsWereDeterminedExplanatory:
            "Disclosure of how ecological entity-specific thresholds were determined",
          disclosureOfHowResponsibilityForRespectingIdentifiedEcologicalThresholdsIsAllocatedExplanatory:
            "Disclosure of how responsibility for respecting identified ecological thresholds is allocated",
        },
        e1: {
          addressedMatters: "Sustainability matters addressed by target for climate change",
          descriptionOfHowItHasBeenEnsuredThatBaselineValueIsRepresentativeInTermsOfActivitiesCoveredAndInfluencesFromExternalFactorsExplanatory:
            "Description of how it has been ensured that baseline value is representative in terms of activities covered and influences from external factors",
          descriptionOfHowNewBaselineValueAffectsNewTargetItsAchievementAndPresentationOfProgressOverTimeExplanatory:
            "Description of how new baseline value affects new target, its achievement and presentation of progress over time",
          disclosureOfPastProgressMadeInMeetingTargetBeforeCurrentBaseYearExplanatory:
            "Disclosure of past progress made in meeting target before current base year",
          targetIsDerivedUsingSectoralDecarbonisationPathway:
            "Is the target derived using a sectoral decarbonisation pathway?",
          disclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory:
            "Disclosure of framework and methodology that has been used to determine GHG emission reduction target, underlying climate and policy scenarios for target, how future developments have been considered and how these will potentially impact GHG emissions and emissions reductions",
          explanationOfHowTargetIsCompatibleWithLimitingOfGlobalWarmingToOneAndHalfDegreesCelsiusInLineWithParisAgreementExplanatory:
            "Explanation of how targets are compatible with limiting of global warming to one and half degrees Celsius in line with Paris Agreement",
          ghgEmissionReductionTargetIsScienceBasedAndCompatibleWithLimitingGlobalWarmingToOneAndHalfDegreesCelsius:
            "GHG emission reduction target is science based and compatible with limiting global warming to one and half degrees Celsius",
          targetHasBeenExternallyAssured: "Target has been externally assured",
          descriptionOfExpectedDecarbonisationLeversAndTheirOverallQuantitativeContributionsToAchieveGHGEmissionReductionTargetExplanatory:
            "Description of expected decarbonisation levers and their overall quantitative contributions to achieve GHG emission reduction target",
          newTechnologiesArePlannedToBeAdoptedToAchieveGHGEmissionReductionTarget:
            "New technologies are planned to be adopted to achieve GHG emission reduction target",
          diverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLevers:
            "Diverse range of climate scenarios have been considered to detect relevant environmental, societal, technology, market and policy-related developments and determine decarbonisation levers",
          disclosureOfHowDiverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLeversExplanatory:
            "Disclosure of how diverse range of climate scenarios have been considered to detect relevant environmental, societal, technology, market and policy-related developments and determine decarbonisation levers",
          explanationOfHowConsistencyOfGHGEmissionReductionTargetWithGHGInventoryBoundariesHasBeenEnsuredExplanatory:
            "Explanation of how consistency of GHG emission reduction targets with GHG inventory boundaries has been ensured",
          greenhouseGasesCoveredByEmissionReductionTarget:
            "Greenhouse gas(es) covered by emission reduction target",
        },
        e2: {
          addressedMatters: "Sustainability matters addressed by target for pollution",
          targetRelatesToPreventionAndControlOfAirPollutantsAndRespectiveSpecificLoads:
            "Target relates to prevention and control of air pollutants and respective specific loads",
          targetRelatesToEitherPreventionOrControlOfEmissionsToWaterAndOrToRespectiveSpecificLoads:
            "Target relates to prevention and control of emissions to water and respective specific loads",
          targetRelatesToEitherPreventionOrControlOfPollutionToSoilAndOrToRespectiveSpecificLoads:
            "Target relates to prevention and control of pollution to soil and respective specific loads",
          targetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcern:
            "Target relates to prevention and control of substances of concern and substances of very high concern",
          disclosureOfHowTargetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcernExplanatory:
            "Disclosure of how target relates to prevention and control of substances of concern and substances of very high concern",
          targetIsMandatoryRequiredByLegislation:
            "Pollution-related target is mandatory (required by legislation)",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForPollutionPreventionAndControl:
            "Pollution-related target addresses shortcomings related to Substantial Contribution criteria for Pollution Prevention and Control",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForPollutionPreventionAndControl:
            "Pollution-related target addresses shortcomings related to Do No Significant Harm criteria for Pollution Prevention and Control",
        },
        e3: {
          addressedMatters:
            "Sustainability matters addressed by target for water and marine resources",
          targetRelatesToManagementOfMaterialImpactsRisksAndOpportunitiesRelatedToAreasAtWaterRiskIncludingImprovementOfWaterQuality:
            "Target relates to management of material impacts, risks and opportunities related to areas at water risk, including improvement of water quality",
          targetRelatesToResponsibleManagementOfMarineResourcesImpactsRisksAndOpportunitiesIncludingNatureAndOrQuantityOfMarineResourcesrelatedCommoditiesUsedByUndertaking:
            "Target relates to responsible management of marine resources impacts, risks and opportunities, including nature and quantity of marine resources-related commodities used by undertaking",
          targetRelatesToReductionOfWaterConsumptionIncludingInAreasAtWaterRiskAndOrInAreasOfHighWaterstress:
            "Target relates to reduction of water consumption, including in areas at water risk and in areas of high water-stress",
          targetRelatesToReductionOfWaterWithdrawals:
            "Target relates to reduction of water withdrawals",
          targetRelatesToReductionOfWaterDischarges:
            "Target relates to reduction of water discharges",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForWaterAndMarineResources:
            "Water and marine resources-related target addresses shortcomings related to Substantial Contribution criteria for Water and Marine Resources",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForWaterAndMarineResources:
            "Water and marine resources-related target addresses shortcomings related to Do No Significant Harm criteria for Water and Marine Resources",
        },
        e4: {
          addressedMatters:
            "Sustainability matters addressed by target for biodiversity and ecosystems",
          targetIsInformedByAndOrAlignedWithKunmingmontrealGlobalBiodiversityFramework:
            "Target is informed by and (or) aligned with Kunming-Montreal Global Biodiversity Framework",
          targetIsInformedByAndOrAlignedWithRelevantAspectsOfEuBiodiversityStrategyFor2030:
            "Target is informed by and (or) aligned with relevant aspects of EU Biodiversity Strategy for 2030",
          targetIsInformedByAndOrAlignedWithOtherBiodiversityAndEcosystemrelatedNationalPoliciesAndLegislation:
            "Target is informed by and (or) aligned with other biodiversity and ecosystem-related national policies and legislation",
          biodiversityOffsetsWereUsedInSettingTarget:
            "Biodiversity offsets were used in setting target",
          layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems:
            "Layer in mitigation hierarchy to which target can be allocated (biodiversity and ecosystems)",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForBiodiversity:
            "Biodiversity and ecosystems-related target addresses shortcomings related to Substantial Contribution criteria for Biodiversity",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForBiodiversity:
            "Biodiversity and ecosystems-related target addresses shortcomings related to Do No Significant Harm criteria for Biodiversity",
        },
        e5: {
          addressedMatters:
            "Sustainability matters addressed by target for resource use and circular economy",
          targetRelatesToIncreaseOfCircularProductDesign:
            "Target relates to increase of circular product design",
          targetRelatesToIncreaseOfCircularMaterialUseRate:
            "Target relates to increase of circular material use rate",
          targetRelatesToMinimisationOfPrimaryRawMaterial:
            "Target relates to minimisation of primary raw material",
          targetRelatesToSustainableSourcingAndOrUseOfRenewableResources:
            "Target relates to sustainable sourcing and use of renewable resources",
          targetRelatesToWasteManagement: "Target relates to waste management",
          layersInWasteHierarchyToWhichTargetRelates:
            "Layer in waste hierarchy to which target relates",
          targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForCircularEconomy:
            "Resource use and circular economy-related target addresses shortcomings related to Substantial Contribution criteria for Circular Economy",
          targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForCircularEconomy:
            "Resource use and circular economy-related target addresses shortcomings related to Do No Significant Harm criteria for Circular Economy",
        },
      },
      s: {
        targetRelationshipToImpactsRisksAndOpportunities:
          "Target relationship to impacts, risks and opportunities",
        s1: {
          addressedMatters: "Sustainability matters addressed by target for own workforce",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Disclosure of intended outcomes to be achieved in lives of people in own workforce",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Disclosure of stability of target over time in terms of definitions and methodologies to enable comparability",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Disclosure of standards or commitments on which target is based",
        },
        s2: {
          addressedMatters: "Sustainability matters addressed by target for value chain workers",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Disclosure of intended outcomes to be achieved in lives of people in value chain workers",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Disclosure of stability of target over time in terms of definitions and methodologies to enable comparability",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Disclosure of standards or commitments on which target is based",
        },
        s3: {
          addressedMatters: "Sustainability matters addressed by target for affected communities",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Disclosure of intended outcomes to be achieved in lives of people in affected communities",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Disclosure of stability of target over time in terms of definitions and methodologies to enable comparability",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Disclosure of standards or commitments on which target is based",
        },
        s4: {
          addressedMatters:
            "Sustainability matters addressed by target for consumers and end-users",
          disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
            "Disclosure of intended outcomes to be achieved in lives of people in consumers and end-users",
          disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
            "Disclosure of stability of target over time in terms of definitions and methodologies to enable comparability",
          disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
            "Disclosure of standards or commitments on which target is based",
        },
      },
    },
    enums: {
      general: {
        absoluteOrRelativeTarget: {
          "esrs:AbsoluteTargetMember": "Absolute target",
          "esrs:RelativeTargetMember": "Relative target",
        },
        esrsMetricsAndMonetaryAmountsUsedForTarget: {
          "esrs:AbsoluteValueOfLocationBasedScope2GreenhouseGasEmissionsReduction":
            "Absolute value of location-based Scope 2 Greenhouse gas emissions reduction",
          "esrs:AbsoluteValueOfMarketBasedScope2GreenhouseGasEmissionsReduction":
            "Absolute value of market-based Scope 2 Greenhouse gas emissions reduction",
          "esrs:AbsoluteValueOfScope1GreenhouseGasEmissionsReduction":
            "Absolute value of Scope 1 Greenhouse gas emissions reduction",
          "esrs:AbsoluteValueOfScope3GreenhouseGasEmissionsReduction":
            "Absolute value of Scope 3 Greenhouse gas emissions reduction",
          "esrs:AbsoluteValueOfTotalGreenhouseGasEmissionsReduction":
            "Absolute value of total Greenhouse gas emissions reduction",
          "esrs:AchievedGHGEmissionReductions": "Achieved GHG emission reductions",
          "esrs:AdjustingItemsToAssetsAtMaterialPhysicalRiskInReconciliationWithFinancialStatement":
            "Adjusting items to assets at material physical risk, in reconciliation with financial statement",
          "esrs:AdjustingItemsToAssetsAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Adjusting items to assets at material transition risk, in reconciliation with financial statement",
          "esrs:AdjustingItemsToLiabilitiesAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Adjusting items to liabilities at material transition risk, in reconciliation with financial statement",
          "esrs:AdjustingItemsToNetRevenueAtMaterialPhysicalRiskInReconciliationWithFinancialStatement":
            "Adjusting items to net revenue at material physical risk, in reconciliation with financial statement",
          "esrs:AdjustingItemsToNetRevenueAtMaterialTransitionRiskInReconciliationWithFinancialStatement":
            "Adjusting items to net revenue at material transition risk, in reconciliation with financial statement",
          "esrs:AmountOfCarbonCreditsOutsideValueChainPlannedToBeCancelledInFuture":
            "Amount of carbon credits outside value chain planned to be cancelled in future",
          "esrs:AmountOfCarbonCreditsOutsideValueChainThatAreVerifiedAgainstRecognisedQualityStandardsAndCancelled":
            "Amount of carbon credits outside value chain that are verified against recognised quality standards and cancelled",
          "esrs:AmountOfFinesForViolationOfAnticorruptionAndAntibriberyLaws":
            "Amount of fines for violation of anti-corruption and anti-bribery laws",
          "esrs:AmountOfFinesPenaltiesAndCompensationForDamagesAsResultOfIncidentesAndComplaints":
            "Amount of fines, penalties, and compensation for damages as result of incidents and complaints",
          "esrs:AmountOfFinesPenaltiesAndCompensationForDamagesForSevereHumanRightsIncidentsConnectedToOwnWorkforce":
            "Amount of fines, penalties, and compensation for damages for severe human rights incidents connected to own workforce",
          "esrs:AmountOfInternalAndExternalLobbyingExpenses":
            "Amount of internal and external lobbying expenses",
          "esrs:AmountOfSubstancesOfConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured":
            "Amount of substances of concern that are generated or used during production or that are procured",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissions":
            "Amount of substances of concern that leave facilities as emissions",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices":
            "Amount of substances of concern that leave facilities as emissions, as products, or as part of products or services",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsPartOfProducts":
            "Amount of substances of concern that leave facilities as part of products",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsProducts":
            "Amount of substances of concern that leave facilities as products",
          "esrs:AmountOfSubstancesOfConcernThatLeaveFacilitiesAsServices":
            "Amount of substances of concern that leave facilities as services",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatAreGeneratedOrUsedDuringProductionOrThatAreProcured":
            "Amount of substances of very high concern that are generated or used during production or that are procured",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissions":
            "Amount of substances of very high concern that leave facilities as emissions",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsEmissionsAsProductsOrAsPartOfProductsOrServices":
            "Amount of substances of very high concern that leave facilities as emissions, as products, or as part of products or services",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsPartOfProducts":
            "Amount of substances of very high concern that leave facilities as part of products",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsProducts":
            "Amount of substances of very high concern that leave facilities as products",
          "esrs:AmountOfSubstancesOfVeryHighConcernThatLeaveFacilitiesAsServices":
            "Amount of substances of very high concern that leave facilities as services",
          "esrs:AmountPaidForMembershipToLobbyingAssociations":
            "Amount paid for membership to lobbying associations",
          "esrs:AnnualRemunerationRatio": "Annual remuneration ratio",
          "esrs:AreaCoveredByInvasiveAlienSpecies": "Area covered by invasive alien species",
          "esrs:AreaOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting":
            "Area of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting",
          "esrs:Assets": "Assets",
          "esrs:AssetsAtAcuteMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Assets at acute material physical risk before considering climate change adaptation actions",
          "esrs:AssetsAtChronicMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Assets at chronic material physical risk before considering climate change adaptation actions",
          "esrs:AssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Assets at material physical risk before considering climate change adaptation actions",
          "esrs:AssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions":
            "Assets at material transition risk before considering climate mitigation actions",
          "esrs:AverageNumberOfDaysToPayInvoiceFromDateWhenContractualOrStatutoryTermOfPaymentStartsToBeCalculated":
            "Average number of days to pay invoice from date when contractual or statutory term of payment starts to be calculated",
          "esrs:AverageNumberOfTrainingHoursPerEmployeeAndOrNonemployee":
            "Average number of training hours per employee and (or) non-employee",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope1GHGEmissions":
            "Biogenic emissions of CO2 from combustion or bio-degradation of biomass not included in Scope 1 GHG emissions",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassNotIncludedInScope2GHGEmissions":
            "Biogenic emissions of CO2 from combustion or bio-degradation of biomass not included in Scope 2 GHG emissions",
          "esrs:BiogenicEmissionsOfCO2FromCombustionOrBiodegradationOfBiomassThatOccurInUpstreamAndDownstreamValueChainNotIncludedInScope3GHGEmissions":
            "Biogenic emissions of CO2 from combustion or bio-degradation of biomass that occur in upstream and downstream value chain not included in Scope 3 GHG emissions",
          "esrs:BoardsGenderDiversityRatio": "Board's gender diversity ratio",
          "esrs:CapitalExpendituresCapExInConjunctionWithMajorIncidentsAndDeposits":
            "Capital expenditures (CapEx) in conjunction with major incidents and deposits",
          "esrs:CarbonPriceAppliedForEachMetricTonneOfGreenhouseGasEmission":
            "Carbon price applied for each metric tonne of greenhouse gas emission",
          "esrs:CarryingAmountOfAssetsAtMaterialPhysicalRisk":
            "Carrying amount of assets at material physical risk",
          "esrs:CarryingAmountOfAssetsAtMaterialTransitionRisk":
            "Carrying amount of assets at material transition risk",
          "esrs:CarryingAmountOfLiabilitiesAtMaterialTransitionRisk":
            "Carrying amount of liabilities at material transition risk",
          "esrs:CarryingAmountOfRealEstateAssets": "Carrying amount of real estate assets",
          "esrs:CarryingAmountOfRealEstateAssetsForWhichEnergyConsumptionIsBasedOnInternalEstimates":
            "Carrying amount of real estate assets for which energy consumption is based on internal estimates",
          "esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamAndCoolingFromRenewableSources":
            "Consumption of purchased or acquired electricity, heat, steam, and cooling from renewable sources",
          "esrs:ConsumptionOfPurchasedOrAcquiredElectricityHeatSteamOrCoolingFromFossilSources":
            "Consumption of purchased or acquired electricity, heat, steam, or cooling from fossil sources",
          "esrs:ConsumptionOfSelfgeneratedNonfuelRenewableEnergy":
            "Consumption of self-generated non-fuel renewable energy",
          "esrs:DurationOfClassroomTraining": "Duration of classroom training",
          "esrs:DurationOfComputerbasedTraining": "Duration of computer-based training",
          "esrs:DurationOfVoluntaryComputerbasedTraining":
            "Duration of voluntary computer-based training",
          "esrs:EmissionsToAir": "Emissions to air",
          "esrs:EmissionsToSoil": "Emissions to soil",
          "esrs:EmissionsToWater": "Emissions to water",
          "esrs:EnergyConsumptionFromFossilSources": "Energy consumption from fossil sources",
          "esrs:EnergyConsumptionFromNuclearSources": "Energy consumption from nuclear sources",
          "esrs:EnergyConsumptionFromRenewableSources": "Energy consumption from renewable sources",
          "esrs:EnergyConsumptionRelatedToOwnOperations":
            "Energy consumption related to own operations",
          "esrs:EnergyIntensityFromActivitiesInHighClimateImpactSectorsTotalEnergyConsumptionPerNetRevenue":
            "Energy intensity from activities in high climate impact sectors (total energy consumption per net revenue)",
          "esrs:EstimatedAmountOfPotentiallyStrandedAssets":
            "Estimated amount of potentially stranded assets",
          "esrs:ExpectedCostSavingsFromClimateChangeAdaptationActions":
            "Expected cost savings from climate change adaptation actions",
          "esrs:ExpectedCostSavingsFromClimateChangeMitigationActions":
            "Expected cost savings from climate change mitigation actions",
          "esrs:ExpectedGHGEmissionReductions": "Expected GHG emission reductions",
          "esrs:FinancialAndInKindPoliticalContributionsMade":
            "Financial and in-kind political contributions made",
          "esrs:FinancialPoliticalContributionsMade": "Financial political contributions made",
          "esrs:FinancingEffectsDirectAndIndirectCostsOfBiodiversityOffsets":
            "Financing effects (direct and indirect costs) of biodiversity offsets",
          "esrs:FuelConsumptionFromCoalAndCoalProducts":
            "Fuel consumption from coal and coal products",
          "esrs:FuelConsumptionFromCrudeOilAndPetroleumProducts":
            "Fuel consumption from crude oil and petroleum products",
          "esrs:FuelConsumptionFromNaturalGas": "Fuel consumption from natural gas",
          "esrs:FuelConsumptionFromOtherFossilSources":
            "Fuel consumption from other fossil sources",
          "esrs:FuelConsumptionFromRenewableSources": "Fuel consumption from renewable sources",
          "esrs:GenderPayGap": "Gender pay gap",
          "esrs:GHGEmissionsAssociatedWithRemovalActivity":
            "GHG emissions associated with removal activity",
          "esrs:GHGEmissionsIntensityLocationbasedTotalGHGEmissionsPerNetRevenue":
            "GHG emissions intensity, location-based (total GHG emissions per net revenue)",
          "esrs:GHGEmissionsIntensityMarketbasedTotalGHGEmissionsPerNetRevenue":
            "GHG emissions intensity, market-based (total GHG emissions per net revenue)",
          "esrs:GHGRemovalsAndStorage": "GHG removals and storage",
          "esrs:GrossGreenhouseGasEmissions": "Gross greenhouse gas emissions",
          "esrs:GrossLocationBasedScope2GreenhouseGasEmissions":
            "Gross location-based Scope 2 greenhouse gas emissions",
          "esrs:GrossMarketBasedScope2GreenhouseGasEmissions":
            "Gross market-based Scope 2 greenhouse gas emissions",
          "esrs:GrossScope1GreenhouseGasEmissions": "Gross Scope 1 greenhouse gas emissions",
          "esrs:GrossScope3GreenhouseGasEmissions": "Gross Scope 3 greenhouse gas emissions",
          "esrs:HazardousWaste": "Hazardous waste",
          "esrs:IncreaseDecreaseInNetRevenueFromLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess":
            "Increase (decrease) in net revenue from low-carbon products and services or adaptation solutions to which undertaking has or may have access",
          "esrs:IncreaseDecreaseInWaterStorage": "Increase (decrease) in water storage",
          "esrs:InkindPoliticalContributionsMade": "In-kind political contributions made",
          "esrs:IntensityValueOfLocationBasedScope2GreenhouseGasEmissionsReduction":
            "Intensity value of location-based Scope 2 Greenhouse gas emissions reduction",
          "esrs:IntensityValueOfMarketBasedScope2GreenhouseGasEmissionsReduction":
            "Intensity value of market-based Scope 2 Greenhouse gas emissions reduction",
          "esrs:IntensityValueOfScope1GreenhouseGasEmissionsReduction":
            "Intensity value of Scope 1 Greenhouse gas emissions reduction",
          "esrs:IntensityValueOfScope3GreenhouseGasEmissionsReduction":
            "Intensity value of Scope 3 Greenhouse gas emissions reduction",
          "esrs:IntensityValueOfTotalGreenhouseGasEmissionsReduction":
            "Intensity value of total Greenhouse gas emissions reduction",
          "esrs:Liabilities": "Liabilities",
          "esrs:LiabilitiesFromMaterialTransitionRisksThatMayHaveToBeRecognisedInFinancialStatements":
            "Liabilities from material transition risks that may have to be recognised in financial statements",
          "esrs:LocationBasedGreenhouseGasEmissions": "Location-based greenhouse gas emissions",
          "esrs:MarketBasedGreenhouseGasEmissions": "Market-based greenhouse gas emissions",
          "esrs:MicroplasticsGenerated": "Microplastics generated",
          "esrs:MicroplasticsGeneratedOrUsed": "Microplastics generated or used",
          "esrs:MicroplasticsUsed": "Microplastics used",
          "esrs:MonetisedGrossScope1And2GHGEmissions":
            "Monetised gross Scope 1 and 2 GHG emissions",
          "esrs:MonetisedTotalGHGEmissions": "Monetised total GHG emissions",
          "esrs:NatureorientedAreaOffSite": "Nature-oriented area off site",
          "esrs:NatureorientedAreaOnSite": "Nature-oriented area on site",
          "esrs:NetRevenueAtMaterialPhysicalRisk": "Net revenue at material physical risk",
          "esrs:NetRevenueAtMaterialTransitionRisk": "Net revenue at material transition risk",
          "esrs:NetRevenueOtherThanUsedToCalculateGHGIntensity":
            "Net revenue other than used to calculate GHG intensity",
          "esrs:NetRevenueUsedToCalculateGHGIntensity":
            "Net revenue used to calculate GHG intensity",
          "esrs:NonrecycledWaste": "Non-recycled waste",
          "esrs:NonrenewableEnergyProduction": "Non-renewable energy production",
          "esrs:NumberOfCasesOfRecordableWorkrelatedIllHealth":
            "Number of cases of recordable work-related ill health",
          "esrs:NumberOfComplaintsFiledThroughChannelsForPeopleInOwnWorkforceToRaiseConcerns":
            "Number of complaints filed through channels for people in own workforce to raise concerns",
          "esrs:NumberOfComplaintsFiledToNationalContactPointsForOECDMultinationalEnterprises":
            "Number of complaints filed to National Contact Points for OECD Multinational Enterprises",
          "esrs:NumberOfComplaintsReceivedFromConsumersAndEndusers":
            "Number of complaints received from consumers and end-users",
          "esrs:NumberOfConfirmedIncidentsInWhichOwnWorkersWereDismissedOrDisciplinedForCorruptionOrBriberyrelatedIncidents":
            "Number of confirmed incidents in which own workers were dismissed or disciplined for corruption or bribery-related incidents",
          "esrs:NumberOfConfirmedIncidentsOfCorruptionOrBribery":
            "Number of confirmed incidents of corruption or bribery",
          "esrs:NumberOfConfirmedIncidentsRelatingToContractsWithBusinessPartnersThatWereTerminatedOrNotRenewedDueToViolationsRelatedToCorruptionOrBribery":
            "Number of confirmed incidents relating to contracts with business partners that were terminated or not renewed due to violations related to corruption or bribery",
          "esrs:NumberOfConvictionsForViolationOfAnticorruptionAndAntibriberyLaws":
            "Number of convictions for violation of anti-corruption and anti-bribery laws",
          "esrs:NumberOfDaysLostToWorkrelatedInjuriesAndFatalitiesFromWorkrelatedAccidentsWorkrelatedIllHealthAndFatalitiesFromIllHealth":
            "Number of days lost to work-related injuries and fatalities from work-related accidents, work-related ill health and fatalities from ill health",
          "esrs:NumberOfEmissionAllowancesStoredFromPreviousAllowancesAtBeginningOfReportingPeriod":
            "Number of emission allowances stored (from previous allowances) at beginning of reporting period",
          "esrs:NumberOfEmployeesHeadcountAtEndOfPeriod":
            "Number of employees (head count), at end of period",
          "esrs:NumberOfEmployeesHeadCountAtTopManagementLevelAtEndOfPeriod":
            "Number of employees (head count) at top management level, at end of period",
          "esrs:NumberOfEmployeesHeadCountAtTopManagementLevelDuringPeriod":
            "Number of employees (head count) at top management level, during period",
          "esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldAtEndOfPeriod":
            "Number of employees (head count) between 30 and 50 years old, at end of period",
          "esrs:NumberOfEmployeesHeadCountBetween30And50YearsOldDuringPeriod":
            "Number of employees (head count) between 30 and 50 years old, during period",
          "esrs:NumberOfEmployeesHeadCountDuringPeriod":
            "Number of employees (head count), during period",
          "esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentAtEndOfPeriod":
            "Number of employees (head count or full-time equivalent), at end of period",
          "esrs:NumberOfEmployeesHeadCountOrFulltimeEquivalentDuringPeriod":
            "Number of employees (head count or full-time equivalent), during period",
          "esrs:NumberOfEmployeesHeadCountOver50YearsOldAtEndOfPeriod":
            "Number of employees (head count) over 50 years old, at end of period",
          "esrs:NumberOfEmployeesHeadCountOver50YearsOldDuringPeriod":
            "Number of employees (head count) over 50 years old, during period",
          "esrs:NumberOfEmployeesHeadCountUnder30YearsOldAtEndOfPeriod":
            "Number of employees (head count) under 30 years old, at end of period",
          "esrs:NumberOfEmployeesHeadCountUnder30YearsOldDuringPeriod":
            "Number of employees (head count) under 30 years old, during period",
          "esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesAtEndOfPeriod":
            "Number of employees in countries with 50 or more employees representing at least 10% of total number of employees, at end of period",
          "esrs:NumberOfEmployeesInCountriesWith50OrMoreEmployeesRepresentingAtLeast10PercentOfTotalNumberOfEmployeesDuringPeriod":
            "Number of employees in countries with 50 or more employees representing at least 10% of total number of employees, during period",
          "esrs:NumberOfEmployeesReceivingAnticorruptionAndAntibriberyTrainingDuringPeriod":
            "Number of employees receiving anti-corruption and anti-bribery training, during period",
          "esrs:NumberOfEmployeesWhoHaveLeftUndertakingDuringPeriod":
            "Number of employees who have left undertaking, during period",
          "esrs:NumberOfExecutiveMembers": "Number of executive members",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites":
            "Number of fatalities as result of work-related ill health of other workers working on undertaking's sites",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealthOfOtherWorkersWorkingOnUndertakingsSites":
            "Number of fatalities as result of work-related injuries and work-related ill health of other workers working on undertaking's sites",
          "esrs:NumberOfFatalitiesAsResultOfWorkrelatedInjuriesOfOtherWorkersWorkingOnUndertakingsSites":
            "Number of fatalities as result of work-related injuries of other workers working on undertaking's sites",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedIllHealth":
            "Number of fatalities in own workforce as result of work-related ill health",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuries":
            "Number of fatalities in own workforce as result of work-related injuries",
          "esrs:NumberOfFatalitiesInOwnWorkforceAsResultOfWorkrelatedInjuriesAndWorkrelatedIllHealth":
            "Number of fatalities in own workforce as result of work-related injuries and work-related ill health",
          "esrs:NumberOfIncidentsOfDiscrimination": "Number of incidents of discrimination",
          "esrs:NumberOfInvasiveAlienSpecies": "Number of invasive alien species",
          "esrs:NumberOfLegalProceedingsOutstandingForLatePayments":
            "Number of legal proceedings outstanding for late payments",
          "esrs:NumberOfNonemployeesInOwnWorkforceAtEndOfPeriod":
            "Number of non-employees in own workforce, at end of period",
          "esrs:NumberOfNonemployeesInOwnWorkforceDuringPeriod":
            "Number of non-employees in own workforce, during period",
          "esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesAtEndOfPeriod":
            "Number of non-employees in own workforce - people provided by undertakings primarily engaged in employment activities, at end of period",
          "esrs:NumberOfNonemployeesInOwnWorkforcePeopleProvidedByUndertakingsPrimarilyEngagedInEmploymentActivitiesDuringPeriod":
            "Number of non-employees in own workforce - people provided by undertakings primarily engaged in employment activities, during period",
          "esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleAtEndOfPeriod":
            "Number of non-employees in own workforce - self-employed people, at end of period",
          "esrs:NumberOfNonemployeesInOwnWorkforceSelfemployedPeopleDuringPeriod":
            "Number of non-employees in own workforce - self-employed people, during period",
          "esrs:NumberOfNonexecutiveMembers": "Number of non-executive members",
          "esrs:NumberOfRecordableWorkrelatedAccidents":
            "Number of recordable work-related accidents",
          "esrs:NumberOfScope1GHGEmissionAllowancesWithinRegulatedEmissionTradingSchemes":
            "Number of Scope 1 GHG emission allowances within regulated emission trading schemes",
          "esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforce":
            "Number of severe human rights incidents connected to own workforce",
          "esrs:NumberOfSevereHumanRightsIncidentsConnectedToOwnWorkforceThatAreNonrespectOfUnGuidingPrinciplesOnBusinessAndHumanRightsIloDeclarationOnFundamentalPrinciplesAndRightsAtWorkOrOecdGuidelinesForMultinationalEnterprises":
            "Number of severe human rights incidents connected to own workforce that are non-respect of UN Guiding Principles on Business and Human Rights, ILO Declaration on Fundamental Principles and Rights at Work or OECD Guidelines for Multinational Enterprises",
          "esrs:NumberOfSevereHumanRightsIncidentsWhereUndertakingPlayedRoleSecuringRemedyForThoseAffected":
            "Number of severe human rights incidents where undertaking played role securing remedy for those affected",
          "esrs:NumberOfSitesOwnedLeasedOrManagedInOrNearProtectedAreasOrKeyBiodiversityAreasThatUndertakingIsNegativelyAffecting":
            "Number of sites owned, leased or managed in or near protected areas or key biodiversity areas that undertaking is negatively affecting",
          "esrs:OperatingExpendituresOpExInConjunctionWithMajorIncidentsAndDeposits":
            "Operating expenditures (OpEx) in conjunction with major incidents and deposits",
          "esrs:PercentageOfApproximateGrossScope1GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Percentage of approximate gross Scope 1 greenhouse gas emissions covered by internal carbon pricing scheme",
          "esrs:PercentageOfApproximateGrossScope2GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Percentage of approximate gross Scope 2 greenhouse gas emissions covered by internal carbon pricing scheme",
          "esrs:PercentageOfApproximateGrossScope3GreenhouseGasEmissionsCoveredByInternalCarbonPricingScheme":
            "Percentage of approximate gross Scope 3 greenhouse gas emissions covered by internal carbon pricing scheme",
          "esrs:PercentageOfAssetsAtMaterialPhysicalRiskAddressedByClimateChangeAdaptationActions":
            "Percentage of assets at material physical risk addressed by climate change adaptation actions",
          "esrs:PercentageOfAssetsAtMaterialPhysicalRiskBeforeConsideringClimateChangeAdaptationActions":
            "Percentage of assets at material physical risk before considering climate change adaptation actions",
          "esrs:PercentageOfAssetsAtMaterialTransitionRiskAddressedByClimateChangeMitigationActions":
            "Percentage of assets at material transition risk addressed by climate change mitigation actions",
          "esrs:PercentageOfAssetsAtMaterialTransitionRiskBeforeConsideringClimateMitigationActions":
            "Percentage of assets at material transition risk before considering climate mitigation actions",
          "esrs:PercentageOfBiologicalMaterialsUsedToManufactureProductsAndServicesThatAreSustainablySourced":
            "Percentage of biological materials used to manufacture products and services that are sustainably sourced",
          "esrs:PercentageOfCarbonCreditsCancelledForRecognisedQualityStandard":
            "Percentage of carbon credits cancelled for recognised quality standard",
          "esrs:PercentageOfCarbonCreditsCancelledFromProjectsInEuropeanUnion":
            "Percentage of carbon credits cancelled from projects in European Union",
          "esrs:PercentageOfCarbonCreditsCancelledFromReductionProjects":
            "Percentage of carbon credits cancelled from reduction projects",
          "esrs:PercentageOfCarbonCreditsCancelledFromRemovalProjects":
            "Percentage of carbon credits cancelled from removal projects",
          "esrs:PercentageOfCarbonCreditsCancelledThatQualifiesAsCorrespondingAdjustment":
            "Percentage of carbon credits cancelled that qualifies as corresponding adjustment",
          "esrs:PercentageOfContractualInstrumentsScope2GHGEmissions":
            "Percentage of contractual instruments, Scope 2 GHG emissions",
          "esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfEnergyBundledWithAttributesAboutEnergyGenerationInRelationToScope2GHGEmissions":
            "Percentage of contractual instruments used for sale and purchase of energy bundled with attributes about energy generation in relation to Scope 2 GHG emissions",
          "esrs:PercentageOfContractualInstrumentsUsedForSaleAndPurchaseOfUnbundledEnergyAttributeClaimsInRelationToScope2GHGEmissions":
            "Percentage of contractual instruments used for sale and purchase of unbundled energy attribute claims in relation to Scope 2 GHG emissions",
          "esrs:PercentageOfDataObtainedFromBestEstimatesWaterConsumption":
            "Percentage of data obtained from best estimates (water consumption)",
          "esrs:PercentageOfDataObtainedFromDirectMeasurementWaterConsumption":
            "Percentage of data obtained from direct measurement (water consumption)",
          "esrs:PercentageOfDataObtainedFromSamplingAndExtrapolationWaterConsumption":
            "Percentage of data obtained from sampling and extrapolation (water consumption)",
          "esrs:PercentageOfEmployeesAndOrNonemployeesPaidBelowApplicableAdequateWageBenchmark":
            "Percentage of employees and (or) non-employees paid below applicable adequate wage benchmark",
          "esrs:PercentageOfEmployeesAndOrNonemployeesThatParticipatedInRegularPerformanceAndCareerDevelopmentReviews":
            "Percentage of employees and (or) non-employees that participated in regular performance and career development reviews",
          "esrs:PercentageOfEmployeesAtTopManagementLevel":
            "Percentage of employees at top management level",
          "esrs:PercentageOfEmployeesBetween30And50YearsOld":
            "Percentage of employees between 30 and 50 years old",
          "esrs:PercentageOfEmployeesEntitledToTakeFamilyrelatedLeaves":
            "Percentage of employees entitled to take family-related leaves",
          "esrs:PercentageOfEmployeesOver50YearsOld": "Percentage of employees over 50 years old",
          "esrs:PercentageOfEmployeesUnder30YearsOld": "Percentage of employees under 30 years old",
          "esrs:PercentageOfEmployeeTurnover": "Percentage of employee turnover",
          "esrs:PercentageOfEnergyConsumptionFromNuclearSourcesInTotalEnergyConsumption":
            "Percentage of energy consumption from nuclear sources in total energy consumption",
          "esrs:PercentageOfEntitledEmployeesThatTookFamilyrelatedLeave":
            "Percentage of entitled employees that took family-related leave",
          "esrs:PercentageOfEstimatedShareOfPotentiallyStrandedAssetsOfTotalAssetsAtMaterialTransitionRisk":
            "Percentage of estimated share of potentially stranded assets of total assets at material transition risk",
          "esrs:PercentageOfFossilSourcesInTotalEnergyConsumption":
            "Percentage of fossil sources in total energy consumption",
          "esrs:PercentageOfFunctionsatriskCoveredByAnticorruptionAndAntibriberyTrainingProgrammes":
            "Percentage of functions-at-risk covered by anti-corruption and anti-bribery training programmes",
          "esrs:PercentageOfGrossScope3GreenhouseGasEmissionsCalculatedUsingPrimaryDataObtainedFromSuppliersOrOtherValueChainPartners":
            "Percentage of Gross Scope 3 greenhouse gas emissions calculated using primary data obtained from suppliers or other value chain partners",
          "esrs:PercentageOfIndependentBoardMembers": "Percentage of independent board members",
          "esrs:PercentageOfLocationBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Percentage of location-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)",
          "esrs:PercentageOfMarketbasedScope2GHGEmissionsLinkedToPurchasedElectricityBundledWithInstruments":
            "Percentage of market-based Scope 2 GHG emissions linked to purchased electricity bundled with instruments",
          "esrs:PercentageOfMarketBasedScope2GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Percentage of market-based Scope 2 Greenhouse gas emissions reduction (as of emissions of base year)",
          "esrs:PercentageOfMembersOfAdministrativeManagementAndSupervisoryBodies":
            "Percentage of members of administrative, management and supervisory bodies",
          "esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialPhysicalRisk":
            "Percentage of net revenue from business activities at material physical risk",
          "esrs:PercentageOfNetRevenueFromBusinessActivitiesAtMaterialTransitionRisk":
            "Percentage of net revenue from business activities at material transition risk",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInCoalrelatedActivities":
            "Percentage of net revenue from customers operating in coal-related activities",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInGasrelatedActivities":
            "Percentage of net revenue from customers operating in gas-related activities",
          "esrs:PercentageOfNetRevenueFromCustomersOperatingInOilrelatedActivities":
            "Percentage of net revenue from customers operating in oil-related activities",
          "esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfConcern":
            "Percentage of net revenue made with products and services that are or that contain substances of concern",
          "esrs:PercentageOfNetRevenueMadeWithProductsAndServicesThatAreOrThatContainSubstancesOfVeryHighConcern":
            "Percentage of net revenue made with products and services that are or that contain substances of very high concern",
          "esrs:PercentageOfNonemployeesInOwnWorkforceWhoseWorkingConditionsAndTermsOfEmploymentAreDeterminedOrInfluencedByCollectiveBargainingAgreements":
            "Percentage of non-employees in own workforce whose working conditions and terms of employment are determined or influenced by collective bargaining agreements",
          "esrs:PercentageOfNonrecycledWaste": "Percentage of non-recycled waste",
          "esrs:PercentageOfOwnWorkersCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelinesAndWhichHasBeenInternallyAuditedAndOrAuditedOrCertifiedByExternalParty":
            "Percentage of own workers covered by health and safety management system based on legal requirements and (or) recognised standards or guidelines and which has been internally audited and (or) audited or certified by external party",
          "esrs:PercentageOfPaymentsAlignedWithStandardPaymentTerms":
            "Percentage of payments aligned with standard payment terms",
          "esrs:PercentageOfPeopleInOwnWorkforceWhoAreCoveredByHealthAndSafetyManagementSystemBasedOnLegalRequirementsAndOrRecognisedStandardsOrGuidelines":
            "Percentage of people in own workforce who are covered by health and safety management system based on legal requirements and (or) recognised standards or guidelines",
          "esrs:PercentageOfPersonsWithDisabilitiesAmongstEmployees":
            "Percentage of persons with disabilities amongst employees",
          "esrs:PercentageOfRemunerationRecognisedInCurrentPeriodThatIsLinkedToClimateRelatedConsiderations":
            "Percentage of remuneration recognised in current period that is linked to climate related considerations",
          "esrs:PercentageOfRenewableSourcesInTotalEnergyConsumption":
            "Percentage of renewable sources in total energy consumption",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsFromRegulatedEmissionTradingSchemes":
            "Percentage of Scope 1 greenhouse gas emissions from regulated emission trading schemes",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Percentage of Scope 1 Greenhouse gas emissions reduction (as of emissions of base year)",
          "esrs:PercentageOfScope1GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Percentage of Scope 1 Greenhouse gas emissions reduction in total Greenhouse gas emissions reduction",
          "esrs:PercentageOfScope2LocationbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Percentage of Scope 2 location-based Greenhouse gas emissions reduction in total Greenhouse gas emissions reduction",
          "esrs:PercentageOfScope2MarketbasedGreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Percentage of Scope 2 market-based Greenhouse gas emissions reduction in total Greenhouse gas emissions reduction",
          "esrs:PercentageOfScope3GreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Percentage of Scope 3 Greenhouse gas emissions reduction (as of emissions of base year)",
          "esrs:PercentageOfScope3GreenhouseGasEmissionsReductionInTotalGreenhouseGasEmissionsReduction":
            "Percentage of Scope 3 Greenhouse gas emissions reduction in total Greenhouse gas emissions reduction",
          "esrs:PercentageOfSecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices":
            "Percentage of secondary reused or recycled components, secondary intermediary products and secondary materials used to manufacture products and services",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissions":
            "Percentage of target related to location-based Scope 2 Greenhouse gas emissions",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Percentage of target related to location-based Scope 2 Greenhouse gas emissions (as of emissions of base year)",
          "esrs:PercentageOfTargetRelatedToLocationBasedScope2GreenhouseGasEmissionsIntensityValue":
            "Percentage of target related to location-based Scope 2 Greenhouse gas emissions (intensity value)",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissions":
            "Percentage of target related to market-based Scope 2 Greenhouse gas emissions",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Percentage of target related to market-based Scope 2 Greenhouse gas emissions (as of emissions of base year)",
          "esrs:PercentageOfTargetRelatedToMarketBasedScope2GreenhouseGasEmissionsIntensityValue":
            "Percentage of target related to market-based Scope 2 Greenhouse gas emissions (intensity value)",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissions":
            "Percentage of target related to Scope 1 Greenhouse gas emissions",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Percentage of target related to Scope 1 Greenhouse gas emissions (as of emissions of base year)",
          "esrs:PercentageOfTargetRelatedToScope1GreenhouseGasEmissionsIntensityValue":
            "Percentage of target related to Scope 1 Greenhouse gas emissions (intensity value)",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissions":
            "Percentage of target related to Scope 3 Greenhouse gas emissions",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Percentage of target related to Scope 3 Greenhouse gas emissions (as of emissions of base year)",
          "esrs:PercentageOfTargetRelatedToScope3GreenhouseGasEmissionsIntensityValue":
            "Percentage of target related to Scope 3 Greenhouse gas emissions (intensity value)",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissions":
            "Percentage of target related to total Greenhouse gas emissions",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsAsOfEmissionsOfBaseYear":
            "Percentage of target related to total Greenhouse gas emissions (as of emissions of base year)",
          "esrs:PercentageOfTargetRelatedToTotalGreenhouseGasEmissionsIntensityValue":
            "Percentage of target related to total Greenhouse gas emissions (intensity value)",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasAtWaterRisk":
            "Percentage of total emissions of pollutants to soil occurring in areas at water risk",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToSoilOccurringInAreasOfHighwaterStress":
            "Percentage of total emissions of pollutants to soil occurring in areas of high-water stress",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasAtWaterRisk":
            "Percentage of total emissions of pollutants to water occurring in areas at water risk",
          "esrs:PercentageOfTotalEmissionsOfPollutantsToWaterOccurringInAreasOfHighwaterStress":
            "Percentage of total emissions of pollutants to water occurring in areas of high-water stress",
          "esrs:PercentageOfTotalEmployeesCoveredByCollectiveBargainingAgreements":
            "Percentage of total employees covered by collective bargaining agreements",
          "esrs:PercentageOfTotalGreenhouseGasEmissionsReductionAsOfEmissionsOfBaseYear":
            "Percentage of total Greenhouse gas emissions reduction (as of emissions of base year)",
          "esrs:PercentageOfVariableRemunerationDependentOnSustainabilityrelatedTargetsAndOrImpacts":
            "Percentage of variable remuneration dependent on sustainability-related targets and (or) impacts",
          "esrs:PotentialFutureLiabilitiesBasedOnExistingContractualAgreementsAssociatedWithCarbonCreditsPlannedToBeCancelledInNearFuture":
            "Potential future liabilities, based on existing contractual agreements, associated with carbon credits planned to be cancelled in near future",
          "esrs:PotentialMarketSizeOfLowcarbonProductsAndServicesOrAdaptationSolutionsToWhichUndertakingHasOrMayHaveAccess":
            "Potential market size of low-carbon products and services or adaptation solutions to which undertaking has or may have access",
          "esrs:ProductsAndTechnicalAndBiologicalMaterialsUsed":
            "Products and technical and biological materials used",
          "esrs:ProvisionsForEnvironmentalProtectionAndRemediationCosts":
            "Provisions for environmental protection and remediation costs",
          "esrs:RadioactiveWaste": "Radioactive waste",
          "esrs:RateOfRecordableWorkrelatedAccidents": "Rate of recordable work-related accidents",
          "esrs:RemunerationRatioAdjustedForPurchasingPowerDifferencesBetweenCountries":
            "Remuneration ratio adjusted for purchasing power differences between countries",
          "esrs:RenewableEnergyProduction": "Renewable energy production",
          "esrs:Revenue": "Revenue",
          "esrs:RevenueFromActivitiesInHighClimateImpactSectors":
            "Revenue from activities in high climate impact sectors",
          "esrs:RevenueFromActivitiesOtherThanInHighClimateImpactSectors":
            "Revenue from activities other than in high climate impact sectors",
          "esrs:RevenueFromBusinessActivitiesAtMaterialPhysicalRisk":
            "Revenue from business activities at material physical risk",
          "esrs:RevenueFromBusinessActivitiesAtMaterialTransitionRisk":
            "Revenue from business activities at material transition risk",
          "esrs:RevenueFromChemicalsProduction": "Revenue from chemicals production",
          "esrs:RevenueFromCoal": "Revenue from coal",
          "esrs:RevenueFromControversialWeapons": "Revenue from controversial weapons",
          "esrs:RevenueFromCultivationAndOrProductionOfTobacco":
            "Revenue from cultivation and (or) production of tobacco",
          "esrs:RevenueFromCustomersOperatingInCoalRelatedActivities":
            "Revenue from customers operating in coal-related activities",
          "esrs:RevenueFromCustomersOperatingInGasRelatedActivities":
            "Revenue from customers operating in gas-related activities",
          "esrs:RevenueFromCustomersOperatingInOilRelatedActivities":
            "Revenue from customers operating in oil-related activities",
          "esrs:RevenueFromFossilFuelCoalOilAndGasSector":
            "Revenue from fossil fuel (coal, oil and gas) sector",
          "esrs:RevenueFromGas": "Revenue from gas",
          "esrs:RevenueFromOil": "Revenue from oil",
          "esrs:RevenueFromTaxonomyalignedEconomicActivitiesRelatedToFossilGas":
            "Revenue from Taxonomy-aligned economic activities related to fossil gas",
          "esrs:Reversals": "Reversals",
          "esrs:SealedArea": "Sealed area",
          "esrs:SecondaryReusedOrRecycledComponentsSecondaryIntermediaryProductsAndSecondaryMaterialsUsedToManufactureProductsAndServices":
            "Secondary reused or recycled components, secondary intermediary products and secondary materials used to manufacture products and services",
          "esrs:SignificantCapExForCoalrelatedEconomicActivities":
            "Significant CapEx for coal-related economic activities",
          "esrs:SignificantCapExForGasrelatedEconomicActivities":
            "Significant CapEx for gas-related economic activities",
          "esrs:SignificantCapExForOilrelatedEconomicActivities":
            "Significant CapEx for oil-related economic activities",
          "esrs:UseOfLandArea": "Use of land area",
          "esrs:WasteGenerated": "Waste generated",
          "esrs:WasteGeneratedDirectedToDisposal": "Waste generated directed to disposal",
          "esrs:WasteGeneratedDivertedFromDisposal": "Waste generated diverted from disposal",
          "esrs:WaterConsumption": "Water consumption",
          "esrs:WaterConsumptionInAreasAtWaterRisk": "Water consumption in areas at water risk",
          "esrs:WaterConsumptionInAreasOfHighwaterStress":
            "Water consumption in areas of high-water stress",
          "esrs:WaterDischarges": "Water discharges",
          "esrs:WaterIntensityTotalWaterConsumptionPerNetRevenue":
            "Water intensity (total water consumption per net revenue)",
          "esrs:WaterRecycledAndReused": "Water recycled and reused",
          "esrs:WaterStored": "Water stored",
          "esrs:WaterWithdrawals": "Water withdrawals",
        },
      },
      e: {
        e1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e1,
          greenhouseGasesCoveredByEmissionReductionTarget: {
            "esrs:PollutantCarbonDioxideCO2Member": "Carbon dioxide (CO2)",
            "esrs:PollutantMethaneCH4Member": "Methane (CH4)",
            "esrs:PollutantNitrousOxideN2OMember": "Nitrous oxide (N2O)",
            "esrs:HydrofluorocarbonsHFCsMember": "Hydro-fluorocarbons (HFCs)",
            "esrs:PerfluorocarbonsPFCsMember": "Perfluorocarbons (PFCs)",
            "esrs:PollutantSulphurHexafluorideSF6Member": "Sulphur hexafluoride (SF6)",
            "esrs:NitrogenTrifluorideNF3Member": "Nitrogen trifluoride (NF3)",
            "esrs:OtherGHGCategoryMember": "Other GHG category",
          },
        },
        e2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e2,
        },
        e3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e3,
        },
        e4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e4,
          layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems: {
            "esrs:AvoidanceMember": "Avoidance",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember":
              "Avoidance through Site Selection",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember":
              "Avoidance through Project Design",
            "esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember":
              "Avoidance through Scheduling",
            "esrs:MinimisationMember": "Minimisation",
            "esrs:RestorationAndRehabilitationMember": "Restoration and rehabilitation",
            "esrs:CompensationOrOffsetsMember": "Compensation or offsets",
          },
        },
        e5: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.e5,
          layersInWasteHierarchyToWhichTargetRelates: {
            "esrs:PreventionMember": "Prevention",
            "esrs:PreparationForReuseMember": "Preparation for reuse",
            "esrs:RecyclingMember": "Recycling",
            "esrs:OtherRecoveryMember": "Other recovery",
            "esrs:DisposalMember": "Disposal",
          },
        },
      },
      s: {
        targetRelationshipToImpactsRisksAndOpportunities: {
          "esrs:ReducingNegativeImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Reducing negative impacts on own workforce / value chain workers / affected communities / consumers and end-users",
          "esrs:AdvancingPositiveImpactsOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Advancing positive impacts on own workforce / value chain workers / affected communities / consumers and end-users",
          "esrs:ManagingMaterialRisksAndOpportunitiesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersMember":
            "Managing material risks and opportunities related to own workforce / value chain workers / affected communities / consumers and end-users",
        },
        s1: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s1,
        },
        s2: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s2,
        },
        s3: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s3,
        },
        s4: {
          addressedMatters: ADDRESSED_MATTERS_ENUM.s4,
        },
      },
    },
    input_errors: {
      // Field specific
      general: {
        name: {
          required: "Please provide a name!",
        },
      },
      // Non field specific
      start_year_after_end_year: "The start year must be greater than or equal to the end year!",
    },
  },
  data_entry_object_value_edit_component: {
    tooltips: {
      add_note: "Add Note",
      remove_note: "Remove Note",
      add_date: "Capture Date",
      use_distribution_criterion: "Use Distribution Criteria",
      undo_distribution_criterion: "Do Not Use Distribution Criteria",
      data_quality: {
        none: "No specification",
        [IDEOIPValueDataQuality.Estimated]: "Estimated",
        [IDEOIPValueDataQuality.Measured]: "Exact Value/Measured",
        [IDEOIPValueDataQuality.Calculated]: "Calculated",
      },
    },
  },
  data_entry_object_value_actions_component: {
    tooltips: {
      cancel: "Cancel",
      reset: "Discard Changes",
      edit: "Save",
      create: "Save New Entry",
      delete: "Delete",
    },
  },
  data_entry_object_values_edit: {
    remove_date_tooltip: "Remove Date",
    date_error_invalid: "Invalid Date",
    date_error_before_start: "Date cannot be earlier than {{minDate}}!",
    date_error_after_end: "Date cannot be later than {{maxDate}}!",
    date_error_end_before_start: "End date cannot be before start date!",
    value_use_distribution_criterion: "Use Distribution Criteria",
    value_undo_distribution_criterion: "Do Not Use Distribution Criteria",
    no_dc_selected: "No Selection",
    label_dc: "Distribution Criteria",
    criteria_hint_component: {
      labels: {
        value_in_percent: "Specified Value (%):",
        calculated_value: "Calculated Value:",
      },
      hints: {
        general:
          'This value will be distributed to the substructure based on the "{{dcName}}" criteria.',
        value_in_percent:
          'The value specified for <bold>"{{deoName}}"</bold> is <bold>{{keyValue}}</bold> for this criteria, which is <bold>{{valueInPercent}}%</bold> of the total amount of <bold>{{sum}}</bold>.',
      },
    },
  },
  data_entry_object_value_esrs_phase_in_input: {
    use_phase_in_toggle_label:
      "Activate phase-in regulation for companies with up to 750 employees",
    information_box_content:
      "The application of phase-in replaces the disclosure requirements of the selected ESRS topic with a minimal set of new mandatory information.",
    notice_only_root_data_entry_object: "Phase-in is only available in the root data entry object",
    notice_phase_in_in_use: "Phase-in is is in use",
  },
  data_entry_object_parent_values_component: {
    title: "Values from Parent Data Entry Objects:",
    title_own_values: "Own Values:",
  },
  data_entry_object_parent_value_component: {
    from: "From",
  },
  error_dialog: {
    dialog_title: "Error",
    button_close: "Close",
  },
  data_entry_object_edit_dialog: {
    dialog_title: "Edit Data Entry Object {{dataEntryObjectName}}",
    dialog_title_new: "Create New Data Entry Object",
    name_required: "Please enter a name.",
    name_input_label: "Name",
    financially_consolidated_label: "Fully financially consolidated?",
    operational_control_label: "Operational Control?",
    part_of_value_chain_label: "Part of the Value Chain?",
    description_input_label: "Description (optional)",
    button_delete_tooltip: "Remove Data Entry Object",
    button_change_parent: "Move to Another Data Entry Object",
    button_cancel: "Cancel",
    button_save: "Save",
    button_create: "Create",
    object_type_select_label: "Type",
    country_select_label: "Country",
    share_held_by_parent_label: "Share held of Data Entry Object",
    object_type_value_location: "Location",
    object_type_value_department: "Department",
    object_type_value_team: "Team",
    object_type_value_business_area: "Business Area",
    object_type_value_grouping: "Grouping",
    object_type_value_organization: "Organization",
  },
  configuration_dashboard_page: {
    title: "Configuration",
    title_recording_periods: "Recording Periods",
    description_recording_periods: "Create and manage recording periods.",
    button_recording_periods: "Recording Periods",
    title_stakeholder: "Stakeholder",
    description_stakeholder:
      "Create and manage stakeholders. External stakeholders can take actions in the platform via a unique share-link.",
    button_stakeholder: "Stakeholder",
    title_distribution_criteria: "Distribution Criteria",
    description_distribution_criteria: "Create and manage distribution criteria.",
    button_distribution_criteria: "Distribution Criteria",
    title_sources: "Sources",
    button_sources: "Sources",
    description_sources: "Create and manage sources.",
    title_users: "Users",
    button_users: "Users",
    description_users: "Manage users.",
    title_emission_factors: "Emission Factors",
    button_emission_factors: "Emission Factors",
    description_emission_factors: "Manage emission factors.",
  },
  recording_periods_edit_list_component: {
    title: "Recording Periods",
    button_new_recording_period: "Create",
    recording_periods_empty: "No recording periods available.",
    button_edit_organization_structure_tooltip: "Edit organization structure",
    button_edit_distribution_criteria_tooltip: "Edit distribution criteria",
    button_edit_recording_period_tooltip: "Edit recording period",
  },
  organization_configuration_dashboard_page: {
    title: "Configure Organization",
    title_organization_structure: "Organization Structure",
    description_organization_structure: "Manage the organization's structure.",
    button_edit_organization_structure: "Edit Organization Structure",
  },
  organization_structure_configuration_page: {
    title: "Organization Structure",
    button_dc: "Distribution Criteria",
    button_dc_tooltip: "Configure Distribution Criteria",
  },
  organization_structure_configuration_component: {
    recording_period_select_label: "Select Recording Period",
    organization_structure_commit_draft_button_tooltip: "Publish Draft",
    organization_structure_is_draft_notice:
      "The organization structure is currently a draft. You must publish the organization structure " +
      "before you can enter values.",
  },
  organization_structure_tree_editor_component: {
    delete_confirm_dialog_title: "Remove Data Entry Object",
    delete_confirm_dialog_content:
      "Are you sure you want to remove the data entry object {{dataEntryObjectName}} and all its subordinate data entry objects?",
    add_child_tooltip: "Add New Data Entry Object",
    add_child_mode_text: "Select the parent data entry object",
    add_child_cancel_tooltip: "Cancel",
    error_cannot_set_deo_as_its_own_parent: "Data entry object cannot be its own parent!",
    error_selected_deo_is_already_parent_of_deo_to_be_moved:
      "The selected data entry object is already the current parent data entry object!",
    error_selected_new_parent_cannot_be_child_of_deo_to_be_moved:
      "The data entry object cannot be moved into one of its subordinate data entry objects!",
    change_parent_mode_text: "Select New Parent Data Entry Object",
    change_parent_cancel_tooltip: "Cancel",
    deo_count_text: "{{currentCount}} / {{maxCount}} Data Entry Objects",
    deo_limit_reached: "Maximum number of data entry objects reached.",
  },
  organization_structure_tree_component: {
    node_type_location: "Location",
    node_type_department: "Department",
    node_type_team: "Team",
    node_type_business_area: "Business Area",
    node_type_grouping: "Grouping",
    node_type_company: "Organization/Company",
    node_type_organization: "Organization",
  },
  organization_structure_draft_commit_dialog: {
    title: "Publish Organizational Structure",
    warning_text:
      "Once published, the organizational structure cannot be changed or reverted. " +
      "All changes made up to this point will be locked permanently.",
    understand: {
      label: "I understand that the organizational structure can no longer be changed.",
      errors: {
        required: "Please confirm that you have understood the message.",
      },
    },
  },
  recording_period_edit_dialog: {
    title_create: "Create New Recording Period",
    title_edit: "Edit Recording Period {{name}}",
    default_name: "Fiscal Year {{year}}",
    name_label: "Name",
    name_required: "Please enter a name.",
    description_label: "Description (optional)",
    start_date_label: "Start Date",
    start_date_required: "Please enter a start date.",
    start_date_invalid: "Please enter a valid start date.",
    start_date_min: "Start date must be after {{minDate}} (previous period).",
    start_date_max: "Start date must be before end date.",
    end_date_required: "Please enter an end date.",
    end_date_invalid: "Please enter a valid end date.",
    end_date_min: "End date must be after start date.",
    end_date_max: "End date must be before {{maxDate}} (next period).",
    end_date_label: "End Date",
    button_create: "Create",
    button_save: "Save",
    button_cancel: "Cancel",
  },
  // Evaluations
  emissions_evaluation_page: {
    title: "Evaluate Emissions",
    notice: {
      text: "The emissions evaluation is currently under refactoring and is therefore not available.",
      button: "Back to Start",
    },
  },
  emissions_overview: {
    title_organization_unit: "Data Entry Object:",
    notice_unit:
      "All values in the overview are given in the unit <bold>t CO<sub>2</sub>eq</bold>.",
    title_emission_group: "Emission Group:",
    title_total_emissions: "Total Emissions:",
    title_total_emissions_by_recording_group: "By Emission Groups:",
    title_total_emissions_by_child: "By Data Entry Object:",
    title_child_data_entry_objects: "Data Entry Objects:",
    title_scopes: "Scope Distribution:",
  },
  emissions_direct_and_upstream_component: {
    title_direct_emissions: "Direct Emissions",
    title_upstream_emissions: "Upstream Emissions",
  },
  total_emissions_pie_chart_component: {
    empty: "No emissions",
    total_emissions: "Total:",
  },
  total_emissions_bar_percentage_component: {
    empty: "No emissions",
  },
  emissions_by_scope_bar_charts: {
    empty: "No emissions",
    button_filter: "Filter",
    title_scope1: "Scope 1",
    title_scope2: "Scope 2",
    title_scope3: "Scope 3",
    title_total: "Total",
    no_deo_with_emissions_notice:
      "No emissions have been recorded for the selected data entry object and emission group.",
    deo_result_no_emissions: "(no emissions)",
  },
  deo_emissions_by_category_bar_chart: {
    empty: "No emissions",
    button_filter: "Filter",
    no_deo_with_emissions_notice:
      "No emissions have been recorded for the selected data entry object and emission group.",
    deo_result_no_emissions: "(no emissions)",
  },
  emissions_evaluation_error_component: {
    title: "An error has occurred during the evaluation!",
    subtitle_error_message: "Error message:",
    errorMessages: {
      "formula_specific.reference.context_key_not_found":
        "The requested key was not found in the context!",
    } as { [key in CalculationErrorCodes]: string },
    affected: {
      subtitle: "Formula & Input Parameters",
      inputParameter: "Input Parameter: {{inputParameterName}} (ID: {{id}})",
      calculationFormula: "Formula: {{calculationFormulaName}} (ID: {{id}})",
      value: "Value: {{id}}",
    },
    step: {
      subtitle: "Affected Step:",
      subtitle_value_key: "Context Key:",
      subtitle_index: "({{count}}. step within the formula)",
      subtitle_formula: "Calculation formula:",
    },
    context: {
      subtitle: "Context:",
      headers: {
        key: "Context Key:",
        value: "Value",
      },
    },
  },
  // Double Materiality Assessment
  dma_common: {
    title: "Double Materiality Assessment",
    // Materiality State
    materiality_chip_tooltip: "Materiality Level / Materiality",
    materiality_true_state: "Yes",
    materiality_false_state: "No",
    // IRO State
    iro_state_draft: "Draft",
    iro_state_shared: "Released",
    // Category State
    category_state_open: "Open",
    category_state_workInProgress: "In Progress",
    category_state_shared: "Released",
    category_state_verified: "Completed",
  },
  dma_configuration: {
    title_threshold: "Assessment",
    description_threshold:
      "This value is used to determine whether an impact, opportunity, or risk is material or not. " +
      "If the calculated materiality level is equal to or greater than this value, the impact, " +
      "opportunity, or risk is considered material.",
    label_threshold_impact: "Threshold impacts",
    label_threshold_effect: "Threshold opportunities/risks",
    error_threshold_required: "Please enter a threshold!",
    error_threshold_max: "The threshold must not be greater than {{max}}.",
    error_threshold_min: "The threshold must not be less than {{min}}.",
    title_potential_extent_steps: "Potential Magnitude",
    description_potential_extent_steps:
      "The potential magnitude of opportunities and risks is assessed in 5 steps. For these steps, " +
      "a monetary value can be assigned, which is then used to calculate the severity in Euros.",
    label_set_potential_extent_steps: "Set Custom Values",
    error_potentialExtentStep_not_ascending: "Values must be ascending.",
    error_potentialExtentStep_required: "Please enter a value.",
    // probability of occurrence material
    title_probability_of_occurrence_steps_material: "Likelihood of the impact",
    description_probability_of_occurrence_steps_material:
      "The likelihood of the impact is assessed in 5 steps. For these steps, a percentage value " +
      "can be assigned, which is then used to calculate the severity/materiality level.",
    // probability of occurrence financial
    title_probability_of_occurrence_steps_financial:
      "Likelihood of Occurrence of Financial Effects",
    description_probability_of_occurrence_steps_financial:
      "The likelihood of occurrence is assessed in 5 steps. For these steps, a percentage value " +
      "can be assigned, which is then used to calculate the severity/materiality level.",
    // probability of occurrence common
    label_set_probability_of_occurrence_steps: "Set Custom Values",
    // errors probability of occurrence
    error_probabilityOfOccurrence_step_not_ascending: "Values must be ascending.",
    error_probabilityOfOccurrence_step_required: "Please enter a value.",
    error_probabilityOfOccurrence_step_min: "The value must be greater than or equal to 0.",
    error_probabilityOfOccurrence_step_max: "The value must not be greater than 100.",
    // Export
    title_export: "Export",
    title_input_language: "Input Language",
  },
  export_button_component: {
    export_data: "Export data",
    download_text: "Please choose the data you would like to download:",
    button_content_iros: "Export Impacts, Risks and Opportunities",
    "button_content_stakeholder-feedback": "Export Stakeholder Feedback",
    button_tooltip_iros:
      "Export impacts, risks and opportunities in the currently chosen language ({{currentLanguage}})",
    "button_tooltip_stakeholder-feedback":
      "Export stakeholder feedback in the currently chosen language ({{currentLanguage}})",
    button_content_users: "Export all users",
    button_tooltip_users: "Export all users in the currently chosen language ({{currentLanguage}})",
    info: "To match the users with their human-readable names, you will need to manually match the user IDs with their corresponding names in Excel or another spreadsheet tool.",
  },
  gap_analysis: {
    title: "Gap Analysis",
    subtitle: "Overview of Disclosure Requirements",
    table: {
      header_name: "Disclosure Requirement",
      header_responsible_person: "Responsible",
      header_data_collection: "Data Collection",
      header_availability: "Availability",
      header_sources: "Sources",
      header_status: "Status",
    },
    data_collection: {
      null: "Please choose",
      [IDataEntryObjectInputParameterGapAnalysisDataCollection.Local]: "Locations/Departments",
      [IDataEntryObjectInputParameterGapAnalysisDataCollection.Central]: "Holding (central)",
    },
    availability: {
      null: "Please choose",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Not]: "Not available",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Unclear]: "Unclear",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Partial]: "Partially available",
      [IDataEntryObjectInputParameterGapAnalysisAvailability.Full]: "Fully available",
    },
    add_all_esrs_topics_button: "Add all ESRS topics",
    missing_esrs_topics: "To view the Gap Analysis, you must add all remaining ESRS topics.",
  },
  double_materiality_assessment_page: {
    no_data_points_assigned_text:
      "No data points have been assigned to you and you do not have permission to view or edit other data points.",
    no_dma_configuration_present:
      "No DMA configuration has been added yet for this recording period.",
  },
  double_materiality_assessment_stepper_component: {
    material_impacts_checkbox_label: "Impacts",
    financial_effects_checkbox_label: "Risks and Opportunities",
    filter_by_user_label: "Filter by User",
    previous_step_button: "Previous",
    next_step_button: "Next",
    complete_dma_button: "Finalize",
    tooltip_icon_dma_completed: "Already finalized",
    can_not_complete_dma:
      "The DMA cannot be completed at the moment because at least one topic is incomplete. " +
      "Double checkmarks will be displayed next to all complete categories.",
    label_dma_dashboard: "Dashboard",
    button_filter: "Filter",
    no_filterable_users_available: "No responsible or assigned persons added yet.",
    selected_category_empty:
      "The selected main topic does not contain subcategories for which impacts can be recorded.",
    dma_incomplete_notice_title: "Double Materiality Incomplete!",
    dma_incomplete_notice_content:
      "The double materiality assessment can only be completed when you have provided at least one impact and one " +
      "risk or opportunity for each topic that does not have subcategories or confirmed that you do not want to " +
      "provide any impacts or risks and opportunities for it.\n\nPlease complete the double materiality assessment before proceeding!",
    dma_incomplete_notice_button_accept: "Complete",
    dma_open_topic:
      "This topic is still open because no impacts or risks/opportunities have been recorded and the topic has not been marked as not relevant.",
    dma_shared_topic:
      "This topic lacks validation by stakeholders and the subsequent completion of all subtopics.",
    dma_verified_topic: "This topic is completed.",
    title_dma_configuration: "Configuration",
    label_step_dma_configuration: "Configuration",
    button_create_category: "Add Topic",
    // Complete DMA Success message
    toast_complete_dma_success_title: "DMA successfully completed!",
    toast_complete_dma_success_text: "Start your data collection in ESRS2 now.",
  },
  execute_dma_confirm_dialog: {
    title: "Finalize Double Materiality Assessment",
    content_text:
      "Would you like to finalize your materiality assessment? After confirmation, we will guide you to the ESRS data collection. Based on your results, non-material data points will automatically be marked for you. Further changes will be limited afterward.",
    notice_long_duration: "This process may take a few minutes.",
    button_confirm: "Finalize",
  },
  double_materiality_assessment_sub_category_component: {
    dma_category_actions_component: {
      tooltip_edit: "Edit Topic",
      tooltip_add_summary: "Add Summary",
      tooltip_add_summary_disabled:
        "Summary can only be added as long as the topic is not completed.",
      tooltip_add_child: "Add Sub-Topic",
      tooltip_add_child_disabled:
        "Sub-Topics can only be added as long as the topic is not completed.",
      button_category_irrelevant: "Not Relevant",
      tooltip_stakeholder_feedback: "Stakeholder Feedback",
    },
    material_impacts_header: "Impacts",
    material_impacts_empty_message: "No impacts yet",
    category_opted_out_notice: "This topic has been marked as not relevant.",
    label_category_opted_out_reason: "Reason:",
    button_edit_opt_out_reason_tooltip: "Edit Reason",
    button_opt_back_into_category: "Reset",
    create_material_impact_tooltip: "Add Impact",
    financial_effects_header: "Risks and Opportunities",
    financial_effects_empty_message: "No risks or opportunities yet",
    create_financial_effect_tooltip: "Add Risk or Opportunity",
    "downstream-value-chain_name": "Downstream Value Chain",
    "own-organization_name": "Own Organization",
    "upstream-value-chain_name": "Upstream Value Chain",
    show_actions: "Show actions",
    hide_actions: "Hide actions",
    label_summary: "Summary",
    tooltip_edit_summary_disabled:
      "Summary can only be edited as long as the topic is not completed.",
  },
  dma_financial_effect_general: {
    effect_type: {
      investment_costs: "Investment Costs",
      operations_costs: "Operating Costs",
      capital_costs: "Capital Costs",
      assets_value_change: "Change in Asset Value",
      revenue: "Revenue",
      access_to_capital: "Access to Capital",
    },
  },
  multiple_chip_component: {
    only_more_items: "{{count}} selected",
    more_items: "+{{count}} more",
  },
  associated_users_dialog_inputs: {
    // Responsible
    label_responsible_user: "Responsible Person",
    // Assigned Users
    label_assigned_users: "Contributors",
    no_assigned_users: "No contributors assigned",
    add_assigned_user_tooltip: "Assign contributors",
    no_more_users_to_assign: "No more users available",
  },
  dma_impact_or_effect_edit_dialog_common: {
    // Inputs
    label_title: "Title",
    error_title_required: "Please enter a title.",
    label_description: "Description and Background (optional)",
    description_explanation:
      "Explanations of the situation, description of underlying data, assumptions, and " +
      "methods for assessment/calculation, as well as listing of involved areas.",
    label_assessmentExplanation: "Assessment Explanation",
    assessmentExplanation_explanation: "Explanation of the assessment and underlying assumptions.",
    error_assessmentExplanation_required: "Please provide an explanation.",
    // Evaluation Section
    header_horizon_evaluation: "Result of Assessment",
    header_total_evaluation: "Total Assessment Across All Three Time Horizons",
    severity_label: "Severity",
    severity_currency_label: "Severity in Euros",
    no_equivalent_set_1: "No individual values set in",
    no_equivalent_set_2: "configuration",
    no_equivalent_set_3: "",
    materiality_label: "Material",
    materiality_degree_label: "Materiality Level",
    materiality_true_state: "Yes",
    materiality_false_state: "No",
    edit_materiality_tooltip: "Manually override calculated materiality",
    calculated_materiality_label: "Calculated Materiality",
    custom_materiality_label: "Custom Materiality",
    cancel_edit_materiality_tooltip: "Cancel and use calculated materiality",
    label_user_materiality_reason: "Reason for Custom Materiality",
    error_user_materiality_reason_required: "Please provide a reason.",
    error_reason_for_horizons_was_not_provided:
      "Since the assessment differs between the individual time horizons " +
      "(short-, medium-, and long-term), an explanation must be provided.",
    inherit_form_horizon_select_label: "Time Horizon Assessment",
    inherit_from_shortTerm: "Inherit values from Short-Term",
    inherit_from_mediumTerm: "Inherit values from Medium-Term",
    inherit_from_longTerm: "Inherit values from Long-Term",
    // inherits_values_itself: "Time horizon adopts values itself",
    horizon_shortTerm_name: "Short-Term",
    horizon_shortTerm_time_span: "1 Year",
    horizon_mediumTerm_name: "Medium-Term",
    horizon_mediumTerm_time_span: "2 - 5 Years",
    horizon_longTerm_name: "Long-Term",
    horizon_longTerm_time_span: "> 5 Years",
    no_inheritance_name: "Manual",
    in_use_by_other_horizon: "Other time horizons inherit values from this time horizon.",
    horizons_comment_label: "Explanation for the different assessment of the time horizons",
    horizons_comment_explanation:
      "Explain why there are differences in the assessment of the individual time horizons " +
      "(short-, medium-, and long-term) and briefly explain the deviations.",
    // Areas
    areas_select_label: "Affected Areas",
    error_effect_area_required: "Please select at least one area.",
    "downstream-value-chain_name": "Downstream Value Chain",
    "own-organization_name": "Own Organization",
    "upstream-value-chain_name": "Upstream Value Chain",
    // Slider Values
    slider_value_very_low: "Very Low",
    slider_value_low: "Rather Low",
    slider_value_average: "Medium",
    slider_value_high: "Rather High",
    slider_value_very_high: "Very High",
    // Slider Values Extent
    slider_value_extent_very_low: "Minimal",
    slider_value_extent_low: "Low",
    slider_value_extent_average: "Medium",
    slider_value_extent_high: "High",
    slider_value_extent_very_high: "Very High/Absolute",
    // Slider Values Scale
    slider_value_scale_very_low: "Limited",
    slider_value_scale_low: "Concentrated",
    slider_value_scale_average: "Medium",
    slider_value_scale_high: "Widespread",
    slider_value_scale_very_high: "Global/Total",
    // Slider Values Irreversibility
    slider_value_irreversibility_very_low: "Relatively Easy/Short-Term Reversible",
    slider_value_irreversibility_low: "Reversible with Effort",
    slider_value_irreversibility_average: "Difficult/Mid-Term Reversible",
    slider_value_irreversibility_high: "Very Difficult/Long-Term Reversible",
    slider_value_irreversibility_very_high: "Not Reversible",
    // Assigned Users
    explanation_assigned_users:
      "Contributors have access to this IRO and can enter data as well as modify the evaluation as long as the data has not been released.",
    confirm_dialog: {
      button_confirm: "$t(buttons:confirm)",
      button_cancel: "$t(buttons:cancel)",
    },
  },
  dma_material_impact_edit_dialog: {
    title_create: "Create Impact",
    title_edit: 'Edit <italic>"{{title}}"</italic>',
    delete_tooltip: "Remove Impact",
    move_to_other_topic: "Move Impact to other Topic",
    // Impact Inputs
    subtitle_type: "Type of Impact",
    type_actual: "Actual Impact",
    type_potential: "Potential Impact",
    label_hasNegativeEffectOnHumanRights: "Potentially Negative Impact on Human Rights",
    label_probability: "Likelihood",
    explanation_probability: "How likely is it that the impact will occur?",
    subtitle_result: "Impact Evaluation",
    result_positive: "Positive",
    result_negative: "Negative",
    label_irreversibility: "Irremediable Character",
    explanation_irreversibility: `Describe how difficult it is to compensate for or reverse the negative impact afterwards.
      If it is very easy to restore the original state, then the irremediable character is very low.
      If it is particularly difficult or even impossible to restore the original state, then the irremediable character should be rated as very high.`,
    label_extent: "Scale",
    explanation_extent: `Assess the scale / extent of the impact on the significantly affected scope.
    First determine the scope of the significantly affected and then assess the scale of the impact on this group.
    Example: Elevator renovation at one of your locations:
    If there is an elevator in the connected neighboring building and only a few floors are step-free, then the scale for, e.g., wheelchair users can be rated as "rather high".`,
    label_scope: "Scope",
    explanation_scope: `Indicate the scope of how far-reaching the impact is in relation to the totality. Ask yourself the following three questions:
      1. Who could potentially be affected by the impact?
      2. Who is significantly affected?
      3. What is the proportion/scope of those significantly affected in relation to those potentially affected?\n
      Example: Elevator renovation at one of your locations:
      1. Only employees, customers, and people who are on site.
      2. Only people who rely on the elevator
      3. If there is a staircase and the location is only frequented by a few people with limited mobility, the scope can be rated as "very low".`,
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Impact Incomplete!",
    incomplete_for_shared_notice:
      "To release an impact, an explanation of the assessment must be provided.",
    // Buttons
    button_cancel: "Cancel",
    button_close: "Close",
    button_save: "Save",
    button_create: "Create",
    // Duplicate Dialog
    duplicate: "Duplicate Impact",
    duplicate_disabled_reason: "Please save all changes before duplicating the impact.",
    duplicate_dialog_title: 'Duplicate Impact <italic>"{{name}}"</italic>',
    duplicate_dialog_content: "Are you sure you want to duplicate the impact?",
  },
  dma_financial_effect_common: {
    effect_category: {
      select_label: "Category",
      market: "Market",
      technology: "Technology",
      policy_and_legal: "Policy and legal",
      reputation: "Reputation",
      acute_physical: "Acute physical",
      chronic_physical: "Chronic physical",
      resource_efficiency: "Resource efficiency",
      products_services: "Products / services",
      financial_incentives: "Financial incentives",
      others: "Others",
    },
  },
  dma_financial_effect_edit_dialog: {
    title_create: "Create Opportunity or Risk",
    title_edit: 'Edit <italic>"{{title}}"</italic>',
    delete_tooltip: "Delete Opportunity or Risk",
    move_to_other_topic: "Move Opportunity or Risk to another topic",
    // Effect Inputs
    effect_type_select_label: "Opportunity or Risk",
    risk_name: "Risk",
    chance_name: "Opportunity",
    subtitle_effect_type: "Financial Impact on",
    subtitle_additional_effect_types: "Other Types:",
    error_effect_type_required: "Please select at least one type for the impact.",
    label_potential_extent: "Potential Magnitude",
    label_probability_of_occurrence: "Likelihood of Occurrence",
    // Incomplete for State Shared
    incomplete_for_shared_notice_dialog_title: "Opportunity/Risk Incomplete!",
    incomplete_for_shared_notice:
      "To release an opportunity/risk, an explanation of the assessment must be provided.",
    incomplete_points: {
      // These don't follow naming convention because they have to match the names
      // of the associated input fields :)
      assessmentExplanation: "An explanation for the rating has to be provided.",
      effectType: "At least one financial effect has to be selected.",
    },
    // Buttons
    button_cancel: "Cancel",
    button_close: "Close",
    button_save: "Save",
    button_create: "Create",
    // Duplicate Dialog
    duplicate: "Duplicate Opportunity or Risk",
    duplicate_disabled_reason:
      "Please save all changes before duplicating the opportunity or risk.",
    duplicate_dialog_title: 'Duplicate Opportunity or Risk <italic>"{{name}}"</italic>',
    duplicate_dialog_content: "Are you sure you want to duplicate the opportunity or risk?",
  },
  delete_impact_or_effect_confirm_dialog: {
    "title_material-impact": 'Delete Impact <italic>"{{name}}"</italic> ?',
    "title_financial-effect": 'Delete Opportunity or Risk <italic>"{{name}}"</italic> ?',
    "content_text_material-impact": "Are you sure you want to delete the impact {{name}}?",
    "content_text_financial-effect":
      "Are you sure you want to delete the opportunity or risk {{name}}?",
    button_cancel: "Cancel",
    button_delete: "Delete",
  },
  move_iro_to_other_topic_dialog: {
    title_material_impact: 'Move Impact <italic>"{{name}}"</italic>?',
    title_financial_effect: 'Move Opportunity or Risk <italic>"{{name}}"</italic>?',
    content_text_material_impact: "Are you sure you want to move the impact {{name}}?",
    content_text_financial_effect:
      "Are you sure you want to move the opportunity or risk {{name}}?",
    button_move: "Move",
    // Select Topic
    choose_esrs_topic_label: "Select Topic",
    success_material_impact: "The impact has been moved successfully.",
    topic_disabled_reason_not_recorded:
      "Moving to the target topic is not possible, since it is not being recorded.",
    topic_disabled_reason_opt_out:
      "Moving to the target topic is not possible, as recording has been opted out of.",
    topic_disabled_reason_no_categories_available:
      "Moving to the target topic is not possible, as it does not possess any sub-topics.",
    topic_disabled_reason_all_categories_verified:
      "Moving to the target topic is not possible, as all sub-topics have already been verified.",
    // Select Category
    choose_category_label: "Select Sub-Topic",
    success_financial_effect: "The opportunity or risk has been successfully moved.",
    category_disabled_reason_already_verified:
      "Moving is not possible, please change the status of the topic to perform this action.",
    category_disabled_reason_same_category:
      "The opportunity or risk is already assigned to this topic.",
    category_disabled_reason_opt_out: 'Cannot move to a category that is marked as "not relevant"',
  },
  esrs_topic_opt_out_confirm_dialog: {
    title_opt_out: "Record no impacts for this ESRS topic?",
    title_edit: "Update Reason",
    content_text:
      'Are you sure you do not want to record any impacts, opportunities, or risks for the ESRS topic "{{topic}}" ({{name}})? ' +
      "You can undo your decision at any time.",
    opt_out_reason_label: "Reason",
    opt_out_reason_required: "Please provide a reason.",
    button_cancel: "Cancel",
    button_opt_out: "Confirm",
    button_edit: "Save",
  },
  // DMA Dashboard
  dma_dashboard: {
    title: "Double Materiality Assessment Dashboard",
    button_back_to_dma: "Back to Input",
    title_materiality_matrix: "Materiality Matrix",
    // Breadcrumbs
    breadcrumbs_item_total: "Total",
    // ### DMA Matrix
    title_overview: "Overview",
    // axis labels
    label_axis_financial_materiality: "Financial Materiality Level",
    label_axis_material_materiality: "Impact Materiality Level",
    // topics
    label_topic_environmental: "Environmental",
    label_topic_social: "Social",
    label_topic_governance: "Governance",
    label_topic_other: "Other",
    // categories
    no_categories_to_display_notice: "No topics to display",
    label_matrix_children: "Subtopics",
    // ### Overview Table
    title_overview_table: "Overview Table",
    label_show_impacts: "Impacts",
    label_show_effects: "Opportunities/Risks",
    button_expand_all: "Expand All",
    button_collapse_all: "Collapse All",
    // Headers
    table_header_material_impacts_group: "Impacts",
    table_header_financial_effects_group: "Opportunities/Risks",
    header_name: "Name",
    header_materiality_short_term: "Short Term",
    header_materiality_medium_term: "Medium Term",
    header_materiality_long_term: "Long Term",
    header_materiality_max: "Maximum",
    header_max_materiality_degree: "Maximum",
    header_material: "Materiality",
    // Content
    tooltip_opt_out: "Marked as not material",
    tooltip_no_children: "No subtopics",
    materiality_value_true: "Material",
    materiality_value_false: "Not Material",
    // Translations for TableCells
    cells: {
      value_exceeds_threshold: "This value exceeds the threshold of {{threshold}}.",
      any_value_exceeds_threshold: "One value exceeds the threshold.",
    },
    // IRO List
    title_iro_overview_list: "Impacts, Opportunities and Risks",
    // Filter
    iroType: "Type",
    effectAreaType: "Affected Area",
    materiality: "Materiality",
  },
  // Common DMA Components
  iros_overview_list_component: {
    table_header_title: "Titel",
    table_header_affected_area: "Affected area",
    table_header_materiality_degree: "Materiality level (max.)",
    table_header_result: "Result",
    table_header_result_tooltip: "short-term",
    table_header_type: "Type",
    // Notices
    notice_no_iros_at_all: "No impacts, opportunities or risks recorded.",
    notice_no_iros_for_filters:
      "No impacts, opportunities or risks were found for the set filters.",
  },
  fill_in_helper_iros_list_component: {
    title: "Impacts, Opportunities or Risks",
    filter_type: "Filter",
    type_hints: {
      "only-material":
        "Only material impacts, opportunities, and risks are being shown. Data comes from the double materiality analysis.",
      "topic-specific":
        "Only impacts, opportunities, and risks of the selected ESRS topic are shown. Data comes from the double materiality analysis.",
    },
  },
  // IRO Types in singular and plural
  iro_types: {
    singular: {
      impact: "Impact",
      opportunity: "Opportunity",
      risk: "Risk",
    },
    plural: {
      impact: "Impacts",
      opportunity: "Opportunities",
      risk: "Risks",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_common: {
    header_stakeholder: "Stakeholder",
    header_organization: "Organization",
    header_material: "Impacts",
    header_financial: "Opportunities/Risks",
    title_material: "Impacts",
    title_financial: "Opportunities/Risks",
    tooltip_feedback_not_requested: "Feedback not requested",
    tooltip_feedback_accepted: "Accepted",
    tooltip_feedback_accepted_with_comment: "Accepted with comment",
    tooltip_feedback_changes_requested: "Not accepted, changes requested",
    tooltip_feedback_pending: "Pending feedback",
    notice_feedback_pending: "No feedback received yet",
    tooltip_copy_link: "Copy the stakeholder's access-link for the feedback request",
    // Warnings
    category_open_warning:
      "The topic is neither marked as not relevant nor have impacts or opportunities/risks been captured. " +
      "If you request stakeholder feedback now, it may distort your results and have legal consequences for you.",
    financial_effects_drafts_warning:
      "Not all opportunities/risks have been released yet! If you request " +
      "stakeholder feedback now, it may distort your results and have legal consequences for you.",
    material_impacts_drafts_warning:
      "Not all impacts have been released yet! If you request " +
      "stakeholder feedback now, it may distort your results and have legal consequences for you.",
  },
  dma_category_stakeholder_feedback_dialog: {
    title: "Stakeholder Feedback for Topic {{name}}",
    header_feedback: "Stakeholder Feedback",
    empty_notice: "No feedback requested yet",
    button_request_feedback: "Request Feedback",
    header_manual_feedback: "Manual Feedback",
    manual_feedback_empty_notice: "No manual feedback provided yet",
    button_add_manual_feedback: "Add Manual Feedback",
  },
  dma_category_validation_button: {
    button_verify_topic: "Complete Topic",
    button_undo_verify_topic: "Undo Completion",
    tooltip_steps_to_verify:
      "Set all impacts and risks/opportunities to “Released” or the topic to “Not Relevant“ to be able to complete the topic.",
    title_confirm_validation: "Confirm Topic Completion",
    button_confirm_validation: "Confirm and Complete",
    content_confirm_validation:
      "You are about to mark this topic as complete without any stakeholder feedback for impacts or risks/opportunities. " +
      "Feedback can enhance your reporting quality.",
  },
  dma_category_stakeholder_feedback_info_dialog: {
    title: "Stakeholder Explanation",
    content_intro:
      "Please consider representatives from all stakeholder groups that you consider relevant for this " +
      "topic. A list of typical stakeholder groups:",
    item_employees: "Employees and other workers",
    item_suppliers: "Suppliers",
    item_consumer: "Consumers",
    item_customers: "Customers",
    item_end_users: "End users",
    item_local_communities: "Local communities",
    item_protection_needing_groups: "Protection needing groups",
    item_ngos: "NGOs",
    item_authorities:
      "Authorities, including regulatory authorities, supervisory authorities, and central banks",
    item_investors: "Existing and potential investors",
    item_scientific_constitutions: "Scientific institutions",
    item_creditors: "Creditors and other lenders, including asset managers",
    item_credit_institutions: "Credit institutions",
    item_insurers: "Insurance companies",
    item_nature: "Nature can be included as a silent stakeholder through environmental data",
  },
  dma_category_stakeholder_feedback_request_dialog: {
    title: "Request Feedback",
    no_stakeholders_notice: "No stakeholders created for organization yet.",
    tooltip_request_feedback: "Request Feedback",
    tooltip_revoke_feedback_request: "Revoke Request",
    tooltip_feedback_delivered: "Feedback received - cannot be revoked",
    button_manage_stakeholders: "Manage Stakeholders",
    no_request_due_to_verified_material:
      "Feedback for impacts cannot be requested as they are already completed.",
    no_request_due_to_verified_financial:
      "Feedback for opportunities and risks cannot be requested as they are already completed.",
  },
  dma_stakeholder_feedback_view: {
    prefix_last_changed: "Last Changed:",
    prefix_assessment: "Assessment:",
    prefix_comment: "Comment:",
  },
  // Manual Stakeholder-Feedback
  manual_stakeholder_feedback_common: {
    type_material: "Impacts",
    type_financial: "Opportunities/Risks",
    source_none: "No Source",
    source_interview: "Interview",
    source_workshop: "Workshop",
    source_survey: "Survey",
    source_discussion: "Discussion",
  },
  dma_category_manual_feedback_list: {
    header_stakeholder: "Stakeholder",
    header_type: "Feedback Type",
    header_source: "Source",
    title_confirm_delete_dialog: "Delete Manual Feedback?",
    content_confirm_delete_dialog: "Are you sure you want to delete the manual feedback?",
    title_feedback: "Feedback",
    title_sources: "Sources",
  },
  dma_category_manual_feedback_edit_dialog: {
    title_create: "Create Manual Feedback",
    title_edit: "Edit Manual Feedback",
    label_feedback_type: "Feedback Type",
    label_feedback_source: "Source",
    label_stakeholders: "Stakeholders",
    label_sources: "Sources",
    error_stakeholders_required: "Please specify at least one stakeholder.",
    no_stakeholders_selected_notice: "No stakeholders selected",
    label_feedback: "Feedback",
    error_feedback_required: "Please provide feedback.",
    button_manage_stakeholders: "Manage Stakeholders",
    no_stakeholders_available: "No stakeholders available",
  },
  dma_language_picker_component: {
    title: "$t(dma_common:title)",
    subtext:
      "Before you can start with the DMA, you have to pick a language in which all content will be written:",
    button: "Continue",
    confirm: {
      title: "Set language?",
      content:
        'Are you sure you want to set the language to "{{language}}"?<br/>' +
        "<br/>" +
        "<strong>The selected language CANNOT be changed once it is set!</strong>",
    },
  },
  // Content Translation
  language: {
    label: "Language",
    translationsPerLanguage: {
      [IContentLanguage.De]: "German",
      [IContentLanguage.En]: "English",
    } as { [key in IContentLanguage]: string },
  },
  // Audit Logging
  audit_logging_configuration: {
    title: "Activity Protocol",
    empty_notice: "No audit logs found.",
    header_name: "Name",
    header_organization: "Organization",
  },
  audit_logging_edit_list_component: {
    headers: {
      timeStamp: "Timestamp",
      userName: "Username",
      stakeholderName: "Stakeholder name",
      action: "Action",
    },
    events: auditLoggingEventsTranslations,
  },
  audit_logging_comparison_view_component: {
    headers: {
      before: "Before",
      after: "After",
    },
    no_data_available: "No data available.",
  },
  audit_logging_expand_component: {
    changelog: "Changelog",
    headers: {
      value_name: "Value Name",
      before_value: "Before",
      after_value: "After",
    },
  },
  // Stakeholder
  stakeholders_configuration: {
    title: "Stakeholders",
    empty_notice: "No stakeholders created for organization yet.",
    header_name: "Name",
    header_organization: "Organization",
    button_create_stakeholder: "Add Stakeholder",
    button_create: "Create",
    delete_dialog_confirm: "$t(buttons:delete)",
    delete_dialog_title: "Delete Stakeholder?",
    delete_dialog_body:
      "Are you sure you want to delete the stakeholder? This action cannot be undone.",
  },
  stakeholder_edit_dialog: {
    title_create: "Create Stakeholder",
    title_edit: "Edit Stakeholder",
    label_name: "Name",
    error_name_required: "Please enter a name.",
    label_organization: "Organization (optional)",
    create_stakeholder: "Create Stakeholder",
  },
  // Distribution Criteria
  distribution_criteria_configuration: {
    title: "Distribution Criteria",
    empty_notice: "No distribution criteria configured for organization yet.",
    header_name: "Name",
    button_create_stakeholder: "Add Distribution Criteria",
    button_create: "Create",
    delete_dialog_title: "Delete Distribution Criteria?",
    delete_dialog_body:
      "Are you sure you want to delete the distribution criteria? This action cannot be undone.",
  },
  distribution_criteria_edit_dialog: {
    title_create: "Create Distribution Criteria",
    title_edit: "Edit Distribution Criteria",
    label_name: "Name",
    error_name_required: "Please enter a name.",
  },
  // Applied Distribution criteria
  applied_distribution_criteria_configuration_page: {
    title: "Applied Distribution Criteria",
  },
  applied_distribution_criteria_configuration_component: {
    title: "Applied Distribution Criteria",
    dropdowns: {
      recording_period_dropdown: {
        recording_period_select_label: "Recording Period",
      },
      distribution_criterion_dropdown: {
        label: "Distribution Criteria",
      },
      organization_structure_is_draft_notice:
        "The organization structure is currently a draft. You must publish the organization structure " +
        "before applying distribution criteria.",
    },
    no_criteria_configured: {
      text: "No distribution criteria configured for the organization.",
      button_text: "Configure Now",
    },
    configure_criterion: {
      text: "This distribution criteria has not been configured for the selected recording period yet.",
      button_text: "Configure Now",
    },
    alert_info: {
      body: "Changes will be automatically saved when leaving the input field!",
      info_already_applied:
        "The distribution criteria has already been applied. This means that values can still be modified but cannot be completely removed.",
    },
  },
  applied_distribution_criteria_table_component: {
    buttons: {
      expand_all: "Expand All",
      collapse_all: "Collapse All",
      remove_configuration: "Remove Configuration",
      // Apply
      apply_configuration: "Apply Configuration",
      tooltip_not_populated:
        "The configuration cannot be applied yet as not all values have been entered.",
      tooltip_populated: "Apply Configuration",
      // Remove application
      remove_application: "Remove Application",
      tooltip_remove_application: "Remove Application",
      tooltip_remove_application_disabled:
        "The application cannot be removed as values have already been entered based on it.",
    },
    table_header: {
      data_entry_object_name: "Name",
      value: "Value",
    },
    confirm_dialog: {
      title: "Remove Configuration?",
      body: "Are you sure you want to remove the configuration? All values will also be deleted. This action cannot be undone.",
    },
  },
  applied_distribution_criteria_table_row_component: {},
  applied_distribution_criteria_table_cell_component: {
    error_required: "Since the criteria has been applied, a value must be entered.",
  },
  // Sources
  sources: {
    sources: "Sources",
    manage_sources: "Manage sources",
    source_type: {
      data_source: "Data Source",
      calculation_source: "Calculation Source",
      methodology_source: "Methodology Source",
      guideline_or_template_source: "Guideline/Template",
    },
    warnings: {
      cannot_be_linked:
        "This source cannot currently be assigned to a data point because it does not contain a description or no files have been uploaded.",
    },
  },
  linked_sources: {
    no_linked_sources: "No sources linked",
    add_linked_source_tooltip: "Link source",
    no_sources_available: "No sources available",
    no_sources_found: "No sources found",
    labels: {
      linked_sources: "Sources",
    },
    selected_sources_count: "{{count}} selected",
  },
  sources_configuration_page: {
    title: "Sources",
  },
  sources_configuration_component: {
    empty_notice: "No sources created for the organization yet.",
    buttons: {
      create: "Create",
    },
    confirm: {
      title: "Remove Source?",
      body: "Are you sure you want to remove this source? This action cannot be undone!",
    },
  },
  sources_edit_dialog: {
    title_create: "Create Source",
    title_edit: "Edit Source",
    labels: {
      name: "Name",
      description: "Description or link to source",
      source_type: "Source Type",
      manage_files: "Manage files",
    },
    errors: {
      name_required: "Please enter a name",
      source_type_required: "Please choose an option",
    },
  },
  sources_list_component: {
    headers: {
      name: "Name",
      description: "Description",
      source_type: "Source Type",
    },
  },
  sources_files_dialog: {
    title: "Edit Files",
    name: "File",
    type: "File type",
    delete_title: "Remove file from source {{sourceName}}?",
    delete_body: "Are you sure you want to remove this file? This action cannot be undone!",
    upload_file: "Upload",
    no_files_uploaded: "No files have been uploaded yet.",
    file_constraints:
      "Maximum file size is {{maxFileSizeTextRepr}}. Allowed file types are: {{allowedFileTypes}}",
  },
  // DMA Categories
  dma_category_delete_confirm_dialog: {
    title: 'Delete Topic "{{name}}"?',
    content_text: 'Are you sure you want to delete the topic "{{name}}"?',
    button_cancel: "Cancel",
    button_delete: "Delete",
  },
  dma_category_edit_dialog: {
    title_create: "Create New Topic",
    title_edit: "Edit Topic {{name}}",
    delete_tooltip: "Remove Topic",
    cannot_delete_tooltip:
      "A topic can only be deleted if it has no subtopics, impacts, risks, or opportunities, " +
      "and if no feedback has been requested or manually provided yet.",
    error_name_required: "Please enter a name.",
    name_label: "Name",
    button_cancel: "Cancel",
    button_create: "Create",
    button_save: "Save",
  },
  dma_category_opt_out_dialog: {
    title_opt_out: "Mark this topic as not relevant?",
    title_edit: "Update Reason",
    content_text:
      'Are you sure you want to mark the topic "{{name}}" and all its subtopics as not relevant? ' +
      'Provide a reason for why "{{name}}" and its subtopics are not relevant for your organization. ' +
      "You can change your decision at any time.",
    label_opt_out_reason: "Reason",
    error_opt_out_reason_required: "Please provide a reason.",
    button_cancel: "Cancel",
    button_opt_out: "Confirm",
    button_edit: "Save",
  },
  dma_category_opt_back_in_confirm_dialog: {
    title: "Reset Topic to Relevant?",
    content:
      // eslint-disable-next-line no-useless-concat
      'Are you sure you want to reset the topic "{{name}}" and all its subtopics ' + "to relevant?",
    button_confirm: "Reset",
  },
  // ESRS Topic
  dma_esrs_topic_component: {
    create_category_button: "Create Topic",
    create_category_button_tooltip:
      'Create a new topic under the parent topic "{{esrsTopicName}}".',
  },
  dma_esrs_topic_not_yet_recorded_component: {
    header: "Nothing has been recorded for this topic yet.",
    start_collection: "You can now either",
    start_collection_button: "Use Template",
    start_collection_button_tooltip:
      "Use standard template for recording impacts or opportunities/risks for this topic. " +
      "Alternatively, you can create topics yourself via the following button",
    skip_collection: "or",
    skip_collection_button: "Skip Impacts",
    skip_collection_button_tooltip: "Do not record impacts or opportunities/risks for this topic",
  },
  dma_esrs_topic_opted_out_component: {
    header: "You have opted out of recording for this topic.",
    opt_out_reason_prefix: "Reason:",
    start_collection_button: "Use Template",
    start_collection_button_tooltip:
      "Use standard template for recording impacts or opportunities/risks for this topic. " +
      "Alternatively, you can create topics yourself via the following button",
    edit_opt_out_reason_button_tooltip: "Edit Reason",
  },
  // Tabular Data
  tabular_input_component: {
    header_dynamic_table: "Actions",
    header_required_tooltip: "Values in this column must be filled in.",
  },
  dynamic_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Add New Row",
      button_add_row: "Add",
      tooltip_save: "Save Changes",
      button_save: "Save",
    },
    row: {
      tooltip_remove_row: "Remove Row",
      tooltip_save_row: "Save Row",
      tooltip_undo_row: "Undo Row",
      tooltip_add_note: "Add Note",
      tooltip_remove_note: "Remove Note",
    },
  },
  static_tabular_input_component: {
    action_bar: {
      tooltip_add_row: "Add New Row",
      button_add_row: "Add",
      tooltip_save: "Save Table",
      tooltip_reset_table: "Reset Table",
      button_save: "Save",
      tooltip_remove_note: "Remove Note",
      tooltip_add_note: "Add Note",
    },
    confirm_dialog: {
      title: "Reset Table?",
      body: "Are you sure you want to reset the table? All data will be lost. This action cannot be undone.",
      button_cancel: "$t(buttons:cancel)",
      button_confirm: "Delete",
    },
    row: {
      tooltip_undo_row: "Undo Row",
    },
  },
  // Stakeholder-Feedback
  stakeholder_feedback_overview_component: {
    title: 'Feedback for "{{name}}"',
    subtitle_no_rps:
      'No recording periods have been configured yet for the organization "{{organizationName}}". Therefore, submitting feedback is not possible yet.',
  },
  stakeholder_feedback_details_component: {
    select_title: "Recording Period:",
  },
  stakeholder_feedbacks_component: {
    no_feedback_requested: "No feedback has been requested for the chosen recording period yet.",
    buttons: {
      previous: "Previous topic",
      next: "Next topic",
    },
  },
  stakeholder_feedback_category_component: {
    material_impacts_header: "Material Impacts",
    financial_effects_header: "Financial Effects",
    button_add_feedback: "Provide Feedback",
    tooltip_add_feedback_impacts: "Provide Feedback for impacts",
    tooltip_add_feedback_effects: "Provide Feedback for opportunities and risks",
    button_edit_feedback: "Edit Feedback",
    tooltip_edit_feedback_impacts: "Edit Feedback for impacts",
    tooltip_edit_feedback_effects: "Edit Feedback for opportunities and risks",
    alert_not_yet_shared: {
      title: "Topic Not Yet Released",
      content:
        "The topic has not yet been released, so there may still be changes in the data " +
        "after you provide your feedback. Please confirm the data status with your contact person " +
        "before providing feedback.",
    },
    alert_category_open: {
      title: "Topic Not Yet Recorded",
      content: "No impacts or financial effects have been recorded for this topic yet.",
    },
    alert_no_iro_material: "No impacts have been identified for this topic.",
    alert_no_iro_financial: "No financial effects have been identified for this topic.",
    category_verified_notice_material: "Material impacts completed - feedback cannot be changed.",
    category_verified_notice_financial: "Financial effects completed - feedback cannot be changed.",
    feedback_indicator: {
      status_submitted: "Feedback Provided",
      "status_not-submitted": "Feedback Pending",
      "status_parent-submitted": "Topic has been considered in the feedback of the parent topic.",
      "status_parent-not-submitted":
        "Topic needs to be considered in the feedback of the parent topic.",
    },
  },
  stakeholder_feedback_dialog: {
    title_material: "Feedback for Material Impacts - {{name}}",
    title_financial: "Feedback for Financial Effects - {{name}}",
    label_agreed: "I agree with the assessment.",
    label_feedback: "Feedback",
    label_assessment: "Assessment",
    label_add_divergent_assessment: "Propose Alternative Assessment",
    explanation_material_assessment: "How do you assess this impact?",
    explanation_financial_assessment: "How do you assess this opportunity / risk?",
    // Slider values
    slider_value_very_low: "1 - Very Low",
    slider_value_low: "2- Low",
    slider_value_average: "3 - Average",
    slider_value_high: "4 - High",
    slider_value_very_high: "5 - Very High",
    slider_info_box_text:
      "Rate the materiality of impacts, risks, or opportunities for the company in the context of the topic “{{topicTitle}}” on a scale of 1-5.",
    slider_info_box_text_material:
      "Rate the materiality of impacts for the company in the context of the topic “{{topicTitle}}” on a scale of 1-5.",
    slider_info_box_text_financial:
      "Rate the materiality of risks, or opportunities for the company in the context of the topic “{{topicTitle}}” on a scale of 1-5.",
    // Buttons
    button_cancel: "$t(buttons:cancel)",
    button_save: "$t(buttons:save)",
    button_create: "Provide Feedback",
    button_close: "$t(buttons:close)",
    // Validation
    error_at_least_one_form_of_feedback_required:
      "At least one form of feedback (feedback or divergent assessment) must be provided.",
    // Materiality details
    materiality_materiality_degree_label: "Materiality Level",
    materiality_material_label: "Material?",
    material_yes: "$t(dma_common:materiality_true_state)",
    material_no: "$t(dma_common:materiality_false_state)",
    materiality_opt_out_title: "Topic Marked as Not Material:",
    materiality_tooltip_materiality_degree: "Materiality Level (Maximum Value: 5)",
  },
  // Reference Budgets
  reference_budgets_page: {
    title: "Reference Budgets",
  },
  reference_budgets_component: {
    empty_notice: "No reference budgets have been created for the organization.",
    buttons: {
      create: "Create",
    },
    confirm: {
      title: "Remove Reference Budget?",
      body: "Are you sure you want to remove this reference budget? This action cannot be undone.",
    },
    refresh: {
      title: "Refresh Data for Reference Budget?",
      body: "Are you sure you want to refresh the data for this reference budget? The old data will be overwritten and will no longer be accessible!",
    },
  },
  reference_budgets_edit_list_component: {
    headers: {
      name: "Name",
      values_fetched_at: "Values fetched at",
      reference_year: "Reference Year",
      reference_revenue: "Reference Revenue",
      budget_per_year: "Annual Budget",
    },
    action_tooltips: {
      edit: "Edit Reference Budget",
      delete: "Delete Reference Budget",
      refresh: "Refresh Data for Reference Budget",
    },
  },
  reference_budget_edit_dialog: {
    title_create: "Create Reference Budget",
    title_edit: "Edit Reference Budget",
    labels: {
      name: "Name",
      recording_periods: "Recording Periods for Reference Budget",
      reference_revenue: "Reference Revenue",
      reference_year: "Reference Year",
    },
    errors: {
      name_required: "Please enter a name.",
      recording_periods_required: "At least one recording period must be selected.",
    },
    notices: {
      recording_periods_have_changed:
        "The recording periods have been adjusted - therefore the budget will be recalculated. This cannot be undone!",
    },
  },
  // Target Paths
  target_paths_configuration_page: {
    title: "Target Paths",
  },
  target_paths_configuration_component: {
    empty_notice: "No target paths have been created for the organization.",
    notice_no_reference_budget: {
      text: "You must create at least one reference budget before configuring target paths.",
      button_text: "Go to Reference Budgets",
    },
    subheaders: {
      current_organization_path: "Set Corporate Target Path",
      paths_in_progress: "Target Paths in Progress",
    },
    buttons: {
      create: "Create",
    },
    tooltips: {},
  },
  target_path_dialogs: {
    confirm: {
      title: "Remove Target Path?",
      body: "Are you sure you want to remove this target path? This action cannot be undone.",
    },
    confirm_toggle: {
      for_state: {
        [ITargetPathState.Committed]: {
          title: "Downgrade Target Path to Draft?",
          body: "Are you sure you want to downgrade the target path to draft? This action can be undone at any time.",
        },
        [ITargetPathState.Draft]: {
          title: "Set Target Path as Corporate Target Path?",
          body_1:
            'Are you sure you want to set "{{targetPathName}}" as the organizational target path?',
          body_2: 'This action will downgrade the following paths to "Draft":',
        },
      },
    },
  },
  target_path_progress_component: {
    header: "Progress",
    labels: {
      recording_structures_for_evaluation: "Recording Structures for Evaluation",
    },
  },
  target_paths_edit_list_component: {
    headers: {
      name: "Name",
      reference_budget_name: "Selected Reference Budget",
      state: "Status",
    },
    action_tooltips: {
      edit: "Edit Target Path",
      edit_disabled:
        "The target path cannot be edited anymore as it has already been set / goals have already been achieved.",
      delete: "Delete Target Path",
      delete_disabled:
        "The target path cannot be deleted anymore as it has already been set / goals have already been achieved.",
      commit: "Set as Corporate Target Path",
      commit_disabled:
        "This path cannot be set as an organizational path as no goals have been set!",
      cease: "Downgrade to Draft",
      clone: "Clone Target Path",
    },
  },
  target_path_actions_component: {
    action_tooltips: {
      edit: "Edit Target Path",
      edit_disabled:
        "The target path cannot be edited anymore as it has already been set / goals have already been achieved.",
      delete: "Delete Target Path",
      delete_disabled:
        "The target path cannot be deleted anymore as it has already been set / goals have already been achieved.",
      commit: "Set as Corporate Target Path",
      commit_disabled:
        "This path cannot be set as an organizational path as no goals have been set!",
      cease: "Downgrade to Draft",
      clone: "Clone Target Path",
    },
  },
  target_path_edit_dialog: {
    title_create: "Create Target Path",
    title_edit: "Edit Target Path",
    labels: {
      name: "Name",
      reference_budget: "Reference Budget for Target Path",
      use_template: "Use Template for Goals?",
      template: "Goal Template",
    },
    errors: {
      name_required: "Please enter a name.",
      name_unique: "The name must be unique within the organization.",
    },
    tooltips: {
      use_template:
        "Example goals will be automatically generated to assist you in the configuration.",
    },
    goals: {
      [ITargetPathGoalTemplateType.ParisConform]: "Paris-Compliant Standard Target Path",
    },
  },
  target_path_clone_dialog: {
    title: "Clone Target Path",
    labels: {
      name: "New Name",
    },
    errors: {
      name_required: "Please enter a name.",
      name_unique: "The name must be unique within the organization.",
    },
  },
  target_path_state_chip_component: {
    text_by_state: {
      [ITargetPathState.Draft]: "Draft",
      [ITargetPathState.Committed]: "Set",
      [ITargetPathState.Fulfilled]: "Fulfilled",
    },
    tooltip_text_by_state: {
      [ITargetPathState.Draft]:
        "This target path is still in the development stage. Changes can be made at any time.",
      [ITargetPathState.Committed]:
        "This target path has been chosen as a direction for the organization and therefore cannot be edited.",
      [ITargetPathState.Fulfilled]:
        "The goals derived from this path have already been achieved. It cannot be edited anymore.",
    },
  },
  target_path_is_committed_info_component: {
    title: "Info",
    text: "This target path has been set as the corporate target path. Therefore, it cannot be edited.",
  },
  // Target Path Goals
  target_path_details_component: {
    title: 'Target Path "{{targetPathName}}"',
  },
  target_path_goals_edit_list_component: {
    title: "Goals",
    headers: {
      target_year: "Target Year",
      reduction_to_reference_year: "Reduction/Increase to Reference Year",
      emissions_in_target_year: "Emissions in Target Year (Total)",
    },
    buttons: {
      create: "Add",
    },
    confirm: {
      title: "Remove Goal?",
      body: "Are you sure you want to remove this goal? This action cannot be undone.",
    },
    action_tooltips: {
      edit: "Edit Goal",
      delete: "Delete Goal",
    },
  },
  target_path_goal_edit_dialog_component: {
    title_create: "Create Goal",
    title_edit: "Edit Goal",
    labels: {
      target_year: "Target Year",
    },
    errors: {
      date: {
        minDate:
          "The selected target year cannot be before the reference year ({{referenceYear}}).",
        targetForYearAlreadyDefined:
          "A goal for the target year {{targetYear}} has already been set.",
      },
    },
  },
  target_path_goal_edit_component: {
    toggle: {
      text_absolute: "Absolute",
      text_relative: "Relative",
    },
    confirm_dialog: {
      title: "Change Recording Method?",
      body: "Are you sure you want to change the recording method? All values entered so far will be deleted. This action cannot be undone.",
    },
  },
  target_path_goal_value_change_input_component: {
    labels: {
      change: "Reduction",
      change_type: "Change Type",
    },
  },
  absolute_target_path_goal_edit_component: {
    translations: {
      in_percent: "Reduction in [%]",
      by_value: "Reduction by [tCO\u2082eq]",
      to_value: "Reduction to [tCO\u2082eq]",
    },
    helper_text: "Reference Budget: {{referenceBudget}}",
    helper_text_no_value: "No reference budget available",
    scopes: {
      scope_reductionScope1: "Reduction Scope 1",
      scope_reductionScope2: "Reduction Scope 2",
      scope_reductionScope3: "Reduction Scope 3",
    },
    tooltips: {
      reduction_as_percent: "This corresponds to a {{reductionTerm}} of {{percentage}} %.",
      reduction_as_by_value: "This corresponds to a {{reductionTerm}} of {{value}} t CO\u2082eq.",
      no_value: "No value can be entered as there is no reference budget for this scope.",
    },
  },
  relative_target_path_goal_edit_component: {
    translations: {
      in_percent: "Annual Revenue Growth in [%]",
      by_value: "Growth by [€]",
      to_value: "Expected Revenue in Target Year",
    },
    headings: {
      revenue: "Revenue",
      intensity: "Intensity",
    },
    revenue: {
      textfield_label_growth: "Revenue Growth",
      textfield_label_absolute: "Target Revenue",
      helper_text: "Reference Revenue: {{referenceRevenue}}",
      tooltip_dropdown_no_revenue:
        "Only a concrete revenue target (and no change) can be entered as no reference revenue has been set.",
    },
  },
  target_intensity_input_component: {
    helper_text_no_value: "No reference budget available",
    tooltip_no_value: "No value can be entered as there is no reference budget for this scope.",
    tooltip_value:
      "This corresponds to emissions of {{absoluteValue}} t CO\u2082eq or a {{reductionTerm}} of {{relativeReduction}} %.",
    scopes: {
      intensityScope1: "Target Intensity Scope 1",
      intensityScope2: "Target Intensity Scope 2",
      intensityScope3: "Target Intensity Scope 3",
    },
  },
  reference_intensity_component: {
    content: "The {{textSum}} reference intensity is: {{referenceIntensity}} tCO\u2082eq / €.",
  },
  target_path_diagram_component: {
    title: "Diagram",
    title_for_organization_path: "Corporate Target Path",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    } as const,
  },
  target_path_general: {
    terms: {
      reduction: "Reduction",
      increase: "Increase",
    },
  },
  target_path_progress_diagram_component: {
    title_for_organization_path: "Corporate Target Path",
    scopes: {
      [ScopeKeys.Scope1]: "Scope 1",
      [ScopeKeys.Scope2]: "Scope 2",
      [ScopeKeys.Scope3]: "Scope 3",
    },
  },
  // Input Components
  list_value_input_component: {
    empty_option: "No Value",
  },
  nested_options_input_component: {
    buttons: {
      close: "Close",
      clear: "Reset choice",
    },
    search: {
      placeholder: "Search...",
    },
  },
  // Input Validation
  value_validation_errors: {
    required: "Please enter a value for this field.",
    number: {
      base: "Please enter a number.",
      precision: "The value can have at most {{limit}} decimal places.",
      min: "The value must be greater than or equal to {{limit}}.",
      max: "The value must be less than or equal to {{limit}}.",
      unsafe: "The value must be a safe number.",
    },
    boolean: {
      base: "Please select a value.",
    },
    string: {
      empty: "Please enter some text.",
    },
    array: {
      includesRequiredUnknowns: "At least one option must be selected.",
      sparse: "Please enter a value for every field.",
      max: "No more than {{limit}} values can be selected.",
    },
    date: {
      format: "Please enter a valid date.",
      min: "The date must be after {{limit}}.",
      max: "The date must be before {{limit}}.",
      greater: "The start date must be before the end date.",
    },
    any: {
      only: "Please select an option.",
      not_unique: "Not unique.",
    },
    "text-area": {
      invalid_content: "Incorrect content could not be processed.",
    },
  },
  number_input: {
    placeholder: "Enter Number",
  },
  currency_input: {
    placeholder: "Enter Amount",
  },
  text_input: {
    placeholder: "Enter Text",
  },
  text_area_input: {
    placeholder: {
      default: "Enter Text",
      input_parameter: "Enter Text",
      disclosure_requirement:
        "Enter all information on the disclosure obligation " +
        "here and use the data points below as a guide.",
    },
  },
  boolean_input: {
    value_true: "Yes",
    value_false: "No",
  },
  date_input_component: {
    remove_date_tooltip: "Remove Date",
  },
  value_with_unit_input: {
    placeholder: "Enter number",
    per_monetary: "MM. €",
  },
  referenced_target_input: {
    placeholder_one: "Choose target",
    placeholder_other: "Choose targets",
    dialog: {
      title_one: "Choose target",
      title_other: "Choose targets",
      table_header_name: "Target",
    },
  },
  // Intercom
  intercom_reference_widget_component: {
    tooltip: "Open help",
  },
  // Errors
  error: {
    axios: {
      connection: "Connection error - Please check your internet connection",
    },
    generic: {
      "400": "Faulty request",
      "401": "Unauthorized",
      "403": "No permission",
      "404": "Not found",
      "409": "Conflict",
      "500": "Internal server error",
      "502": "API not available",
    },
    general: {
      entity_not_found: "Not found.",
      internal_server_error: "Internal server error.",
    },
    auth: {
      unauthorized:
        "Your session has expired or your credentials are invalid. Please log in again.",
    },
    input_parameter: {
      not_found: "The value could not be found.",
    },
    organization: {
      not_found: "The organization could not be found.",
    },
    recording_period: {
      not_found: "The recording period could not be found.",
      previous_structure_is_draft:
        "The previous organizational structure is a draft. It must be published before a new recording period can be created.",
      value_would_move_outside_scope:
        "Values have already been specified for the recording period outside the new period!",
      overlaps_with_existing_recording_period:
        "The specified period overlaps with an existing recording period!",
    },
    recording_model: {
      not_found: "The recording model could not be found.",
    },
    recording_model_templates_group: {
      not_found: "The topic could not be found.",
    },
    input_parameter_recording_structure_template: {
      not_found: "The template could not be found.",
      no_organization_license: "Organization license does not cover this template.",
    },
    input_parameter_recording_structure: {
      not_found: "The recording structure could not be found.",
      input_parameter_used_more_than_once:
        "A value can only be recorded once within the recording structure!",
      input_parameter_not_found:
        "At least one unknown value for recording was specified in the recording structure!",
      order_in_use:
        "The specified order of the recording structure within the recording model is already in use!",
      no_organization_license: "License for organization does not include structure!",
    },
    organization_structure: {
      not_found: "The organization structure could not be found.",
      not_yet_committed: "The organization structure has not yet been published.",
      already_committed: "The draft has already been published.",
      duplicate_ids:
        "Unexpected error! The values of a past data entry object are inherited by multiple " +
        "new data entry objects!",
      id_doesnt_belong_to_previous_structure:
        "Unexpected error! An attempt was made to inherit an data entry object that was not " +
        "part of the previous organizational structure!",
    },
    input_parameter_recording_method: {
      not_found: "Recording method used for value not found.",
    },
    data_entry_object: {
      not_found: "The data object could not be found.",
      next_already_set:
        "The data object is already being used by another data object as a predecessor (recorded " +
        "values are inherited).",
    },
    data_entry_object_input_parameter_value: {
      bad_value: "Faulty value!",
      value_already_recorded_for_period:
        "Value has already been recorded for the given recording period.",
      not_found: "Value is not recorded for the given data entry object.",
      value_outside_recording_period:
        "The date for the value is outside the recording period for which it was recorded!",
    },

    dma_category: {
      not_found: "The topic could not be found.",
      has_children: "Action not possible: The topic has subordinate topics!",
      has_associated_financial_effects:
        "Action not possible: The topic has subordinate opportunities and risks!",
      has_associated_material_impacts: "Action not possible: The topic has subordinate impacts!",
      opt_out_of_category_with_iros:
        "Opt out not possible: The topic has impacts, opportunities, or risks subordinate!",
      opt_out_without_reason: "Marking as not relevant without reason is not possible",
      opt_in_with_reason: "Undo marking as not relevant with reason is not possible",
      has_opted_out: "Already marked as not relevant",
      in_invalid_state: "Status of the topic for completion is invalid",
      already_verified: "Topic is already completed",
    },
    dma_effect: {
      reason_was_not_provided: "A reason needs to be provided.",
      reason_was_provided_without_override: "Reason was provided without override!",
      cannot_be_moved_to_opt_out_category:
        'Cannot move to a topic that is marked as "not relevant"',
      cannot_be_moved_to_opt_out_topic: 'Cannot move to a topic that is marked as "not relevant"',
    },
    dma_financial_effect: {
      not_found: "Opportunity/Risk not found",
      not_responsible_user: "Your user is not responsible for the opportunity/risk",
      already_published: "Opportunity/Risk is already released and cannot be changed anymore",
      incomplete_for_shared:
        "To release an opportunity/risk, you must provide an explanation of the assessment.",
      referenced_in_policies:
        "The risk/chance is referenced in a policy. Therefore, its state cannot be changed back to 'draft'.",
      referenced_in_actions:
        "The risk/chance is referenced in an action. Therefore, its state cannot be changed back to 'draft'.",
    },
    dma_material_impact: {
      not_found: "Impact not found.",
      not_responsible_user: "No responsible person specified for the impact.",
      already_published: "Already released.",
      incomplete_for_shared:
        "To release an opportunity/risk, you must provide an explanation of the assessment.",
      referenced_in_policies:
        "The impact is referenced in a policy. Therefore, its state cannot be changed back to 'draft'.",
      referenced_in_actions:
        "The impact is referenced in an action. Therefore, its state cannot be changed back to 'draft'.",
    },
    esrs_topic: {
      not_found: "ESRS topic not found",
      does_not_support_custom_categories:
        "Custom topics cannot be created for the selected ESRS topic",
    },
    recorded_esrs_topic: {
      opt_out_of_topic_with_categories:
        "Cannot set no impacts for this topic as sub-topics have already been created",
      does_not_exist: "The specified ESRS topic or the used data entry object does not exist",
      category_is_not_child: "The specified topic is not part of the specified ESRS topic",
      opt_out_without_reason: "To not select topics for an ESRS topic, you must provide a reason",
      opt_in_with_reason: "To undo topic selection, no reason must be provided",
    },
    dma_execution: {
      invalid_category: "Invalid topic",
      feedback_missing: "Feedback missing",
    },
    data_entry_object_input_parameter: {
      attribution_reason_not_present: "",
      explanation_required: "Explanation is required for the chosen reason.",
    },
    stakeholder: {
      not_found: "Stakeholder not found",
      has_associated_feedback:
        "The stakeholder could not be deleted because they have provided feedback.",
    },
    stakeholder_feedback: {
      not_found: "Stakeholder feedback not found",
      invalid:
        "Feedback invalid - either agreement and only one comment (without any other suggested " +
        "rating) or, if the rating is not released, at least one other suggested rating or a feedback " +
        "text must be provided.",
      already_submitted: "Feedback already submitted",
    },
    manual_stakeholder_feedback: {
      not_found: "Manual stakeholder feedback not found",
    },
    // Distribution Criterion
    distribution_criterion: {
      not_found: "Distribution criteria not found",
      already_in_use: "The distribution criteria is already in use and cannot be deleted.",
    },
    // Reference Budget
    reference_budget: {
      is_used_in_target_path:
        "The reference budget is used in a target path and therefore cannot be deleted.",
      not_all_target_paths_in_draft_state:
        "The budget is already referenced by target paths that have been set as corporate target paths. The calculated CO\u2082 budget cannot be changed anymore.",
    },
    // Source
    source: {
      is_referenced: "The source is already referenced and therefore cannot be deleted.",
    },
    // Source Files
    source_file: {
      invalid_mime_type: "The file or one of the files has an invalid file type.",
    },
    // S3 Errors
    EntityTooLarge: "The file or one of the files is too large.",
    unknown: "Unknown error",
    update_user_org_role: {
      forbidden: "Request to update user organization role is forbidden.",
    },
    update_user_data_entry_objects_access: {
      user_is_not_data_entry_object_admin: "The user must have the permission object access.",
      missing_data_entry_object_descendants: "Missing data entry object descendants.",
    },
    // Emission Factors
    emission_factor: {
      not_found: "Emission factor could not be found.",
      related_emission_factor_not_location_based: "Related emission factor is not location based.",
      units_not_equal: "The linked emission factor does not have the same unit.",
      is_referenced: "The emission factor is already referenced and therefore cannot be deleted.",
    },
  },
  not_found: {
    title: "This page was not found",
    go_to_dashboard: "Back to Home",
  },
  dma_categories: generateDMACategoryTranslations(IContentLanguage.En),
  esrs_topics: generateESRSTopicTranslations(IContentLanguage.En),
  users_table: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    role: "Role",
    no_users_found: "No users found",
  },
  esrs_paywall: {
    title: "ESRS Data Collection Module Not Unlocked",
    content:
      "Your company account has not yet unlocked the ESRS Data Collection Module. Click the link below to contact our team directly. Once purchased, we will unlock the module for you immediately.",
    cta: "Contact us",
  },
  users_page: {
    title: "Users",
  },
  roles: {
    organization_admin: "Full Access",
    organization_admin_description: "The user can perform all actions without restrictions.",
    object_admin: "Object Access",
    object_admin_description:
      "The user is assigned to one or more data entry objects and can view and edit all data points for these and all subsidiary objects.",
    view_members: "Limited",
    view_members_description:
      "The user can only view and edit data points where he is assigned as responsible or a contributor.",
  },
  require_organization_role: {
    access_denied: "You don't have permission to view this screen",
  },
  update_deo_access_dialog: {
    title: "Update Data Entry Object Access",
    deo_picker_label: "Select Data Entry Objects",
  },
  organizations_layout: {
    organization_disabled:
      "Your organization's access has expired.<br/><br/> Please contact <link/> if you want to continue using NetCero",
  },
  emission_factors_page: {
    title: "Emission Factors",
    create_emission_factor: "Create emission factor manually",
  },
  emission_factor_dialog: {
    create_emission_factor: "Create Emission Factor",
    edit_emission_factor: "Edit Emission Factor",
    name: "Name",
    country: "Country",
    version: "Version",
    type: "Type",
    market_based_emission_factor_type: "Type of market based factor",
    sources: "Sources",
    description: "Description (optional)",
    ghg_category: "GHG-Category",
    factor: "Factor",
    unit: "Unit",
    supplier: "Supplier (optional)",
    delete_emission_factor: "Delete Emission Factor",
    delete_emission_factor_body: "Are you sure you want to delete this emission factor?",
    add_factor_for_indirect_emissions: "Add factor for indirect emissions",
    scope_3_factor: "Scope 3 factor",
    related_location_based_emission_factor: "Related location based emission factor: ",
    no_related_location_based_emission_factor: "No related emission factor",
    link_emission_factor: "Link emission factor",
    manage_emission_factors: "Manage emission factors",
  },
  emission_factors_table: {
    no_emission_factors_found: "Keine Emissionsfaktoren gefunden",
    name: "Name",
    database: "Database",
    type: "Type",
    version: "Version",
    ghg_category: "GHG-Category",
    factor: "Factor",
    country: "Country",
    unit: "Unit",
  },
  emission_factors_filters: {
    name: "Search by name...",
    databases: "Database",
    emissionFactorTypes: "Type",
    ghgCategories: "GHG-Category",
    countries: "Country",
    units: "Unit",
  },
  market_based_emission_factor_types: MARKET_BASED_EMISSION_FACTOR_TYPES,
  emission_factor_types: EMISSION_FACTOR_TYPES,
  ghg_categories: GHG_CATEGORIES,
  emission_factor_databases: EMISSION_FACTOR_DATABASES,
  emission_factor_units: EMISSION_FACTOR_UNITS,
};
