/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { ICreateReferenceBudget400Response } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IEnum } from "../models";
// @ts-ignore
import { IGetRecordingPeriodGhgCategories404Response } from "../models";
// @ts-ignore
import { IGhgCategoriesIdentifier } from "../models";
// @ts-ignore
import { INetceroCoreYamlComponentsSchemasEntityId } from "../models";
// @ts-ignore
import { IPerformEvaluationForRecordingPeriod200Response } from "../models";
// @ts-ignore
import { IRecordingPeriodGhgCategory } from "../models";
/**
 * RecordingPeriodGhgCategoriesApi - axios parameter creator
 * @export
 */
export const RecordingPeriodGhgCategoriesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingPeriodGhgCategories: async (
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getRecordingPeriodGhgCategories", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getRecordingPeriodGhgCategories", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'recordingStructureId' is not null or undefined
      assertParamExists(
        "getRecordingPeriodGhgCategories",
        "recordingStructureId",
        recordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (recordingStructureId !== undefined) {
        localVarQueryParameter["recordingStructureId"] = recordingStructureId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IEnum} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {boolean} shouldComputeScope3Values Whether to compute scope 3 values for the evaluation.
     * @param {INetceroCoreYamlComponentsSchemasEntityId} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performEvaluationForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      type: IEnum,
      shouldComputeScope3Values: boolean,
      recordingStructureId?: INetceroCoreYamlComponentsSchemasEntityId,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("performEvaluationForRecordingPeriod", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "performEvaluationForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'type' is not null or undefined
      assertParamExists("performEvaluationForRecordingPeriod", "type", type);
      // verify required parameter 'shouldComputeScope3Values' is not null or undefined
      assertParamExists(
        "performEvaluationForRecordingPeriod",
        "shouldComputeScope3Values",
        shouldComputeScope3Values,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/ghg-categories/evaluation`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (type !== undefined) {
        localVarQueryParameter["type"] = type;
      }

      if (shouldComputeScope3Values !== undefined) {
        localVarQueryParameter["shouldComputeScope3Values"] = shouldComputeScope3Values;
      }

      if (recordingStructureId !== undefined) {
        localVarQueryParameter["recordingStructureId"] = recordingStructureId;
      }

      if (categoryIdentifier !== undefined) {
        localVarQueryParameter["categoryIdentifier"] = categoryIdentifier;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - functional programming interface
 * @export
 */
export const RecordingPeriodGhgCategoriesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RecordingPeriodGhgCategoriesApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRecordingPeriodGhgCategories(
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordingPeriodGhgCategories(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IEnum} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {boolean} shouldComputeScope3Values Whether to compute scope 3 values for the evaluation.
     * @param {INetceroCoreYamlComponentsSchemasEntityId} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async performEvaluationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      type: IEnum,
      shouldComputeScope3Values: boolean,
      recordingStructureId?: INetceroCoreYamlComponentsSchemasEntityId,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IPerformEvaluationForRecordingPeriod200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.performEvaluationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        type,
        shouldComputeScope3Values,
        recordingStructureId,
        categoryIdentifier,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - factory interface
 * @export
 */
export const RecordingPeriodGhgCategoriesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RecordingPeriodGhgCategoriesApiFp(configuration);
  return {
    /**
     * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecordingPeriodGhgCategories(
      organizationId: string,
      recordingPeriodId: string,
      recordingStructureId: string,
      options?: any,
    ): AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }> {
      return localVarFp
        .getRecordingPeriodGhgCategories(
          organizationId,
          recordingPeriodId,
          recordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Performs the evaluation for the specified recording period.
     * @summary Performs the evaluation for the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IEnum} type Whether the evaluation should be performed for a recording structure or a category. Required.
     * @param {boolean} shouldComputeScope3Values Whether to compute scope 3 values for the evaluation.
     * @param {INetceroCoreYamlComponentsSchemasEntityId} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
     * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. Only required if type is \&quot;for-category\&quot;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performEvaluationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      type: IEnum,
      shouldComputeScope3Values: boolean,
      recordingStructureId?: INetceroCoreYamlComponentsSchemasEntityId,
      categoryIdentifier?: IGhgCategoriesIdentifier,
      options?: any,
    ): AxiosPromise<IPerformEvaluationForRecordingPeriod200Response> {
      return localVarFp
        .performEvaluationForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          type,
          shouldComputeScope3Values,
          recordingStructureId,
          categoryIdentifier,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RecordingPeriodGhgCategoriesApi - interface
 * @export
 * @interface RecordingPeriodGhgCategoriesApi
 */
export interface RecordingPeriodGhgCategoriesApiInterface {
  /**
   * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  getRecordingPeriodGhgCategories(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<{ [key: string]: Array<IRecordingPeriodGhgCategory> | undefined }>;

  /**
   * Performs the evaluation for the specified recording period.
   * @summary Performs the evaluation for the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IEnum} type Whether the evaluation should be performed for a recording structure or a category. Required.
   * @param {boolean} shouldComputeScope3Values Whether to compute scope 3 values for the evaluation.
   * @param {INetceroCoreYamlComponentsSchemasEntityId} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
   * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. Only required if type is \&quot;for-category\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApiInterface
   */
  performEvaluationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    type: IEnum,
    shouldComputeScope3Values: boolean,
    recordingStructureId?: INetceroCoreYamlComponentsSchemasEntityId,
    categoryIdentifier?: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IPerformEvaluationForRecordingPeriod200Response>;
}

/**
 * RecordingPeriodGhgCategoriesApi - object-oriented interface
 * @export
 * @class RecordingPeriodGhgCategoriesApi
 * @extends {BaseAPI}
 */
export class RecordingPeriodGhgCategoriesApi
  extends BaseAPI
  implements RecordingPeriodGhgCategoriesApiInterface
{
  /**
   * Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @summary Gets all the GHG categories for the given recording period, filtered for the specified recording structure (query parameter).
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} recordingStructureId The id of the recording structure for which to filter the categories.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public getRecordingPeriodGhgCategories(
    organizationId: string,
    recordingPeriodId: string,
    recordingStructureId: string,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .getRecordingPeriodGhgCategories(
        organizationId,
        recordingPeriodId,
        recordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Performs the evaluation for the specified recording period.
   * @summary Performs the evaluation for the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IEnum} type Whether the evaluation should be performed for a recording structure or a category. Required.
   * @param {boolean} shouldComputeScope3Values Whether to compute scope 3 values for the evaluation.
   * @param {INetceroCoreYamlComponentsSchemasEntityId} [recordingStructureId] The ID of the recording structure for which the evaluation should be performed. Only required if type is \&quot;for-recording-structure\&quot;.
   * @param {IGhgCategoriesIdentifier} [categoryIdentifier] The identifier of the category for which the evaluation should be performed. Only required if type is \&quot;for-category\&quot;.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RecordingPeriodGhgCategoriesApi
   */
  public performEvaluationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    type: IEnum,
    shouldComputeScope3Values: boolean,
    recordingStructureId?: INetceroCoreYamlComponentsSchemasEntityId,
    categoryIdentifier?: IGhgCategoriesIdentifier,
    options?: AxiosRequestConfig,
  ) {
    return RecordingPeriodGhgCategoriesApiFp(this.configuration)
      .performEvaluationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        type,
        shouldComputeScope3Values,
        recordingStructureId,
        categoryIdentifier,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
