import { Box, Divider, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import {
  IAction,
  IActionData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IIntercomEntity,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ActionsUtilities, WithEqualUndefinedAndNullRecursive } from "@netcero/netcero-common";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";
import {
  ALL_SECTIONS_CONFIG,
  ALL_SECTIONS_ORDER,
  E1And4,
  E1And4_ORDER,
  GENERAL,
  GENERAL_ORDER_FIRST,
  GENERAL_ORDER_SECOND,
  IActionDataGeneralBasicProperties,
} from "../action-inputs.constants";
import { ActionTopicComponent } from "./action-topic.component";
import { ActionsAppUtilities, IActionEditDialogData } from "../actions-app.utilities";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import { MdrTopicBreadcrumb } from "../../minimum-disclosure-requirements-common/components/mdr-topic-breadcrumb.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { useDMACategoriesQuery } from "../../double-materiality-assessment/dma.queries";
import { usePoliciesQuery } from "../../policies/policies.queries";
import { QueriesWrapper } from "../../common/components/queries-wrapper.component";
import { MdrAssociatedUsersAndSourcesInputs } from "../../minimum-disclosure-requirements-common/components/mdr-associated-users-and-sources-inputs.component";
import { PoliciesAppUtilities } from "../../policies/policies-app.utilities";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { FinancialAllocationTable } from "./financial-allocation-table.component";

const getFormDefaultValues = (
  action: IAction | undefined | null,
  identifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): IActionEditDialogData => {
  const identifierKey = ActionsAppUtilities.getIdentifierKeyForTopic(identifier);

  const result: IActionEditDialogData = {
    ...(action ? ActionsUtilities.convertActionToActionData(action) : {}),
    materialImpactIds: action?.materialImpactIds ?? [],
    financialEffectIds: action?.financialEffectIds ?? [],
    policyIds: action?.policyIds ?? [],
    general: {
      ...MdrUtilities.getDefaultValuesForConfiguration<IActionDataGeneralBasicProperties>(
        GENERAL,
        action?.general,
      ),
      financialResourcesAllocatedToActionPlanCapEx: {
        shortTerm: action?.general?.financialResourcesAllocatedToActionPlanCapEx?.shortTerm ?? null,
        midTerm: action?.general?.financialResourcesAllocatedToActionPlanCapEx?.midTerm ?? null,
        longTerm: action?.general?.financialResourcesAllocatedToActionPlanCapEx?.longTerm ?? null,
      },
      financialResourcesAllocatedToActionPlanOpEx: {
        shortTerm: action?.general?.financialResourcesAllocatedToActionPlanOpEx?.shortTerm ?? null,
        midTerm: action?.general?.financialResourcesAllocatedToActionPlanOpEx?.midTerm ?? null,
        longTerm: action?.general?.financialResourcesAllocatedToActionPlanOpEx?.longTerm ?? null,
      },
    },
    associatedTopics: action?.associatedTopics ?? [identifier],
    responsibleUserId: action?.responsibleUserId ?? null,
    contributingUserIds: action?.contributingUserIds ?? [],
    sourceIds: action?.sourceIds ?? [],
  };

  if (ActionsAppUtilities.isSTopic(identifierKey)) {
    result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      action?.[identifierKey],
    ) as never;
  } else if (ActionsAppUtilities.isETopic(identifierKey)) {
    result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      action?.[identifierKey],
    ) as never;
  }

  if (identifierKey === "e1" || identifierKey === "e4") {
    result.e1And4 = MdrUtilities.getDefaultValuesForConfiguration(E1And4, action?.e1And4);
  }

  return result;
};

interface IActionEditDialogPropsBase {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IActionData | null) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
}

interface IActionEditDialogPropsCreate extends IActionEditDialogPropsBase {
  action?: undefined;
  onDelete?: undefined;
}

interface IActionEditDialogPropsEdit extends IActionEditDialogPropsBase {
  action: IAction | undefined | null;
  onDelete: (actionId: string) => void;
}

type IActionEditDialogProps = IActionEditDialogPropsCreate | IActionEditDialogPropsEdit;

export const ActionEditDialog: FC<IActionEditDialogProps> = ({
  disabled,
  onClose,
  onDelete,
  error,
  open,
  loading,
  action,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
}) => {
  const mode = action ? "edit" : "create";

  const { t } = useTranslation("data_entry_object_action_component");
  const identifierKey = useMemo(
    () => ActionsAppUtilities.getIdentifierKeyForTopic(topicIdentifier),
    [topicIdentifier],
  );

  const policiesQuery = usePoliciesQuery(organizationId, recordingPeriodId);
  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    rootDataEntryObjectId,
  );

  const useFormResult = useForm<IActionEditDialogData>({
    defaultValues: getFormDefaultValues(action, topicIdentifier),
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useFormResult;

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(action, topicIdentifier));
    }
  }, [action, open, reset, topicIdentifier]);

  const handleEmitData = useCallback(
    (data: IActionEditDialogData) => {
      const result = {
        ...data,
        responsibleUserId: data.responsibleUserId ?? undefined,
        general: {
          ...MdrUtilities.convertToApiPayload<
            WithEqualUndefinedAndNullRecursive<IActionDataGeneralBasicProperties>
          >(data.general, GENERAL),
          financialResourcesAllocatedToActionPlanCapEx: {
            shortTerm:
              data.general.financialResourcesAllocatedToActionPlanCapEx.shortTerm ?? undefined,
            midTerm: data.general.financialResourcesAllocatedToActionPlanCapEx.midTerm ?? undefined,
            longTerm:
              data.general.financialResourcesAllocatedToActionPlanCapEx.longTerm ?? undefined,
          },
          financialResourcesAllocatedToActionPlanOpEx: {
            shortTerm:
              data.general.financialResourcesAllocatedToActionPlanOpEx.shortTerm ?? undefined,
            midTerm: data.general.financialResourcesAllocatedToActionPlanOpEx.midTerm ?? undefined,
            longTerm:
              data.general.financialResourcesAllocatedToActionPlanOpEx.longTerm ?? undefined,
          },
        },
      };

      if (
        PoliciesAppUtilities.isSTopic(identifierKey) ||
        ActionsAppUtilities.isETopic(identifierKey)
      ) {
        result[identifierKey] = MdrUtilities.convertToApiPayload(
          data[identifierKey],
          ALL_SECTIONS_CONFIG[identifierKey],
        ) as never;
      }

      if (identifierKey === "e1" || identifierKey === "e4") {
        data.e1And4 = MdrUtilities.convertToApiPayload(data.e1And4, E1And4);
      }
      // as IActionData is fine since convertToApiPayload converts relevant values correctly
      onClose(result as IActionData);
    },
    [identifierKey, onClose],
  );

  return (
    <EditDialogWrapper
      open={open}
      mode={mode}
      loading={loading}
      hasChanges={isDirty}
      disabled={disabled}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      dialogProps={{
        fullWidth: true,
        maxWidth: "xl",
      }}
      title={
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1}>
            {/* Breadcrumbs */}
            <MdrTopicBreadcrumb topicIdentifier={topicIdentifier} />
            {/* Actual title*/}
            <span>
              <FormatTranslation
                i18nKey={mode === "create" ? "create_action" : "edit_action"}
                t={t}
                values={{ title: action?.general.name }}
              />
              {mode === "create" && (
                <IntercomReferenceWidget
                  entityType={IIntercomEntity.Action}
                  identifier={`dialog_heading_create_help.${topicIdentifier}`}
                  renderIfNoArticleFound={
                    <IntercomReferenceWidget
                      entityType={IIntercomEntity.Action}
                      identifier="dialog_heading_create_help.common"
                    />
                  }
                />
              )}
            </span>
          </Box>
          {/* Delete button */}
          {onDelete && action && (
            <>
              <Divider orientation="vertical" flexItem />
              <Tooltip title={t("delete_action_tooltip")}>
                <span>
                  <IconButton onClick={() => onDelete(action.id)} disabled={loading}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </Box>
      }
    >
      {/* General Disclosures (includes associated entities) */}
      <FormProvider {...useFormResult}>
        {/* Linked Users & Sources */}
        <MdrAssociatedUsersAndSourcesInputs organizationId={organizationId} disabled={disabled} />

        {/* General Inputs Part 1 */}
        <QueriesWrapper
          queries={[policiesQuery, dmaCategoriesQuery]}
          loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
          build={([policies, dmaCategories]) => (
            <ActionTopicComponent
              topicPrefix="general"
              control={control}
              inputs={GENERAL_ORDER_FIRST}
              inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
              watch={watch}
              disabled={!!disabled}
              dmaCategories={dmaCategories}
              policies={policies}
            />
          )}
        />

        {/* CapEx / OpEx Table */}
        <Typography variant="subtitle1" component="h5" sx={{ mt: 2 }}>
          {t("section_titles.capEx_opEx")}
        </Typography>
        <FinancialAllocationTable disabled={disabled} />

        {/* General Inputs Part 2 */}
        <ActionTopicComponent
          topicPrefix="general"
          control={control}
          inputs={GENERAL_ORDER_SECOND}
          inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
          watch={watch}
          disabled={!!disabled}
        />
      </FormProvider>

      {/* E1 & E4 common Disclosures */}
      {(identifierKey === "e1" || identifierKey === "e4") && (
        <>
          <Divider sx={{ my: 4 }} />
          <ActionTopicComponent
            sectionTitle={t(`section_titles.esrs_e1_and_e4`)}
            topicPrefix="e1And4"
            control={control}
            inputs={E1And4_ORDER}
            inputsMetaData={E1And4}
            watch={watch}
            disabled={!!disabled}
          />
        </>
      )}

      {/* Topic specific Disclosures */}
      {ActionsAppUtilities.hasSpecificInputs(identifierKey) && (
        <>
          <Divider sx={{ my: 4 }} />
          <ActionTopicComponent
            sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
            topicPrefix={identifierKey}
            control={control}
            inputs={ALL_SECTIONS_ORDER[identifierKey]}
            inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
            watch={watch}
            disabled={!!disabled}
          />
        </>
      )}
    </EditDialogWrapper>
  );
};
