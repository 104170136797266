import { Box, Chip, Stack, styled, TableCell, Tooltip } from "@mui/material";
import { FC } from "react";
import { IEmissionFactor } from "@netcero/netcero-core-api-client";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { IGhgProtocolScope, MappingUtilities } from "@netcero/netcero-common";
import { GhgCategoriesChips } from "./ghg-category-chip.component";
import { useTranslation } from "react-i18next";
import { EMISSION_UNIT_TEXT } from "../emission-factors.constants";
import { CornerRightUpDoubleIcon } from "../../common/constants/tabler-icon.constants";
import { EmissionFactorUnitNameShortWithTooltip } from "./emission-factor-unit-name.component";

type EmissionFactorTableValueCellComponent<T extends keyof IEmissionFactor> = FC<
  Pick<IEmissionFactor, T>
>;

export const StyledTableCell = styled(TableCell)({
  padding: "16px",
});

export const EmissionFactorTableTitleCell: EmissionFactorTableValueCellComponent<"title"> = ({
  title,
}) => {
  const translateContent = useTranslateContent();
  return <StyledTableCell>{translateContent(title)}</StyledTableCell>;
};

export const EmissionFactorTableDatabaseCell: EmissionFactorTableValueCellComponent<"database"> = ({
  database,
}) => {
  const { t } = useTranslation("emission_factor_databases");
  return (
    <StyledTableCell>
      {FormatUtilities.formatOptionalString(MappingUtilities.mapIfNotUndefined(database, t))}
    </StyledTableCell>
  );
};

export const EmissionFactorTableTypeCell: EmissionFactorTableValueCellComponent<
  "emissionFactorType"
> = ({ emissionFactorType }) => {
  const { t } = useTranslation("emission_factor_types");
  return <StyledTableCell>{t(emissionFactorType)}</StyledTableCell>;
};

export const EmissionFactorTableVersionCell: EmissionFactorTableValueCellComponent<"version"> = ({
  version,
}) => {
  return <StyledTableCell>{version}</StyledTableCell>;
};

export const EmissionFactorTableGhgCategoriesCell: EmissionFactorTableValueCellComponent<
  "ghgCategories"
> = ({ ghgCategories }) => {
  return (
    <StyledTableCell>
      <Box display="flex" gap={1}>
        <GhgCategoriesChips ghgCategories={ghgCategories} />
      </Box>
    </StyledTableCell>
  );
};

export const EmissionFactorTableCountryCell: EmissionFactorTableValueCellComponent<"country"> = ({
  country,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "countries" });
  return (
    <StyledTableCell>
      {FormatUtilities.formatOptionalString(MappingUtilities.mapIfNotUndefined(country, t))}
    </StyledTableCell>
  );
};

export const EmissionFactorTableFactorCell: FC<
  Pick<IEmissionFactor, "factor" | "scopeFactors">
> = ({ factor, scopeFactors }) => {
  return (
    <StyledTableCell align="right">
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Box minWidth="40px">{factor}</Box>
        <Chip size="small" label={EMISSION_UNIT_TEXT} sx={{ ml: 0.5 }} />
        {scopeFactors[IGhgProtocolScope.Scope3] !== undefined ? (
          <Tooltip title={`${scopeFactors[IGhgProtocolScope.Scope3]} ${EMISSION_UNIT_TEXT}`}>
            <CornerRightUpDoubleIcon />
          </Tooltip>
        ) : (
          <Box width={5} />
        )}
      </Stack>
    </StyledTableCell>
  );
};

export const EmissionFactorTableUnitCell: EmissionFactorTableValueCellComponent<"unit"> = ({
  unit,
}) => {
  return (
    <StyledTableCell>
      <EmissionFactorUnitNameShortWithTooltip unit={unit} />
    </StyledTableCell>
  );
};
