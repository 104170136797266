import { IGhgProtocolScope } from "../emission-factors";
import { GHG_VERSIONS } from "./versions/ghg-versions";

export const GHG_CATEGORIES_BY_SCOPE = {
  [IGhgProtocolScope.Scope1]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScope.Scope1],
  ),
  [IGhgProtocolScope.Scope2]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScope.Scope2],
  ),
  [IGhgProtocolScope.Scope3]: Object.values(GHG_VERSIONS).flatMap(
    (ghgVersion) => ghgVersion[IGhgProtocolScope.Scope3],
  ),
};
